import React,{Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import HeaderCell from './GridHeaderCell';
import {orange,grey} from '@material-ui/core/colors';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  headerrow:{
    backgroundColor: grey['200'],
  },
  headercell:{
    // borderRightStyle: 'solid',
    // borderRightColor: grey['600'],
    // borderRightWidth: '1px',
  },
  root:{
    color: theme.palette.primary.main,
    fontSize: theme.typography.h6.fontSize,
  },
  active:{
    color: theme.palette.primary.light,
  },
  icon:{
    color: orange['500'],
  }
});

const styles = extendedstyles(extrastyles);

class GridHeader extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode:0
    };
  }

  createSortHandler = (property:any) => (event:any) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    //const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;
    const { cols, actionCols, onSelectAllClick, order, orderBy, numSelected, classes } = this.props;
    //console.log('cols:',cols);
    return (
      <TableHead>
        <TableRow className={classes.headerrow}>
          <HeaderCell padding="checkbox">
            <Checkbox 
              indeterminate={numSelected>1} //{numSelected > 0 && numSelected < rowCount}
              checked={numSelected===1} //{numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </HeaderCell>
          {cols.map((col:any) => {
            return (
              <HeaderCell
                key={col.id}
                // numeric={row.numeric}
                padding={col.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === col.id ? order : false}
                className={classes.headercell}
              >
                <Tooltip
                  title="Sort"
                  placement={col.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === col.id}
                    direction={order}
                    onClick={this.createSortHandler(col.id)}
                    classes={{root:classes.root,active:classes.active,icon:classes.icon}}
                  >
                    {col.label}
                  </TableSortLabel>
                </Tooltip>
              </HeaderCell>
            );
          }, this)}
          {actionCols && (
            <HeaderCell padding="checkbox">
            {" "}
          </HeaderCell>
          )}
        </TableRow>
      </TableHead>
    );
  }
}

GridHeader.propTypes = {
  cols: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


export default withStyles(styles)(GridHeader);