import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {blue,pink,green} from '@material-ui/core/colors';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ResourceView from './ResourceView';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon} from '../../components/icons';
import Download from '../../ajax/download';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  secondaryHeading2: {
    fontSize: theme.typography.pxToRem(15),
    color: blue[600], //theme.palette.text.secondary,
  },
  topheading: {
    fontWeight:'bold',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.3%',
  },
  gridtitle: {
    fontSize: theme.typography.pxToRem(15),
    color: pink['500'],
    fontWeight:'bold',
  },
  gridroot: {
    width: '100%',
  },
  gridrow: {
    display: 'flex',
    width: 600,
    border: '1px solid #eeeeee',
    textAlign: 'left',
  },
  gridcolumnauto: {
    flexBasis: 'fill',
    textAlign: 'left',
    borderRight: '1px solid #eeeeee',
  },
  gridcolumn30: {
    flexBasis: 'auto',
    width: '200px',
    textAlign: 'left',
    borderRight: '1px solid #eeeeee',
  },
  gridcontent: {
    textAlign: 'left',
    paddingLeft: '20px',
  },
  dcolumn: {
    width: '100%',
    display: 'flex',
    flexBasis: '50%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.text.hint}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink["500"]
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
  
});

const styles = extendedstyles(extrastyles);

class RegisterView extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      views:[],
      count:0,
      datasets: null,
      dataset: null
    };
  }

  handleClick = (name:any) => () => {
    //var btn = event.target;
    var {data,record,user} = this.props;
    var d = new Download();
    d.downloadFile(name,data,record);
  } 

  render() {
    const {result,classes } = this.props;
    var sdoc = result.sdoc;
    var vers = Object.keys(sdoc);
    const vts:any = {'POI':'ID Verification','POA':'Address Verification'};
    return (
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classnames(classes.heading,classes.topheading)}>
                Status
              </Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classnames(classes.secondaryHeading,classes.topheading)}>
                {result.jsonsumm.status}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
            {result.info.map( (infoitem:any,ii:number) => (
              <div key={`info_${ii}`} className={classes.gridrow}>
                <div className={classes.gridcolumn30}>
                  <Typography>
                    {infoitem.title}
                  </Typography>
                </div>
                <div className={classes.gridcolumnauto}>
                  <Typography className={classes.secondaryHeading2}>
                    {infoitem.value}
                  </Typography>
                </div>
              </div>
            ))}
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
        {result.vers.map( (veritem:any,vei:number) => (
          <div key={`ver_${vei}`}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column}>
                  <Typography className={classes.heading}>
                    {veritem.title}
                  </Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}>
                    {veritem.value}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div>
                  {(result.tile[veritem.field])?
                  <ResourceView name={result.name} tileData={result.tile[veritem.field]} />
                  :""}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
        {(result.errs.length>0)?
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classnames(classes.heading,classes.topheading)}>
                Errors
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div>
                  {result.errs.map( (erritem:any,i:number) => (
                    <div key={`err_${i}`} className={classes.gridrow}>
                    {/* <div key={erritem.field} className={classes.gridrow}> */}
                      <div className={classes.gridcolumn30}>
                        <Typography className={classes.heading}>
                          <span>{erritem.title}</span>
                        </Typography>
                      </div>
                      <div className={classes.gridcolumnauto}>
                        <Typography className={classes.secondaryHeading2}>
                          <span>{erritem.error}</span>
                        </Typography>
                      </div>
                      <Divider />
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          // </div>
        :""}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Data
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            {/* <div> */}
              {vers.map(n => {
                var docs = sdoc[n];
                var dockeys = Object.keys(docs);
                return dockeys.map( (vn,vi) => {
                  var docdata = docs[vn];
                  return (
                    <div key={`doc_${vi}`}>
                      <div className={classes.gridtitle}>{vts[n]||n} - {vn}</div> 
                      <div>
                      {docdata.map( (dataitem:any,di:number) => {
                        return (
                          <div key={`docdata_${di}`} className={classes.gridrow}>
                          {/* <div key={dataitem.field} className={classes.gridrow}> */}
                            <div className={classes.gridcolumn30}>
                              <Typography className={classes.heading}>
                                <span>{dataitem.title}</span>
                              </Typography>
                            </div>
                            <div className={classes.gridcolumnauto}>
                              <Typography className={classes.secondaryHeading2}>
                                <span>{dataitem.value}</span>
                              </Typography>
                            </div>
                            <Divider />
                          </div>
                        );
                      })} 
                      </div>
                    </div>
                  );  
                });
              })}
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Validations
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.gridroot}>
            {result.vals.map( (valitem:any,vai:number) => {
              return (
                <div key={`val_${vai}`} className={classes.gridrow}>
                {/* <div key={valitem.validation} className={classes.gridrow}> */}
                  <div className={classes.gridcolumn30}>
                    <Typography className={classes.heading}>
                      <span className={classes.gridcontent}>{valitem.title}</span>
                    </Typography>
                  </div>
                  <div className={classes.gridcolumnauto}>
                    <Typography className={classes.heading}>
                      <span className={classes.gridcontent}>{valitem.validation}</span>
                    </Typography>
                  </div>
                  <div className={classes.gridcolumn30}>
                    <Typography className={classes.secondaryHeading2}>
                      <span className={classes.gridcontent}>{valitem.result}</span>
                    </Typography>
                  </div>
                  <Divider />
                </div>
              );
            })} 
            </div>    
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Downloads
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <div className={classes.iconsroot}>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('pdf')}>
                    <PDFIcon titleAccess="PDF Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('csv')}>
                    <CSVIcon titleAccess="CSV Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('json')}>
                    <JSONIcon titleAccess="JSON Download" />
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('zip')}>
                    <ZIPIcon titleAccess="ZIP Download" />
                  </Button>
              </div>
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withStyles(styles)(RegisterView);