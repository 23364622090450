import React from 'react';
import PermIdentity from '@material-ui/icons/PermIdentity';

const data:any = {
  menus: [
    // { id:1, text: 'Dashboard', icon: <Assessment/>, page:'dashboard', acv: 3 },
    // { id:2, text: 'Results', icon: <VerifiedUser/>, page:'result', acv: 3 },
    // { id:3, text: 'Verification', icon: <Web/>, page:'verify', acv: 3 },
    // { id:4, text: 'Requests', icon: <GridOn/>, page:'request', acv: 3 },
    // { id:5, text: 'Accounts', icon: <AccountBox/>, page:'account', acv: 1 },
    // { id:6, text: 'Billing', icon: <ShoppingBasket/>, page:'billing', acv: 1 },
    // { id:7, text: 'Users', icon: <People/>, page:'user', acv: 1 },
    // { id:8, text: 'Settings', icon: <Settings/>, page:'setting', acv: 1 },
    // { id:9, text: 'Menu', icon: <GridOff/>, page:'menu', acv: 15 },
    { id:10, text: 'Logout', icon: <PermIdentity/>, page:'logout', acv: 3 }
  ],
  services: {
    id: 'ID Verification',
  },
  vstates: {
    list: ['disabled','requested','accessed','submitted','autopassed','autofailed','manualpassed','manualfailed','unauthorized'],
    default:'unknown'
  },
  statuses: {
    list: ['disabled','requested','accessed','submitted','passed','processing','passed','failed','unauthorized'],
    default:'unknown'
  },
  actions: {add:'Add',edit:'Edit',del:'Delete',delete:'Delete',view:'View',man:'Manage',manage:'Manage',wiz:'Process',process:'Process',upload:'Upload'}
    
};

//export {data};

export default data;
