//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//colors
import Grid from '@material-ui/core/Grid';
import {PageBase} from '../../components/site';
import {extendedstyles} from '../styles';

//siblings
import {ReportForm} from '.';
/**
 * master theme styling
 */
//const styles = (theme:Theme) => servicestyle(theme,pink,'white');
const extrastyles = (theme:Theme) => ({
});

const styles = extendedstyles(extrastyles);


class Report extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  
  render() {
    //const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const {classes, theme, ...others} = this.props;
    const {user}  = this.props;
    return (
      <PageBase navigation="Application / Report">
      <div className={classes.root}>
        {/* <h3 className={classes.navigation}>{this.props.userData.bd.enm}</h3> */}
        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12} sm={6}>
            <ReportForm 
              record={{}}
              data={[]}
              user={user||"admin"}
              {...others}
            />
          </Grid>
        </Grid>
      </div>
      </PageBase>
    );
  }
}

Report.propTypes = {
  service: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  showNotification: PropTypes.func
};
 
export default withStyles(styles, { withTheme: true })(Report);
