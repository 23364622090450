import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import GuideIcon from '@material-ui/icons/Book';
import ReportIcon from '@material-ui/icons/Report';
import SupportIcon from '@material-ui/icons/ContactSupport';
import ViewModule from '@material-ui/icons/ViewModule';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import SearchBox from './SearchBox';


const drawerWidth = 240;
const miniDrawerWidth = 60;

const styles = (theme:Theme):any => {
  console.log('theme in header: ',theme);
  return ({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      [theme.breakpoints.down('sm')]:{
        width: `calc(100% - ${miniDrawerWidth}px)`,
        marginLeft: miniDrawerWidth,
      },
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar:{
      display: 'flex',
      justifyContent: 'flex-end'
    },
    searchbar: {
      width:'100%'
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 36,
      position:'absolute',
      left:0
    },
    hide: {
      display: 'none',
    },
    show: {
      display: 'flex',
    },
    topmenu: {
      float: 'right'
    },
    title: {
      marginLeft: 60,
      marginRight: 36,
      position:'absolute',
      left:0,
      color:'white'
    }
  });
};

class Header extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      anchorEl: null,
      menuopen: false,
      useropen: false,
      useranchor: null,
      settingsopen: false,
      settingsanchor: null,
    };
  }

  handleClick = (menu:any,page:any,index:any)=> () => {
    console.log('handleclick:menu,page,index',menu,page,index);
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
    
  }

  onUserOpen = (event:any) => {
    this.setState({ useranchor: event.currentTarget, useropen:true });
  };

  onUserProfile = (event:any) => {
    this.setState({ useranchor: null, useropen:false });
    const page = 'profile';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onUserAccount = (event:any) => {
    this.setState({ useranchor: null, useropen:false });
    const page = 'profile';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onUserSignOut = (event:any) => {
    this.setState({ useranchor: null, useropen:false });
    const page = 'logout';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onUserClose = () => {
    this.setState({ useranchor: null, useropen:false });
  };

  onSettingsOpen = (event:any) => {
    this.setState({ settingsanchor: event.currentTarget, settingsopen:true });
  };

  onHelp = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'help';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onGuide = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'help';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onSupport = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'support';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onAbout = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'about';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onSettingsClose = () => {
    this.setState({ settingsanchor: null, settingsopen:false });
  };

  onKeyPress = (event:any) => {
    if(event.key === 'Enter') this.onSearch(event.target.value);
  }

  onSearch = (query:any) => {
    const page = 'search';
    this.props.handleSetContent(query,page).then( () => {
      //clear search? no.
    });
  }

  onMenu = (event:any) => {
    this.setState({ anchorEl: event.currentTarget, menuopen:true });
    const page = 'kanban';
    this.props.handleSetContent(null,page).then( () => {
      this.props.closeDrawer();
    });
  };

  onClose = () => {
    this.setState({ anchorEl: null, menuopen:false });
  };

  render() {
    const {navDrawerOpen, openDrawer, closeDrawer, toggleDrawer, data, classes, theme} = this.props;
    const { useropen,useranchor,settingsopen,settingsanchor } = this.state;
    const eti = (data.bd||{}).eti;
    const isMain = (eti===1);
    const adminmenu = isMain?classes.show:classes.hide;
    
      return (
        <AppBar position={"absolute"} className={classNames(classes.appBar, navDrawerOpen && classes.appBarShift)}>
        <Toolbar disableGutters={!navDrawerOpen} className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            //onClick={navDrawerOpen?openDrawer:closeDrawer}
            onClick={toggleDrawer}
            //className={classNames(classes.menuButton, navDrawerOpen && classes.hide)}
            className={classNames(classes.menuButton)}
          >
            {navDrawerOpen?
              (theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />)
              :<MenuIcon />
            }
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
             {data.bd.enm}
          </Typography>
          <SearchBox className={classes.searchbar} onKeyPress={this.onKeyPress}/>
          <div className={classes.topmenu}>{/*HeaderMenu  style={style.iconsRightContainer}....marginLeft:20*/}
            <IconButton
              aria-owns={settingsopen ? 'menu' : undefined}
              aria-haspopup={true}
              onClick={this.onMenu}
              color="inherit">
              <ViewModule />
            </IconButton>
            {/* <Menu
              id="menu-setting"
              anchorEl={settingsanchor}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={settingsopen}
              onClose={this.onSettingsClose}>
              <MenuItem onClick={this.onSettingsClose}><HelpIcon/>Help</MenuItem>
              <MenuItem onClick={this.onSettingsClose}><GuideIcon/>User Guide</MenuItem>
              <MenuItem onClick={this.onSettingsClose}><SupportIcon/>Support</MenuItem>
              <MenuItem onClick={this.onSettingsClose} className={adminmenu}><ReportIcon/>Reports</MenuItem>
            </Menu> */}
          </div>
          <div className={classes.topmenu}>{/*HelpMenu  style={style.iconsRightContainer}....marginLeft:20*/}
            <IconButton
              aria-owns={settingsopen ? 'help-menu' : undefined}
              aria-haspopup="true"
              onClick={this.onSettingsOpen}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="help-menu"
              anchorEl={settingsanchor}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={settingsopen}
              onClose={this.onSettingsClose}>
              <MenuItem onClick={this.onHelp}><HelpIcon/>Help</MenuItem>
              <MenuItem onClick={this.onGuide}><GuideIcon/>User Guide</MenuItem>
              <MenuItem onClick={this.onSupport}><SupportIcon/>Support</MenuItem>
              <MenuItem onClick={this.onClose} className={adminmenu}><ReportIcon/>Reports</MenuItem>
            </Menu>
          </div>
          <div>{/* UserMenu*/}
            <IconButton
              aria-owns={useropen ? 'menu-user' : undefined}
              aria-haspopup="true"
              onClick={this.onUserOpen}
              color="inherit">
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-user"
              anchorEl={useranchor}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={useropen}
              onClose={this.onUserClose}
            >
              {/* <MenuItem onClick={this.onUserProfile}>Profile</MenuItem> */}
              <MenuItem onClick={this.onUserAccount}>My Account</MenuItem>
              <MenuItem onClick={this.onUserSignOut}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  hstyles: PropTypes.object,
  toggleDrawer: PropTypes.func
};

//export default Header;
export default withStyles(styles, { withTheme: true })(Header);
