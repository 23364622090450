import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
//import classNames from 'classnames';
import {grey} from '@material-ui/core/colors';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const styles = (theme:Theme):any => ({
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    //padding: 5,
    //height: '88vh',
    // marginBottom: 20,
  },
  minipaper: {
    marginTop: 30,
    margin: 'auto',
    padding: 0,
    width: '60%'
  },
  clear: {
    clear: 'both'
  }
});

class PageBase extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  render() {
    const {title, mini, children,classes} = this.props;

    return (
      <Fragment>
        {/* <span className={classes.navigation}>{navigation}</span> */}

        <Paper className={mini?classes.minipaper:classes.paper}>
          {title?
            <div><h3 className={classes.title}>{title}</h3><Divider/></div>
          : undefined
          }
          
          {children}

          <div className={classes.clear}/>

        </Paper>
      </Fragment>
    );
  }
}

PageBase.propTypes = {
  title: PropTypes.string,
  navigation: PropTypes.string,
  children: PropTypes.element
};

export default withStyles(styles, { withTheme: true })(PageBase);
