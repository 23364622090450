import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
//siblings
import {requestStatus} from '../../components/renderers';
import {ServiceBase} from '../../components/service';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({});

const styles = extendedstyles(extrastyles);

class Search extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  configuration = () => {
    const config = {
      service:{
        name:'search',
        title:'Search',
        num: false,
        grid: [['dcd','Date'],['urf','User'],['svc','Service'],['tri','Tracking ID'],['rfi','Reference'],['src','Source'],['stn','Status'],['stp','Stamp']],
        menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
        eform:[{id:'dcd',label:'Request Date',type:'rf',req:0,ops:{}},
              {id:'urf',label:'User Contact',type:'rf',req:0,ops:{}},
              {id:'svc',label:'Service',type:'rf',req:0,ops:{}},
              {id:'tri',label:'Tracking ID',type:'rf',req:0,ops:{}},
              {id:'rfi',label:'Reference',type:'rf',req:0,ops:{}},
              {id:'src',label:'Source Reference',type:'rf',req:0,ops:{}},
              {id:'stn',label:'Status',type:'rf',req:0,ops:{}},
              {id:'stp',label:'Last Updated',type:'rf',req:0,ops:{}},
            ],
      },
      create:false,
      panels: { //simple,dialog,result,column,combo,tab,wizard,analytics
        view:'result',close:'dialog',check:'dialog',print:'result'
      }
    };
    return config;
  }

  // handleRecord = (record) =>{
  //   console.log('transaction.handleRecord');
  //   if(record.action==='add') {
  //     //this.props.setPage('verify');
  //     return false;
  //   }
  //   return record;
  // }

  render() {
    const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    // console.log('review.classes',classes);
    const title = "Searched Record";
    return (
      <ServiceBase
        title={title}
        service={service}
        code={code}
        configuration={this.configuration}
        gridformatters={{stn:requestStatus}}
        //rowActions={this.rowActions}
        //handleRecord={this.handleRecord}
        setPage={setPage}
        setHome={setHome}
        showNotification={showNotification}
        classes={classes}
        theme={theme}
      />
    );
  }
}

Search.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func
};
 
export default withStyles(styles, { withTheme: true })(Search);