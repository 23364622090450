import React, { Component } from "react";
import PropTypes from "prop-types";
import { Theme, withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Input from "@material-ui/core/Input";
//import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
//import { Fab } from "@material-ui/core";
// import {
//   white
// } from "@material-ui/core/colors";

const styles = (theme:Theme):any => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  slider: {
    padding: "22px 0px"
  },
  axis: {
    width: "90%"
  },
  fixedfab: {
    //backgroundColor: pink["500"],
    margin: 0,
    top: "auto",
    // right: 20,
    // bottom: 20,
    left: "auto",
    // position: 'fixed',
    width: "100%",
    height: "100%",
    color: 'white',
    fontSize: 8
  },
  actionfab: {
    fill: 'white'
  },
  hide: {
    display: "none"
  }
});

const steps = ["1hr", "3hrs", "1day", "3days"];

class FSlider extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    let index = Math.max(props.values.indexOf(props.value),0)
    this.state = {
      next: true,
      v: index,
      cur: props.value||props.values[0], //"1hr"
    };
  }

  handleChange = (event:any, value:any) => {
    const {values} = this.props;
    // if(this.props.onChange) this.props.onChange(value);
    this.setState({
      v: value,
      cur: values[value] || value
    });
  };

  // handleStep = step => () => {
  //   const {values} = this.props;
  //   this.setState({
  //     activeStep: step,
  //     v: step,
  //     cur: values[step] || step
  //   });
  // };

  handleNext = () => {

  }

  render() {
    const { id, label, value, values, onChange, divider, classes } = this.props;
    const { v, cur } = this.state;
    const min = 0;
    const max = steps.length-1;
    const step = 1;
    const val = Math.max(0,values.indexOf(cur));
    if(val===value)console.log('');

    return (
      <div className={classes.root}>
        <FormControl fullWidth={true}>
          <FormLabel>
            {label}
          </FormLabel>
          <Slider
            id={id}
            name={id}
            className={classes.slider}
            value={v}
            min={min}
            max={max}
            step={step}
            onChange={onChange||this.handleChange}
            // thumb={
            //   <Fab
            //     // variant="fab"
            //     className={classes.fixedfab}
            //     onClick={this.handleNext}
            //   >
            //     {cur}
            //   </Fab>
            // }
          />
          <Input name={id} type="hidden" className={classes.hide} value={cur} />
          <div>{cur}</div>
        </FormControl>
        {divider && <Divider />}
      </div>
    );
  }
}

FSlider.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default withStyles(styles)(FSlider);
