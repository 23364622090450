import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FileUploadIcon from '@material-ui/icons/CloudUpload';

const styles = (theme:Theme):any => ({
  container: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems: 'center'
  },
  preview: {
    width:200,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  }
});

class FileUpload extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      source: undefined
    };
  }
  
  // startLoading = () => {
  //   this.setState({loading:true});
  // }

  // stopLoading = () => {
  //   this.setState({loading:false});
  // }

  onChange = (event:any) => {
    event.persist();
    let that = this;
    //console.log(event.target.name);
    let file:any, fr:any;
    let files:any = event.target.files || event.dataTransfer.files;
    if (files) {
      file = files[0];
      fr = new FileReader();
      fr.onload = function createImage() {
        that.setState({source:fr.result});
        if(that.props.onChange) that.props.onChange(event.target.name)(event,file);
        //console.log('source set, onChange called');
      };
      fr.readAsDataURL(file);
    }
  }

  render() {
    const {id,name,children,accept,preview,previewStyle,divider,classes} = this.props;
    const {source} = this.state;
    var xid = id || 'logo-file';
    var xname = name || 'logo'; 
    var xaccept = accept || "image/*";
    return (
      <div>
      <div className={classes.container}>
        <input
          accept={xaccept}
          className={classes.input}
          id={xid}
          name={xname}
          type="file"
          onChange={this.onChange}
        />
        <label htmlFor={xid}>
          <Button variant="contained" component="span" className={classes.button}>
            <FileUploadIcon color={'inherit'}/>
            {children}
          </Button>
        </label>
        {preview&&<img className={source?classes.preview:classes.hidden} style={previewStyle||{}} src={source} alt=""/>}
      </div>
      {divider && <Divider />}
      </div>
    );
  }
}

FileUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles, { withTheme: true })(FileUpload);
