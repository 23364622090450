import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import MenuIcon from '@material-ui/icons/Menu';

const styles = (theme:Theme):any => ({
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemicon: {
    minWidth: 24,
    marginRight: 8,
  }
});

class DynamicMenuParent extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  state = {
    open: this.props.openImmediately,
  };

  componentDidMount() {
    // So we only run this logic once.
    if (!this.props.openImmediately) {
      return;
    }

    // Center the selected item in the list container.
    const activeElement = document.querySelector(`.${this.props.classes.active}`);
    if (activeElement && activeElement.scrollIntoView) {
      activeElement.scrollIntoView({});
    }
  }

  handleClick = () => {
    this.setState((state:any) => ({ open: !state.open }));
  };

  render() {
    const {
      children,
      classes,
      onClick,
      openImmediately,
      title,
      ...other
    } = this.props;

    return (
      <React.Fragment>
      <ListItem button onClick={this.handleClick} {...other}>
        <ListItemIcon className={classes.itemicon}>
          <MenuIcon />
        </ListItemIcon>
        <ListItemText primary={title} />
        {this.state.open ? <ChevronLeft /> : <ChevronRight />}
      </ListItem>
      <Collapse in={this.state.open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
      </React.Fragment>
    );
  }
}

DynamicMenuParent.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  openImmediately: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

DynamicMenuParent.defaultProps = {
  openImmediately: false,
};

export default withStyles(styles)(DynamicMenuParent);
