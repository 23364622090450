import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
});

class FTextField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      value: props.value||''
    };
  }

  handleChange = (event:any) => {
    const {onChange} = this.props;
    let value = event.target.value;
    this.setState({value});
    //if(typeof onChange === 'function') console.log('final onChange is a function',value);
    //if(typeof onChange === 'function')
    if(typeof onChange === 'function') onChange(value);
  };

  render() {
    const {id,label,required,options,divider,classes } = this.props;
    const {value} = this.state;
    return (
      // <div className={classes.root}>
      <React.Fragment>
        <TextField
          label={label}
          id={id}
          name={id}
          type={'text'}
          required={required}
          margin='dense'
          fullWidth={true}
          className={classes.textField}
          onChange={this.handleChange}
          value={value}
          {...options}
        />
        {divider && <Divider />}
      </React.Fragment>
      //{/* </div> */}
    );
  }
}

FTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FTextField);
