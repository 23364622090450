import React,{ Component } from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {grey} from '@material-ui/core/colors';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  headercell:{
    backgroundColor: theme.palette.grey['200'], // grey['600'], //theme.palette.common.purple,
    color: theme.palette.secondary.light,
    // borderRightStyle: 'solid',
    // borderRightColor: theme.palette.grey['500'], //.light,
    // borderRightWidth: '1px',
    fontSize: theme.typography.h6.fontSize,
  },
  body: {
    fontSize: 14,
  },
});

const styles = extendedstyles(extrastyles);

class GridHeaderCell extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode:0
    };
  }

  render(){
    const {classes} = this.props;
    return (
      <TableCell variant={"head"}  className={classes.headercell}>
        {this.props.children}
      </TableCell>);
  }
}

export default withStyles(styles)(GridHeaderCell);
