import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import DownloadIcon from '@material-ui/icons/ZoomIn';
import {saveAs} from 'file-saver';
import { extendedstyles } from '../../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    // overflow: 'hidden',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  gridList: {
    width: 800,
    height: 350,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile: {
    
  },
  gridTileImage: {
    flex: '1 1 auto'
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});
const styles = extendedstyles(extrastyles);

class VeriPane extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0
    };
  }

  handleClick = (name:any) => (event:any,value:any) => {
    var {result} = this.props;
    console.log('result',result,value,name);
    var blob = new Blob(value);
    saveAs(blob);
  } 

  render() {
    const { record,photo,idcard,classes} = this.props;
    ///console.log('vpane.record,',record);
    //const photo card;
    return (
      <div className={classes.root}>
        {/* <GridList cellHeight={300} spacing={1} className={classes.gridList}> */}
        <GridList cellHeight={350} spacing={1} className={classes.gridList}>
          <GridListTile cols={1} rows={1} className={classes.gridListTile}>
            <img id={photo.resid} src={photo.src} alt="" className={classes.gridTileImage}/>
            <GridListTileBar
              title={photo.title}
              titlePosition="top"
              actionIcon={
                <IconButton className={classes.icon}>
                  <DownloadIcon />
                </IconButton>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </GridListTile>
          <GridListTile key={idcard.resid} cols={1} rows={1} className={classes.gridListTile}>
            <img id={idcard.resid} src={idcard.src} alt="" className={classes.gridTileImage}/>

            <GridListTileBar
              title={idcard.title}
              titlePosition="top"
              actionIcon={
                <IconButton className={classes.icon}>
                  <DownloadIcon />
                </IconButton>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </GridListTile>
    
        </GridList>
      </div>
    );
  }
}

VeriPane.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(VeriPane);
