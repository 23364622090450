//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
//siblings
import {ServiceBase} from '../../components/service';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({});

const styles = extendedstyles(extrastyles);


class Inbox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  configuration = () => {
    const config = {
      service:{
        name:'inbox',
        title:'Inbox',
      },
      create:true
    };
    return config;
  }

  render() {
    const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const title = "Inbox";
    return (
      <ServiceBase
        title={title}
        service={service}
        code={code}
        configuration={this.configuration}
        setPage={setPage}
        setHome={setHome}
        showNotification={showNotification}
        classes={classes}
        theme={theme}
      />
    );
  }
}

Inbox.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func
};

export default withStyles(styles, { withTheme: true })(Inbox);