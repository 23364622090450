import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ListIcon from '@material-ui/icons/List';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  badge:{
    margin: theme.spacing(1),
  },
  hidden: {
    display: 'none'
  }
});

const styles = extendedstyles(extrastyles);

class SelectionAlert extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  
  render() {
    const { numSelected, classes } = this.props;
    return (
      <Badge
        color="secondary"
        badgeContent={numSelected}
        invisible={!numSelected}
        className={classes.badge}
      >
        <ListIcon {...(!numSelected&&{className:classes.hidden})} />
      </Badge>
    );
  }
}

SelectionAlert.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

export default withStyles(styles)(SelectionAlert);
