import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import LocationIcon from '@material-ui/icons/LocationOn';
import MapPreview from '../map/MapPreview';
import {extendedstyles} from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  preview: {
    height: 95,
    padding: '5px 15px 0 15px'
  },
});

const styles = extendedstyles(extrastyles);

class Location extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      position:props.location,
      map: null,
      preview:false,
      zoom: 13,
      info: props.info
    };
  }

  mouseDown = (event:any) => {
    event.preventDefault();
  }

  openPreview = (event:any) => {
    this.setState({preview:true});
  }

  closePreview = (event:any) => {
    this.setState({preview:false});
  }

  render() {
    const {style, position, info} = this.props;
    const {preview,zoom} = this.state;

    return (
      <Fragment>
      <IconButton aria-label="View" style={style} onClick={this.openPreview} onMouseDown={this.mouseDown}>
        <LocationIcon />
      </IconButton>
      <MapPreview open={preview} position={position} info={info} zoom={zoom} onClose={this.closePreview} />
      </Fragment>
    );
  }
}

Location.propTypes = {
  data: PropTypes.array
};

export default withStyles(styles, { withTheme: true })(Location);
