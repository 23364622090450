import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// //import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../styles';

const extrastyles = (theme:Theme) => ({
  card: {
    maxWidth: 345,
    minHeight: 600,
    margin: 'auto',
    paddingTop: 20,
  },
  media: {
    height: 28,
    marginLeft: '20px auto'
  },
  video: {
    width: '90%',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    border: '1px solid #28dab6',
    borderRadius: '1',
    color: '#fffff',
    paddingLeft: 20,
    paddingRight: 20,
  },
  second: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '1',
    color: '#cccccc',
    paddingLeft: 20,
    paddingRight: 20,
  },
  cancel: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    borderRadius: '1',
    color: '#cccccc',
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
  bullets: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  },
  iddemo: {
    backgroundImage: 'url(lib/img/esignature_id_diagram.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: 'auto',
    width: 242, //Landscape:680   =>300 //Portrait:802 => 300 => 242
    height: 200, //Landscape:386  =>170 //Portrait:663 => 248 => 200
  },
});
const styles = extendedstyles(extrastyles);

class WelcomeCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("WelcomeCard");
    //this.ui = this.props.getUI(this.props.uic,'ID','INFO','ALL');
    this.state = {
      next: true,
      fwopen: false
    };
  }

  handleNext = (ev:any)  => {
    this.props.setNext(this.props.name);
  }

  handlePrev = (ev:any)  => {
    this.props.setPrev(this.props.name);
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props;  //fmtUI, logo, title, switchFn,
    // const { TITLE, INSTRUCTION,NEXT,CANCEL} = this.ui;
    // const {idname, idfname } = this.props;
    // let iname = idname || 'ID Card';
    // let fname = idfname || 'ID Card front';
    return (
      <div>
        <BaseCard 
          cardid={'WELCOME'}
          cardname={'Welcome'}
          title={'Welcome'}
          instruction={'welcome message here'}
          step={0}
          buttons={['CANCEL','NEXT','GUIDE']}
          {...extraProps}
        >
          <Divider />
            <div className={classes.bullets}>
              <ul>
                <li>Identity Processing</li>
                <li>Identity Verification</li>
                <li>Bottom of phone must be in your right hand</li>
                <li>Avoid glare on picture</li> 
                {/* <li>Do not hold down ID with hand</li> */}
                <li>Ensure all 4 corners of the ID are visible</li>
              </ul>
            </div> 
          
        </BaseCard>
      </div>
    );
  }
}

WelcomeCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WelcomeCard);
