
const basicconfig = {
  
  accounttype: {
    service:{
      name:'accounttype',
      title:'Account Type',
    }
  },

  activitystatus: {
    service:{
      name:'activitystatus',
      title:'Activity Status',
    }
  },

  agency: {
    service:{
      name:'agency',
      title:'Agency',
    }
  },

  agentcategory: {
    service:{
      name:'agentcategory',
      title:'Agent Category',
    }
  },

  agenttype: {
    service:{
      name:'agenttype',
      title:'Agent Type',
    }
  },

		//NA
  apitype: {
    service:{
      name:'apitype',
      title:'API Type',
    }
  },

  auditmode: {
    service:{
      name:'auditmode',
      title:'Audit Mode',
    }
  },

  branchtype: {
    service:{
      name:'branchtype',
      title:'Branch Type',
    }
  },

  clienttype: {
    service:{
      name:'clienttype',
      title:'Client Type',
    }
  },

  commissiontype: {
    service:{
      name:'commissiontype',
      title:'Commission Type',
    }
  },

  compliancetype: {
    service:{
      name:'compliancetype',
      title:'Compliance Type',
    }
  },

  country: {
    service:{
      name:'country',
      title:'Country',
    }
  },

  currency: {
    service:{
      name:'currency',
      title:'Currency',
    }
  },

  dashtype: {
    service:{
      name:'dashtype',
      title:'Dash Type',
    }
  },

  gender: {
    service:{
      name:'gender',
      title:'Gender',
    }
  },

  idtype: {
    service:{
      name:'idtype',
      title:'ID Type',
    }
  },

  messagetype: {
    service:{
      name:'messagetype',
      title:'Message Type',
    }
  },

  //
  mobilemessagetype: {
    service:{
      name:'mobilemessagetype',
      title:'Mobile Message Type',
    }
  },

  mobilereporttype: {
    service:{
      name:'mobilereporttype',
      title:'Mobile Report Type',
    }
  },

  mobilestatus: {
    service:{
      name:'mobilestatus',
      title:'Mobile User Status',
    }
  },

  momotxtype: {
    service:{
      name:'momotxtype',
      title:'Momo Txn Type',
    }
  },

  providertype: {
    service:{
      name:'providertype',
      title:'Provider Type',
    }
  },
	 //
   region: {
    service:{
      name:'region',
      title:'Region',
    }
  },

  requeststatus: {
    service:{
      name:'requeststatus',
      title:'Request Status',
    }
  },

  report: {
    service:{
      name:'report',
      title:'Reports',
    }
  },
  
  //
  reportstatus: {
    service:{
      name:'reportstatus',
      title:'Report Status',
    }
  },
  
  settlementstatus: {
    service:{
      name:'settlementstatus',
      title:'Settlement Status',
    }
  },

  servicetype: {
    service:{
      name:'servicetype',
      title:'Service Type',
    }
  },

  // severity: {
  //   service:{
  //     name:'severity',
  //     title:'Severity Type',
  //   }
  // },

  // testingcenter: {
  //   service:{
  //     name:'testingcenter',
  //     title:'Testing Center',
  //   }
  // },

  // testingstatus: {
  //   service:{
  //     name:'testingstatus',
  //     title:'Testing Status',
  //   }
  // },

  // testingtype: {
  //   service:{
  //     name:'testingtype',
  //     title:'Testing Type',
  //   }
  // },

  transactionstatus: {
    service:{
      name:'transactionstatus',
      title:'Transaction Status',
    }
  },

  // transactiontype: {
  //   service:{
  //     name:'transactiontype',
  //     title:'Transaction Type',
  //   }
  // },

  uidevice: {
    service:{
      name:'uidevice',
      title:'UI Device',
    }
  },

  uidoc: {
    service:{
      name:'uidoc',
      title:'UI Document',
    }
  },

  uilang: {
    service:{
      name:'uilang',
      title:'UI Language',
    }
  },

  uipage: {
    service:{
      name:'uipage',
      title:'UI Page',
    }
  },

  uisection: {
    service:{
      name:'uisection',
      title:'UI Section',
    }
  },
};

export {basicconfig};
