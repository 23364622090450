import React, {Component} from 'react';

import { Theme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar'; 
//import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import {grey} from '@material-ui/core/colors';
import HelpIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import UserIcon from '@material-ui/icons/Person';
import InfoIcon from '@material-ui/icons/Info';
import ConfIcon from '@material-ui/icons/Settings';
//import StatIcon from '@material-ui/icons/PieChart';
import MasterGrid from '../../components/layout/DynamicGrid';
import {helppost,apidownload} from '../../ajax';
import { extendedstyles } from '../styles';
import Button from '@material-ui/core/Button';
import {CenterGrid} from '../../components/layout';


const extrastyles = (theme:Theme) => ({
  MuiPaperroot:{
    width:400
  },
  helpform: {
    padding: theme.spacing(1),
    width: '100%',
  },
  titlebar: {
    color: 'white',
    textALign: 'center',
    flex:1
  },
  summarybar: {
    backgroundColor: grey['100'],
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(1),
  },
  avatarIcon: {
    float: 'left',
    display: 'block',
    marginRight: 15,
    boxShadow: '0px 0px 0px 8px rgba(0,0,0,0.2)'
  },
  titletext: {

  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 400,
  },
  details:  {
    width: '100%',
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 40,
    width:35,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  },
  help:{
    margin: 20
  }
  
});

const styles = extendedstyles(extrastyles);

class Help extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',
      username: '',
      password:'',
      showPassword: false,
      context: props.context,
      serviceid: 1,
      remember: false
    };
  }

  componentDidMount(){
    //this.loadhelp('main')();
  }

  loadhelp = (name:any) => () => {

  }

  load = async() => {
    console.log('starting onSubmit... ');
    const {code,service,mode,sconfig,record,onResponse,ft,ms,mt} = this.props;
    const {url,endpoint} = sconfig || {};
    const {formData} = this.state;
    //console.log('onsubmit.formdata:',formData);
    try{
      let s = service;
      let a = mode;
      let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      let params = Object.assign(recs,formData,{s,a,ft,ms,mt});
      //console.log('service request ',params);
      this.startLoading();
      const response:any = await helppost(params);
      //console.log('service response ',response);

      if (response.success){
        this.props.showNotification(response.sm,'success',response.st)
        onResponse(true,response);
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          this.props.showNotification(response.message,response.type||'error',response.title)
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      this.props.showNotification(error.message||"request failed",error.type||'warn',error.title||'Update Error');
    }
    finally{
      this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  onChange = (panel:any) => (expanded:any) => {
    this.setState((prev:any) => ({expanded:expanded?panel:null}));
  }

  onDownload = () => {
    let url = '/api/download/';
    let doc = 'arcaudit_guide.pdf';
    // const response:any = await apidownload(doc,url,auth,);


  }

  render() {
    //const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    const {userData,classes, theme} = this.props;
    //const unm = (userData.bd||{}).unm;
    const {expanded}  = this.state;
    const logosrc = '/api/logo/icon.png';
    const docsrc = '/api/download/';

    return (
      <CenterGrid>
        <Paper elevation={3} square={false}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/>
            <Typography variant="h3" className={classes.titlebar}>
              Help
            </Typography>
          </Toolbar>
        </AppBar>
          <div>
          <form className={classes.helpform}>
            
            <div>
              <Typography>
                ARc Mobile AMS 1.0
              </Typography>
            </div>
            <div>
              <Typography>
                ARC Mobile Audit Management System
              </Typography>  
            </div>
            <div>
              <Typography>
                Audit Analytics, Monitoring, Reporting, Corebanking Integration
              </Typography> 
            </div>
            <br/>
            <div>
              <Typography>
              <a href={docsrc} download="arcaudit_guide.pdf">Download ARC Audit Guide</a>
              </Typography> 
            </div>
          </form>
          </div>
        </Paper>
        <div>
          
        </div>
      </CenterGrid>
    );

  }

  
}

export default withStyles(styles, { withTheme: true })(Help);
