import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
//import {white} from '@material-ui/core/colors';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import SearchIcon from '@material-ui/icons/Search';

//const SearchBox = () => {

const styles = (theme:Theme):any => ({
  iconButton: {
    float: 'right',
    paddingTop: 17,
    
  },
  textField: {
    color: 'white',
    backgroundColor: 'white',
    borderRadius: 2,
    height: 35
  },
  inputStyle: {
    color: 'white',
    paddingLeft: 5
  },
  hintStyle: {
    height: 16,
    paddingLeft: 5,
    color: 'white'
  },

  wrapper: {
    fontFamily: theme.typography.fontFamily,
    position: 'relative',
    marginleft: 16,
    borderRadius: 2,
    background: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      background: alpha(theme.palette.common.white, 0.25),
    },
    '& $input': {
      transition: theme.transitions.create('width'),
      width: 400,
      '&:focus': {
        width: 550,
      },
    },
  },
  search: {
    width: theme.spacing(9),
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    pointerEvents: 'none',
    display: 'flex',
    color: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    font: 'inherit',
    padding: theme.spacing(1, 1, 1, 1),
    border: 0,
    display: 'block',
    //float: 'right',
    verticalAlign: 'middle',
    whiteSpace: 'normal',
    background: 'none',
    margin: 0, // Reset for Safari
    color: 'white',
    width: '100%',
    '&:focus': {
      outline: 0,
    },
  },

});

class SearchBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    //this.setContent = this.setContent.bind(this);
    this.state = {
      selectedItem: null
    };
  }

  // menuname=(menu)=>{
  //   return menu.replace('/','');
  // }

  // handleClick = (menu,page,index)=> () => {
  //   console.log('handleclick:',menu,page,index);
  //   this.props.handleSetContent(null,page);
  // }

  render(){
    //const { navDrawerOpen, handleSetContent, closeDrawer, openDrawer, toggleDrawer, classes, theme } = this.props;
    const { onKeyPress, classes } = this.props;
    return (
      // <div>
      //   <TextField
      //     //helperText="Search..."
      //     // underlineShow={false}
      //     fullWidth={true}
      //     //InputProps={{style:{classes.textField}}}
      //     className={classes.textField}
      //     //InputProps={{className:classes.textField}}
      //     //inputStyle={classes.inputStyle}
      //     //hintStyle={classes.hintStyle}
      //     FormHelperTextProps={{className:classes.hintStyle}}
      //   />
      //   <IconButton color="contrast" className={classes.iconButton} >
      //     <SearchIcon />
      //   </IconButton>
        
      // </div>
      <div className={classes.wrapper}>
        <input id="docsearch-input" className={classes.input} onKeyPress={onKeyPress}/>
        <div className={classes.search}>
          <SearchIcon />
        </div>
      </div>
  );
    //);
  }
};

export default withStyles(styles, { withTheme: true })(SearchBox);



SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
};
