import React from 'react';
import {Menu,MenuBook,MenuOpen,Map,Star,Sms} from '@material-ui/icons';
import {MobileFriendly,Dashboard,DataUsage,Settings,LocationCity} from '@material-ui/icons';
import {Security,SystemUpdate,Report,GraphicEq,Equalizer,Help} from '@material-ui/icons';
import {FileCopy,NetworkCheck,Business,People,MeetingRoom,Money} from '@material-ui/icons';
import {Telegram,MonetizationOn,Payment,PartyMode,Person,Shop} from '@material-ui/icons';
import {Computer,Nature,Sports,CardTravel,MusicNote,VideoCall} from '@material-ui/icons';

//TODO: Build a collection of icons based on reknowned top level menu categories and add a default for all unknown (maybe random 5 defaults)
//       to beused by parents
//TODO: All children should use parent icon and change colors
//MAIN,MOBILE,DATA,SETUP,DISTRIBUTION,SECURITY,SYSTEMS,REPORT,ANALYTICS,TOOLS,HELP
//CLIENT,CUSTOMER,MEMBER,MOMO,USSD,TRANSACTION,AGENT,MESSAGING,PAYMENT,
//OTHER1,OTHER2,OTHER3,OTHER4,OTHER5

const icons:any = {
  dash: Dashboard,
  maps: Map ,
  main: Menu ,
  mobi: MobileFriendly,
  data: DataUsage,
  setu: Settings,
  dist: LocationCity,
  secu: Security,
  syst: SystemUpdate,
  repo: Report,
  anal: GraphicEq,
  tool: Equalizer,
  help: Help,
  file: FileCopy ,
  info: VideoCall,
  clnt: Business ,
  cust: People ,
  memb: MeetingRoom ,
  momo: Money,
  ussd: Telegram,
  tran: MonetizationOn,
  agnt: Person,
  mesg: Sms ,
  paym: Payment,
  oth1: Computer,
  oth2: Nature ,
  oth3: Sports ,
  oth4: CardTravel ,
  oth5: Menu,
  defa: MusicNote,
};

const getRandomInt = (min:number,max:number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const getIconRandom = () => {
  let i = getRandomInt(1,5);
  let icon:any = `oth${i}`;
  return icons[icon];
}

const getIcon = (icon:any,rand?:boolean) => icons[icon] || rand?getIconRandom():icons['defa'];

export {icons,getIcon,getIconRandom};
export default icons;
