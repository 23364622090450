//react
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//local helpers
import {forms} from '../../app/custom';
// import {apipost} from '../../ajax';
//siblings
//import styles from '../service/servicestyles';
import styles from '../../app/styles';
import { Typography } from '@material-ui/core';

// const forms = {
//   veri: <VeriForm/>,
//   trustly: <TrustlyForm/>,
// };

/**
 * -------------------------------------------------------------------
 * Service Panel
 * -------------------------------------------------------------------
 */
class CustomPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading:false,
      record:null,
      vdata:null,
      result:null,
      rvopen:false,
    };
  }

  // componentDidMount(){
  //   this.refresh();
  // }

  // refresh = async() => {
  //   try{
  //     const {code,record} = this.props;
  //     const refid = record.rfi;
  //     const trackid = record.tri;
  //     this.startLoading();
  //     let baseparams = {refid:refid,trackid:trackid}
  //     const response = await apipost('/api/result/dash',code,baseparams);
  //     console.log('login response ',response);
  //     let rvopen = true;
  //     this.setState({record:record,vdata:response.sd,result:response.result,rvopen:rvopen});
  //   }
  //   catch(error){
  //     console.log(error);
  //     //this.showNotification(error.message||"request failed",error.type||'warn',error.title||'Loading Error');
  //   }
  //   finally{
  //     this.stopLoading();
  //   }
  // }
  customError = () => {
    return (
      <Fragment>
        <Typography>
          {"No rendering component for selected service panel"}
        </Typography>
      </Fragment>
    )
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  render = () => {
    const {showNotification,code,service,classes} = this.props;
    const customPage = forms[service];
    if(!customPage) showNotification("Error. Not Found");
    return (
      <div className={classes.container}>
        {React.cloneElement(
          (service in forms)?forms[service]:this.customError(), //TODO: default to a simple local message component
          {showNotification,code,}
        )}
      </div>
    );
  }
}

CustomPanel.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add','edit','delete','view','close','manage','wizard']).isRequired,
  page: PropTypes.string.isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object
};

//CustomPanel = withStyles(styles, { withTheme: true })(CustomPanel);

export default withStyles(styles, { withTheme: true })(CustomPanel);