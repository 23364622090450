import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
//import Wizard from '../wizard';
import {ClosableBar, DraggablePaperComponent } from '../dialogs';
//import ClosableBar from '../dialogs/ClosableBar';
import {Wizard,BaseCard,Validator} from '../wizard';
import { clone } from '../../core/utils';
import { formatDate } from '../renderers';
import { create, createFormField, isValid } from '../form';
import { extendedstyles } from '../../app/styles';
import { isTemplateTail } from 'typescript';
import { apipost, bizpost } from '../../ajax';
import Paper from '@material-ui/core/Paper';

const extrastyles = (theme:Theme) => ({
  resetdialog: {
    // width: '100%',
    minHeight: 400,
  },
  root: {
    //display: 'flex',
    //flexDirection: 'vertical',
    //flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
  bullets: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  },
});

const styles = extendedstyles(extrastyles);

//import StartCard from './StartCard';
class StartForm extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      valid: false,
      iserror: false,
      message: '',
      count:0
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
  }

  mkfield = () => {
    const {service,mode,formatters={}} = this.props; 
    const {record={}} = this.props;
    const cfg = { id:'unm',label:'Username',type:'tf',req:1,ops:{ helperText:'Enter Username',pattern:"[a-zA-Z ]+" } };
    const extras = {};
    
    const item = createFormField(service,mode,cfg,record,formatters,this.onChange,extras) ;
    return item;
  }

  // setValid = (data:any)  => {
  //   const sconfig = this.formconfig();
  //   const {mode} = this.props;
  //   const valid = ['del','delete'].includes(mode)?true:isValid(sconfig,data,mode);
  //   //console.log('valid:',valid);
  //   //this.setState({valid});

  //   //const valid = (!!newdata.nam && !!newdata.agc && !!newdata.mbn && !!newdata.lci);
  //   //const valid = (!!data.nam && !!data.agc && !!data.mbn && !!data.lci);
  //   //console.log('valid:',valid);
  //   this.props.setValid(valid);
  // }

  // onChange = (name:any) => (value:any) => {
  //   const {data} = this.props;
  //   const newdata = {...data.data,[name]:value};
  //   this.setValid(newdata);
  //   this.props.setItem('data','data',newdata);
  // }

  onChange = (name:any) => (value:any) => {
    console.log('onchange called',name,value);
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    const valid = isValid([{id:'unm',req:1}],newdata,'add');
    const message = valid?"It's ok now":"Username not valid";
    this.setState({message});
    this.props.setValid(valid);
    this.props.setItem('data','data',newdata);
    //this.setState({data:newdata});
  }
  
  render() {
    const { classes} = this.props;
    const {valid,iserror,message} = this.state;
    const username = this.mkfield();

    return (
      <div className={classes.root}>
      <Divider />
      <div className={classes.bullets}>
        <ul>
          <li>Enter your username annd press NEXT</li>
          <li>Check your registered email for OTP code </li>
          <li>Provide OTP code and new password and submit</li>
          <li>Complete wizard</li>
        </ul>
      </div>
      {/* <div> */}
      <Typography className={valid?classes.success:classes.failure}>
        {message}
      </Typography>
      {/* </div> */}
      {/* <div> */}
      <form id="resetform" className={classes.reqform}>
        <div key={username.id}>{username.dom}</div>
      </form>
      {/* </div> */}
    </div>
    );
  }
}

StartForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StartPanel = withStyles(styles, { withTheme: true })(StartForm);

class StartMain extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("StartCard");
    this.state = {
      //valid: true,
      iserror: false,
      message: '',
      data:{}
    };
  }

  doSubmit = async() => {
    const { classes, theme, showNotification, ...extraProps } = this.props;  //fmtUI, logo, title, switchFn,
    const { record={},data={},extras,setItem } = this.props;
    const {data:formdata={}} = data;

    const {code,service,mode,sconfig,onResponse,ft,ms,mt} = this.props;
    const {url,endpoint} = sconfig || {};
    //const {formData} = this.state;
    //console.log('onsubmit.formdata:',formData);
    try{
      let s = 'security';
      let a = 'reset';
      //let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      console.log('service data ',service,mode,record,formdata);
      let params = Object.assign(record,formdata,{s,a,ft,ms,mt});
      console.log('service request ',params);
      this.setState({loading:true});
      var response;
      if(endpoint==='api')
        response = await apipost(url,code,params);
      else
        response = await bizpost(params);
      //console.log('service response ',response);

      if (response.success){
        showNotification(response.sm,'success',response.st)
        //onResponse(true,response);
        return response;
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warning',error.title||'Reset Request Error');
      return Promise.reject(response);
    }
    finally{
      this.setState({loading:false});
    }
  }

  render = () => {
    const {service,mode,formatters={}, classes, theme, ...extraProps  } = this.props; 
    const {record={},data,setItem} = this.props;    

    return (
        <BaseCard 
          cardid={'START'}
          cardname={'Start'}
          cardtitle={'Request Reset'}
          instruction={'Enter your username and press NEXT to request reset'}
          step={0}
          buttons={['CANCEL','NEXT','GUIDE']}
          doSubmit={this.doSubmit.bind(this)}
          {...extraProps}
        >
         <StartPanel
            data={data}
            record={record}
            setItem={setItem}
          /> 
        </BaseCard>
    );
  }
}

StartMain.propTypes = {
  classes: PropTypes.object.isRequired,
};

const StartCard = withStyles(styles)(StartMain);



class SendForm extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      source: {},
      preview: false,
      valid:false,
      count:0
    };
  }

  componentDidMount() {
    this.props.setValid(this.state.valid);
  }

  formconfig = () => {
    const config = {
      service: {
        name: 'resetform',
        title: 'Reset Form',
        eform: [
          { id:'act',label:'Code',type:'tf',req:1,ops:{ helperText:'Enter OTP Code',pattern:"[a-zA-Z ]+" } },
          { id:'npw',label:'Password',type:'pf',req:1,ops:{ helperText:'Enter new password'} },
          { id:'cpw',label:'Confirm',type:'pf',req:1,ops:{ helperText:'Confirm new password'} },
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  makeform = () => {
    const config = this.formconfig();
  
    const { record={}, extras, classes } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = {};
    const onChange = this.onChange.bind(this);
    const form = create(mode,sdata,record,formatters,onChange,classes, extras);
    return form;
  }

  setValid = (data:any)  => {
    const sconfig = this.formconfig();
    const mode = 'add';
    const {npw,cpw} = data||{};
    const valid = (npw === cpw) && isValid(sconfig,data,mode);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  render() {
    const form = this.makeform();
    return form;
  }
}

SendForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SendPanel = withStyles(styles, { withTheme: true })(SendForm);

class SendMain extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("InfoCard");
    this.state = {
      next: false,
      percent: 0,
      fwopen: false,
      error: false,
      status: 'Saving',
      loading:false,
    };
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  onProgress = (ev:any,perc:any) => {
    this.setState({percent:perc});
  }

  //this method is designed for basecard.handleSend
  doSubmit = async() => {
    const { classes, theme, showNotification, ...extraProps } = this.props;  //fmtUI, logo, title, switchFn,
    const { record,data,extras,setItem } = this.props;
    const {data:formdata} = data;

    const {code,service,mode,sconfig,onResponse,ft,ms,mt} = this.props;
    const {url,endpoint} = sconfig || {};
    //const {formData} = this.state;
    //console.log('onsubmit.formdata:',formData);
    let response;

    try{
      let s = 'security';
      let a = 'activate';
      //let recs = (mode==='delete')?{rid:+(record.rid),stp:record.stp}:record;
      let params = Object.assign(record,formdata,{s,a,ft,ms,mt});
      console.log('service request ',params);
      this.startLoading();
      if(endpoint==='api')
        response = await apipost(url,code,params);
      else
        response = await bizpost(params);
      //console.log('service response ',response);

      if (response.success){
        showNotification(response.sm,'success',response.st)
        //onResponse(true,response);
        return response;
      }
      else if(response.failure){
        if (response.en===60){
        }
        else{
          showNotification(response.message,response.type||'error',response.title);
          return Promise.reject(response);
        }
      }      
    }
    catch(err){
      const error:any = err || {};
      console.log('submit.error:',JSON.stringify(error));
      showNotification(error.message||"request failed",error.type||'warning',error.title||'Reset Request Error');
      return Promise.reject(response);
    }
    finally{
      this.stopLoading();
    }
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props;
    const {record,data,extras,formdata,setItem} = this.props;
    return (
      <BaseCard
        cardid={'SEND'}
        cardname={'Send'}
        cardtitle={'Confirm Reset'}
        instruction={'Enter the OTP received in email and set and confirm new password'}
        step={1}
        buttons={['START','CANCEL','PREV','SEND','GUIDE']}
        doSubmit={this.doSubmit.bind(this)}
        panelMode={false}
        {...extraProps}
      >
        <SendPanel 
          data={data}
          //record={record} 
          record={{...record,...formdata}} 
          extras={extras}
          setItem={setItem}
          />
      </BaseCard>
    );
  }
}
SendMain.propTypes = {
  classes: PropTypes.object.isRequired,
};
const SendCard = withStyles(styles)(SendMain);


const cards = {
  start: <StartCard name='start' />,
  send: <SendCard name='send' />
};
const list = ['start','send'];


class LoginReset extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      wizards:[],
    };
  }

  render = () => {
    //const {title,swidth,open,nodrag,sconfig,data,sdata,formatters,onChange,onSubmit,onClose,showNotification,classes,theme} = this.props;
    const {title='Reset Password',open,onClose,classes} = this.props;
    const {code,service,mode,record,handleClose, selected, reset, cvalue, cuparams,...others} = this.props;
    const extras = {selected, reset, cvalue, cuparams };
    //const {cards,list,meta} = getWizard(service,mode);
    //.. service='security',mode='reset/add',record={},open,title=,name,cards,list,onClose
    //const dragging = {PaperComponent:DraggablePaperComponent};
    //console.log(onClose);
    
    return (
      <div>
        <Dialog open={open} className={classes.resetdialog} maxWidth={"md"} fullWidth={false} PaperComponent={DraggablePaperComponent}>
          <DialogTitle id="window-dialog-title" className={classes.titlebar} disableTypography={true}>
            <ClosableBar onClose={onClose}>{title}</ClosableBar>
          </DialogTitle>
          <DialogContent dividers>
            <Wizard 
              service={'security'} 
              name={'reset'}
              mode={'wizard'} 
              record={record}
              code={''} 
              cards={cards} 
              list={list} 
              //meta={meta}
              wizClose={onClose}
              extras={extras}
              {...others}
            />
          </DialogContent>
        </Dialog>
      </div>
      
    );
  }
}

LoginReset.propTypes = {
  // service: PropTypes.string.isRequired,
  // mode: PropTypes.oneOf(['add','process','wizard']), //PropTypes.string.isRequired, 
  // sdata: PropTypes.object,
  // record: PropTypes.object,
  // onChange: PropTypes.func,
  // formatters: PropTypes.object
};

//WizardPanel = withStyles(styles, { withTheme: true })(WizardPanel);

export default withStyles(styles, { withTheme: true })(LoginReset);
