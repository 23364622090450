import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { extendedstyles } from '../../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'vertical',
    //flexWrap: 'wrap',
    //justifyContent: 'space-around',
    // overflow: 'hidden',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
  bullets: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(12),
  },
});
const styles = extendedstyles(extrastyles);

class StartPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      valid: true,
      iserror: false,
      message: '',
      count:0
    };
  }

  componentDidMount(){
    //disable next (using valid) if form not valid
    //this.props.setValid(this.state.valid);
    //check caller data that the request is valid
   // this.processRecord();
  }

  /**
   * processRecord: Check the validity of the incoming request by making sure 
   * all required data are available. Otherwise reject (setInvalid. Cannot proceed)
   */
  processRecord = () => {
    const {record,setItem,setValid} = this.props;
    const {pho,idf} = record;
    try{
      //
      var extra = JSON.parse(record.dtl);
      const {photo,card} = extra;
      var ophoto = photo[0];
      var ocard = card[0];
      var fphoto = !!pho?pho:ophoto.filename;
      var fcard = !!idf?idf:ocard.filename;
      if(!fphoto) throw new Error('Invalid photo file');
      if(!fcard) throw new Error('Invalid id file');
      var basepath = 'api/upload';
      var xphoto = {resid:'photo',src:`${basepath}/${fcard}`,title:'Photo'};
      var xcard = {resid:'idcard',src:`${basepath}/${fphoto}`,title:'ID Card'};
      var item = {photo:xphoto,idcard:xcard};
      this.setState({iserror:false,message:'User record processed'});
      setItem('data','start',item);
      setValid(true);
      //console.log('processrecord complete')
    }
    catch(err){
      const error:any = err || {};
      this.setState({iserror:true,message:error.message});
      setValid(false);
    }
  }

  render() {
    const { classes} = this.props;
    const {valid,iserror,message} = this.state;
    return (
      <div className={classes.root}>
        <Divider />
        <div className={classes.bullets}>
          <ul>
            <li>Provide Details for new agent</li>
            <li>Provide security credentials for accessing API and Backend (CB)</li>
            <li>Select Access Level to Backend Services</li>
            <li>Submit request to server</li>
            <li>Complete wizard</li>
          </ul>
        </div>
        <Typography className={valid?classes.success:classes.failure}>
          {message}
        </Typography>
      </div>
    );
  }
}

StartPanel.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(StartPanel);
