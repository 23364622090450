import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import { Typography } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ListIcon from '@material-ui/icons/List';
import {enableDragging} from '../dialogs/DraggablePaperComponent';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  badge:{
    margin: theme.spacing(1),
  },
  flex: {
    flex:1
  },
  hidden: {
    display: 'none'
  }
});

const styles = extendedstyles(extrastyles);

//array switch library. TODO: move to file
const tohash = (array:[]) => array.reduce(acc=>(acc<<1)|1,0);
const isset = (hash:number,i:number) => ((hash&(1<<i))>0);
const set = (hash:number,i:number) => hash|(1<<i);
const unset = (hash:number,i:number) => hash & ~(1<<i);
const tolist = (array:[]) => array.map((item:any)=>item.id);
const toarray = (array:[],hash:number) => array.filter((item,i)=>isset(hash,i));

//
// unsigned int count = 0; 
// while (n) 
// { 
//     count += n & 1; 
//     n >>= 1; 
// } 
// return count; 
//const count = hash => {if(!hash) return c}
//Draggable


class ColumnSelector extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    let array = props.cols || [];
    let list = tolist(array);
    let hash = tohash(props.cols||[]);
    let count = list.length;
    this.state = {
      array: array,
      list: list,
      hash: hash,
      count:count
    };
  }

  handleChange = (i:any) => (ev:any,v:any) => {
    console.log('cs change',i,v,this.state.hash);
    const {hash,list} = this.state;
    //update column selector
    this.setState((prevState:any)=>({hash:v>0?set(prevState.hash,i):unset(prevState.hash,i)}));
    //update grid
    const newhash = v?set(hash,i):unset(hash,i);
    const newlist = toarray(list,newhash);
    this.setState({count:newlist.length});
    this.props.setColumns({},newlist);
  }
  
  render() {
    const {open,onClose,classes } = this.props;
    const {array,count,hash} = this.state;
    return (
      
      <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      {...enableDragging}
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogTitle disableTypography>
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {"Select Columns"}
        </Typography>
        
        <Badge
          color="secondary"
          badgeContent={count}
          //invisible={!count}
          className={classes.badge}
        >
          {/* <ListIcon {...(!count&&{className:classes.hidden})} /> */}
          <ListIcon />
        </Badge>
        <IconButton color="inherit" onClick={onClose} aria-label="Close">
          <CloseIcon />
        </IconButton>
      </Toolbar>
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Toggle Columns</FormLabel>
          <FormGroup>
            {array.map((item:any,i:number)=>(
            <FormControlLabel key={i}
              control={
                <Checkbox checked={isset(hash,i)} onChange={this.handleChange(i)} />
              }
              label={item.label}
            />
            ))}
          </FormGroup>
          <FormHelperText>At least 1 column</FormHelperText>
        </FormControl>
      </DialogContent>
      {/* <DialogActions>
        <Button onClick={this.handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={this.handleClose} color="primary">
          Ok
        </Button>
      </DialogActions> */}
    </Dialog>
    );
  }
}

ColumnSelector.propTypes = {
  classes: PropTypes.object.isRequired,
  cols: PropTypes.arrayOf(PropTypes.object).isRequired,
  fcols: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  setColumns: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles)(ColumnSelector);
