import React from 'react';

import Dashboard from './Dashboard';
import BlockMenu from './Kanban';
//
import About from './About';
//import Audit from './Audit';
import Inbox from './Inbox';
import Help from './Help';
import Main from './Main';
import Mapboard from './Mapboard';
import Memboard from './Memboard';
//import Privilege from './Privilege';
import Password from './Password';
import Profile from './Profile';
//import Role from './Role';
import Search from './Search';
//import User from './User';
import Kanban from './Kanban';
import ReportService from '../reports/ReportService';
import Support from './Support';
import Testing from './Testing';
import TestPing from './Testping';

//logout is handled in Home
const pages:any = {
  about: <About />,
  search: <Search/>,
  dashboard:<Dashboard/>,
  menu: <BlockMenu />,

  help: <Help />,
  
  inbox: <Inbox />,

  kanban: <Kanban />,

  main: <Main />,
  mapboard: <Mapboard />,
  memboard: <Memboard />,
  password: <Password />,
  profile:<Profile />,

  subscriberreport: <ReportService />,
  support: <Support />,

  testapi: <Testing />,
  testping: <TestPing />,
  
};

export default pages;