import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function CSSIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
        c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
        c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
        z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
        c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.391V13.78c-0.532-0.48-1.229-0.78-2-0.78c-0.553,0-1,0.448-1,1s0.447,1,1,1c0.552,0,1,0.449,1,1v4
        c0,1.2,0.542,2.266,1.382,3c-0.84,0.734-1.382,1.8-1.382,3v4c0,0.551-0.448,1-1,1c-0.553,0-1,0.448-1,1s0.447,1,1,1
        c1.654,0,3-1.346,3-3v-4c0-1.103,0.897-2,2-2c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.103,0-2-0.897-2-2v-4
        c0-0.771-0.301-1.468-0.78-2h11.389c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M20.034,45.954c0.241-0.306,0.515-0.521,0.82-0.649s0.627-0.191,0.964-0.191c0.301,0,0.59,0.06,0.868,0.178
        s0.531,0.31,0.759,0.574l1.135-1.012c-0.374-0.364-0.798-0.638-1.271-0.82s-0.984-0.273-1.531-0.273
        c-0.593,0-1.144,0.111-1.654,0.335s-0.955,0.549-1.333,0.978s-0.675,0.964-0.889,1.606c-0.214,0.643-0.321,1.388-0.321,2.235
        s0.107,1.595,0.321,2.242c0.214,0.647,0.511,1.185,0.889,1.613s0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328
        c0.547,0,1.058-0.091,1.531-0.273s0.897-0.456,1.271-0.82l-1.135-0.998c-0.237,0.265-0.499,0.456-0.786,0.574
        s-0.595,0.178-0.923,0.178s-0.641-0.07-0.937-0.212s-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183s-0.203-1.066-0.212-1.75
        c0.009-0.702,0.082-1.294,0.219-1.777S19.793,46.26,20.034,45.954z"/>
      <path d="M31.532,49.064c-0.314-0.228-0.654-0.422-1.019-0.581s-0.702-0.323-1.012-0.492s-0.569-0.364-0.779-0.588
        s-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458s0.173-0.303,0.301-0.431s0.273-0.234,0.438-0.321
        s0.337-0.139,0.52-0.157c0.328-0.027,0.597-0.032,0.807-0.014s0.378,0.05,0.506,0.096s0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137s0.101-0.185,0.164-0.308c0.064-0.123,0.132-0.255,0.205-0.396
        s0.137-0.271,0.191-0.39c-0.265-0.173-0.61-0.299-1.039-0.376s-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191
        s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882s-0.246,0.743-0.246,1.189c0,0.492,0.104,0.902,0.314,1.23
        s0.474,0.613,0.793,0.854s0.661,0.451,1.025,0.629s0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595s0.314,0.483,0.314,0.793
        c0,0.511-0.148,0.896-0.444,1.155s-0.723,0.39-1.278,0.39c-0.183,0-0.378-0.019-0.588-0.055s-0.419-0.084-0.629-0.144
        s-0.412-0.123-0.608-0.191s-0.357-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.34,0.253,0.554,0.349s0.439,0.171,0.677,0.226
        s0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.511,0,0.966-0.077,1.367-0.232s0.738-0.362,1.012-0.622
        s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.104-0.978-0.314-1.319S31.847,49.292,31.532,49.064z"/>
      <path d="M38.942,49.064c-0.314-0.228-0.654-0.422-1.019-0.581s-0.702-0.323-1.012-0.492s-0.569-0.364-0.779-0.588
        s-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458s0.173-0.303,0.301-0.431s0.273-0.234,0.438-0.321
        s0.337-0.139,0.52-0.157c0.328-0.027,0.597-0.032,0.807-0.014s0.378,0.05,0.506,0.096s0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137s0.101-0.185,0.164-0.308s0.132-0.255,0.205-0.396
        s0.137-0.271,0.191-0.39c-0.265-0.173-0.61-0.299-1.039-0.376s-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191
        s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882s-0.246,0.743-0.246,1.189c0,0.492,0.104,0.902,0.314,1.23
        s0.474,0.613,0.793,0.854s0.661,0.451,1.025,0.629s0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595s0.314,0.483,0.314,0.793
        c0,0.511-0.148,0.896-0.444,1.155s-0.723,0.39-1.278,0.39c-0.183,0-0.378-0.019-0.588-0.055s-0.419-0.084-0.629-0.144
        s-0.412-0.123-0.608-0.191s-0.357-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.34,0.253,0.554,0.349s0.439,0.171,0.677,0.226
        s0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.511,0,0.966-0.077,1.367-0.232s0.738-0.362,1.012-0.622
        s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.104-0.978-0.314-1.319S39.257,49.292,38.942,49.064z"/>
      <path d="M20.5,20v-4c0-0.551,0.448-1,1-1c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.654,0-3,1.346-3,3v4c0,1.103-0.897,2-2,2
        c-0.553,0-1,0.448-1,1s0.447,1,1,1c1.103,0,2,0.897,2,2v4c0,1.654,1.346,3,3,3c0.553,0,1-0.448,1-1s-0.447-1-1-1
        c-0.552,0-1-0.449-1-1v-4c0-1.2-0.542-2.266-1.382-3C19.958,22.266,20.5,21.2,20.5,20z"/>
    </g>
    </SvgIcon>
  );
}

function CSVIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
        v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
        c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
        C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
        C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
        M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
        c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M18.034,45.954c0.241-0.306,0.515-0.521,0.82-0.649c0.305-0.128,0.626-0.191,0.964-0.191c0.301,0,0.59,0.06,0.868,0.178
        c0.278,0.118,0.531,0.31,0.759,0.574l1.135-1.012c-0.374-0.364-0.798-0.638-1.271-0.82c-0.474-0.183-0.984-0.273-1.531-0.273
        c-0.593,0-1.144,0.111-1.654,0.335c-0.511,0.224-0.955,0.549-1.333,0.978c-0.378,0.429-0.675,0.964-0.889,1.606
        c-0.214,0.643-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242c0.214,0.647,0.51,1.185,0.889,1.613
        c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328c0.547,0,1.057-0.091,1.531-0.273c0.474-0.183,0.897-0.456,1.271-0.82
        l-1.135-0.998c-0.237,0.265-0.499,0.456-0.786,0.574s-0.595,0.178-0.923,0.178s-0.641-0.07-0.937-0.212
        c-0.296-0.142-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183c-0.132-0.483-0.203-1.066-0.212-1.75
        c0.009-0.702,0.082-1.294,0.219-1.777S17.792,46.26,18.034,45.954z"/>
      <path d="M29.532,49.064c-0.314-0.228-0.654-0.422-1.019-0.581c-0.365-0.159-0.702-0.323-1.012-0.492
        c-0.31-0.169-0.57-0.364-0.779-0.588c-0.21-0.224-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458
        c0.073-0.159,0.173-0.303,0.301-0.431c0.127-0.128,0.273-0.234,0.438-0.321s0.337-0.139,0.52-0.157
        c0.328-0.027,0.597-0.032,0.807-0.014c0.209,0.019,0.378,0.05,0.506,0.096c0.127,0.046,0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137c0.045-0.082,0.1-0.185,0.164-0.308
        c0.063-0.123,0.132-0.255,0.205-0.396c0.073-0.142,0.137-0.271,0.191-0.39c-0.265-0.173-0.611-0.299-1.039-0.376
        c-0.429-0.077-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191s-0.693,0.313-0.971,0.554
        c-0.278,0.241-0.499,0.535-0.663,0.882s-0.246,0.743-0.246,1.189c0,0.492,0.104,0.902,0.314,1.23
        c0.209,0.328,0.474,0.613,0.793,0.854c0.319,0.241,0.661,0.451,1.025,0.629c0.364,0.178,0.704,0.355,1.019,0.533
        s0.576,0.376,0.786,0.595c0.209,0.219,0.314,0.483,0.314,0.793c0,0.511-0.148,0.896-0.444,1.155c-0.296,0.26-0.723,0.39-1.278,0.39
        c-0.183,0-0.378-0.019-0.588-0.055c-0.21-0.036-0.419-0.084-0.629-0.144c-0.21-0.06-0.413-0.123-0.608-0.191
        c-0.196-0.068-0.358-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.339,0.253,0.554,0.349c0.214,0.096,0.439,0.171,0.677,0.226
        c0.237,0.055,0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.51,0,0.966-0.077,1.367-0.232
        c0.401-0.155,0.738-0.362,1.012-0.622s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.105-0.978-0.314-1.319
        C30.108,49.577,29.847,49.292,29.532,49.064z"/>
      <polygon points="36.115,52.619 33.777,43.924 31.904,43.924 35.035,54.055 37.168,54.055 40.449,43.924 38.59,43.924 	"/>
      <path d="M24.5,13h-12v4v2v2v2v2v2v2v2v4h10h2h21v-4v-2v-2v-2v-2v-2v-4h-21V13z M14.5,19h8v2h-8V19z M14.5,23h8v2h-8V23z M14.5,27h8
        v2h-8V27z M22.5,33h-8v-2h8V33z M43.5,33h-19v-2h19V33z M43.5,29h-19v-2h19V29z M43.5,25h-19v-2h19V25z M43.5,19v2h-19v-2H43.5z
        M14.5,17v-2h8v2H14.5z"/>
    </g>
    </SvgIcon>
  );
}

function DOCIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
        v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
        c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
        C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
        C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
        M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
        c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M23.235,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814
        c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513
        s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757
        C23.976,46.355,23.659,45.847,23.235,45.4z M22.039,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957
        c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244
        C22.777,49.989,22.531,51.02,22.039,51.73z"/>
      <path d="M33.265,45.072c-0.378-0.429-0.82-0.754-1.326-0.978s-1.06-0.335-1.661-0.335s-1.155,0.111-1.661,0.335
        s-0.948,0.549-1.326,0.978c-0.378,0.429-0.675,0.964-0.889,1.606c-0.214,0.643-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242
        c0.214,0.647,0.51,1.185,0.889,1.613c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328s1.155-0.109,1.661-0.328
        s0.948-0.542,1.326-0.971c0.378-0.429,0.674-0.966,0.889-1.613c0.214-0.647,0.321-1.395,0.321-2.242s-0.107-1.593-0.321-2.235
        C33.939,46.036,33.643,45.501,33.265,45.072z M32.588,50.698c-0.137,0.487-0.326,0.882-0.567,1.183
        c-0.242,0.301-0.515,0.518-0.82,0.649c-0.306,0.132-0.627,0.198-0.964,0.198c-0.328,0-0.641-0.07-0.937-0.212
        c-0.296-0.142-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183c-0.132-0.483-0.203-1.066-0.212-1.75
        c0.009-0.702,0.082-1.294,0.219-1.777s0.326-0.877,0.567-1.183c0.241-0.306,0.515-0.521,0.82-0.649
        c0.305-0.128,0.626-0.191,0.964-0.191c0.328,0,0.64,0.068,0.937,0.205c0.296,0.137,0.561,0.36,0.793,0.67s0.415,0.704,0.547,1.183
        c0.132,0.479,0.203,1.06,0.212,1.743C32.797,49.616,32.725,50.211,32.588,50.698z"/>
      <path d="M39.062,45.954c0.241-0.306,0.515-0.521,0.82-0.649c0.305-0.128,0.626-0.191,0.964-0.191c0.301,0,0.59,0.06,0.868,0.178
        c0.278,0.118,0.531,0.31,0.759,0.574l1.135-1.012c-0.374-0.364-0.798-0.638-1.271-0.82c-0.474-0.183-0.984-0.273-1.531-0.273
        c-0.593,0-1.144,0.111-1.654,0.335c-0.511,0.224-0.955,0.549-1.333,0.978c-0.378,0.429-0.675,0.964-0.889,1.606
        c-0.214,0.643-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242c0.214,0.647,0.51,1.185,0.889,1.613
        c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328c0.547,0,1.057-0.091,1.531-0.273c0.474-0.183,0.897-0.456,1.271-0.82
        l-1.135-0.998c-0.237,0.265-0.499,0.456-0.786,0.574s-0.595,0.178-0.923,0.178s-0.641-0.07-0.937-0.212
        c-0.296-0.142-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183c-0.132-0.483-0.203-1.066-0.212-1.75
        c0.009-0.702,0.082-1.294,0.219-1.777S38.82,46.26,39.062,45.954z"/>
      <path d="M13.5,14h6c0.552,0,1-0.448,1-1s-0.448-1-1-1h-6c-0.552,0-1,0.448-1,1S12.948,14,13.5,14z"/>
      <path d="M13.5,19h9c0.552,0,1-0.448,1-1s-0.448-1-1-1h-9c-0.552,0-1,0.448-1,1S12.948,19,13.5,19z"/>
      <path d="M26.5,19c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.26-0.11-0.52-0.29-0.71
        c-0.37-0.37-1.05-0.37-1.42,0c-0.18,0.19-0.29,0.45-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C25.98,18.89,26.24,19,26.5,19z"/>
      <path d="M30.5,19h8c0.552,0,1-0.448,1-1s-0.448-1-1-1h-8c-0.552,0-1,0.448-1,1S29.948,19,30.5,19z"/>
      <path d="M12.79,32.29c-0.18,0.19-0.29,0.45-0.29,0.71c0,0.26,0.11,0.52,0.29,0.71C12.98,33.89,13.24,34,13.5,34
        c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.27-0.11-0.52-0.29-0.71C13.84,31.92,13.16,31.92,12.79,32.29z"/>
      <path d="M25.5,32h-8c-0.552,0-1,0.448-1,1s0.448,1,1,1h8c0.552,0,1-0.448,1-1S26.052,32,25.5,32z"/>
      <path d="M44.5,17h-2c-0.552,0-1,0.448-1,1s0.448,1,1,1h2c0.552,0,1-0.448,1-1S45.052,17,44.5,17z"/>
      <path d="M13.5,24h22c0.552,0,1-0.448,1-1s-0.448-1-1-1h-22c-0.552,0-1,0.448-1,1S12.948,24,13.5,24z"/>
      <path d="M44.5,22h-6c-0.552,0-1,0.448-1,1s0.448,1,1,1h6c0.552,0,1-0.448,1-1S45.052,22,44.5,22z"/>
      <path d="M13.5,29h4c0.552,0,1-0.448,1-1s-0.448-1-1-1h-4c-0.552,0-1,0.448-1,1S12.948,29,13.5,29z"/>
      <path d="M31.5,27h-10c-0.552,0-1,0.448-1,1s0.448,1,1,1h10c0.552,0,1-0.448,1-1S32.052,27,31.5,27z"/>
      <path d="M44.5,27h-9c-0.552,0-1,0.448-1,1s0.448,1,1,1h9c0.552,0,1-0.448,1-1S45.052,27,44.5,27z"/>
    </g>
    </SvgIcon>
  );
}

function HTMLIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.001-0.001l-0.771-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
        c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
        c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
        z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
        c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.391V14h11.608c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <polygon points="16.814,48.34 12.453,48.34 12.453,43.924 10.785,43.924 10.785,54 12.453,54 12.453,49.461 16.814,49.461 
        16.814,54 18.455,54 18.455,43.924 16.814,43.924 	"/>
      <polygon points="20.438,45.045 23.445,45.045 23.445,54 25.1,54 25.1,45.045 28.107,45.045 28.107,43.924 20.438,43.924 	"/>
      <polygon points="34.725,50.814 31.73,43.924 30.063,43.924 30.063,54 31.73,54 31.73,47.068 34,52.674 35.449,52.674 
        37.705,47.068 37.705,54 39.373,54 39.373,43.924 37.705,43.924 	"/>
      <polygon points="43.57,43.924 41.902,43.924 41.902,54 48.205,54 48.205,52.756 43.57,52.756 	"/>
      <path d="M24.207,17.293c-0.391-0.391-1.023-0.391-1.414,0l-6,6c-0.391,0.391-0.391,1.023,0,1.414l6,6
        C22.988,30.902,23.244,31,23.5,31s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L18.914,24l5.293-5.293
        C24.598,18.316,24.598,17.684,24.207,17.293z"/>
      <path d="M36.207,17.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L40.086,24l-5.293,5.293
        c-0.391,0.391-0.391,1.023,0,1.414C34.988,30.902,35.244,31,35.5,31s0.512-0.098,0.707-0.293l6-6c0.391-0.391,0.391-1.023,0-1.414
        L36.207,17.293z"/>
    </g>
    </SvgIcon>
  );
}

function JSIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.001-0.001l-0.771-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
        c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
        c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
        z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
        c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.391V13.78c-0.532-0.48-1.229-0.78-2-0.78c-0.553,0-1,0.448-1,1s0.447,1,1,1c0.552,0,1,0.449,1,1v4
        c0,1.2,0.542,2.266,1.382,3c-0.84,0.734-1.382,1.8-1.382,3v4c0,0.551-0.448,1-1,1c-0.553,0-1,0.448-1,1s0.447,1,1,1
        c1.654,0,3-1.346,3-3v-4c0-1.103,0.897-2,2-2c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.103,0-2-0.897-2-2v-4
        c0-0.771-0.301-1.468-0.78-2h11.389c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M25.354,51.43c-0.019,0.446-0.171,0.764-0.458,0.95s-0.673,0.28-1.155,0.28c-0.191,0-0.396-0.022-0.615-0.068
        s-0.429-0.098-0.629-0.157c-0.201-0.06-0.385-0.123-0.554-0.191s-0.299-0.135-0.39-0.198l-0.697,1.107
        c0.182,0.137,0.405,0.26,0.67,0.369c0.264,0.109,0.54,0.207,0.827,0.294s0.565,0.15,0.834,0.191s0.503,0.062,0.704,0.062
        c0.4,0,0.791-0.039,1.169-0.116s0.713-0.214,1.005-0.41c0.291-0.196,0.523-0.456,0.697-0.779c0.173-0.323,0.26-0.723,0.26-1.196
        v-7.848h-1.668V51.43z"/>
      <path d="M34.083,49.064c-0.314-0.228-0.654-0.422-1.019-0.581c-0.365-0.159-0.702-0.323-1.012-0.492
        c-0.311-0.169-0.57-0.364-0.779-0.588c-0.21-0.224-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458
        c0.072-0.159,0.173-0.303,0.301-0.431c0.127-0.128,0.273-0.234,0.438-0.321s0.337-0.139,0.52-0.157
        c0.328-0.027,0.597-0.032,0.807-0.014c0.209,0.019,0.378,0.05,0.506,0.096c0.127,0.046,0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137c0.045-0.082,0.1-0.185,0.164-0.308
        c0.063-0.123,0.132-0.255,0.205-0.396c0.072-0.142,0.137-0.271,0.191-0.39c-0.265-0.173-0.611-0.299-1.039-0.376
        c-0.429-0.077-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882
        S29.4,46.13,29.4,46.576c0,0.492,0.104,0.902,0.314,1.23c0.209,0.328,0.474,0.613,0.793,0.854c0.318,0.241,0.66,0.451,1.025,0.629
        c0.364,0.178,0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595c0.209,0.219,0.314,0.483,0.314,0.793
        c0,0.511-0.148,0.896-0.444,1.155s-0.723,0.39-1.278,0.39c-0.183,0-0.379-0.019-0.588-0.055c-0.21-0.036-0.42-0.084-0.629-0.144
        c-0.21-0.06-0.412-0.123-0.608-0.191s-0.358-0.139-0.485-0.212l-0.287,1.176c0.154,0.137,0.34,0.253,0.554,0.349
        s0.439,0.171,0.677,0.226c0.236,0.055,0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.51,0,0.966-0.077,1.367-0.232
        c0.4-0.155,0.738-0.362,1.012-0.622s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.105-0.978-0.314-1.319
        C34.659,49.577,34.397,49.292,34.083,49.064z"/>
      <path d="M20.5,20v-4c0-0.551,0.448-1,1-1c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.654,0-3,1.346-3,3v4c0,1.103-0.897,2-2,2
        c-0.553,0-1,0.448-1,1s0.447,1,1,1c1.103,0,2,0.897,2,2v4c0,1.654,1.346,3,3,3c0.553,0,1-0.448,1-1s-0.447-1-1-1
        c-0.552,0-1-0.449-1-1v-4c0-1.2-0.542-2.266-1.382-3C19.958,22.266,20.5,21.2,20.5,20z"/>
      <circle cx="28.5" cy="19.5" r="1.5"/>
      <path d="M28.5,25c-0.553,0-1,0.448-1,1v3c0,0.552,0.447,1,1,1s1-0.448,1-1v-3C29.5,25.448,29.053,25,28.5,25z"/>
    </g>
    </SvgIcon>
  );
}

function JPGIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-5.818-5.818l-5.047-5.048l0,0l-0.77-0.77C38.964,0.201,38.48,0,37.985,0H8.963
        C7.777,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042
        C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.101-0.029,0.152-0.043
        C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187z M47.935,12H39.5V3.565l4.248,4.249
        L47.935,12z M8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.608c0.135,0,0.265-0.025,0.391-0.058
        c0,0.015,0.001,0.021,0.001,0.036v12.693l-8.311-7.896c-0.195-0.185-0.445-0.284-0.725-0.274c-0.269,0.01-0.521,0.127-0.703,0.325
        l-9.795,10.727l-4.743-4.743c-0.369-0.369-0.958-0.392-1.355-0.055L8.5,37.836V2.926C8.5,2.709,8.533,2,8.963,2z M8.963,56
        c-0.071,0-0.135-0.026-0.198-0.049C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.11,0.34-0.265,0.414
        C49.172,55.975,49.108,56,49.037,56H8.963z M10.218,39l14.245-12.124L34.776,37.19c0.391,0.391,1.023,0.391,1.414,0
        s0.391-1.023,0-1.414l-4.807-4.807l9.168-10.041l8.949,8.501V39H10.218z"/>
      <path d="M19.354,51.43c-0.019,0.446-0.171,0.764-0.458,0.95s-0.672,0.28-1.155,0.28c-0.191,0-0.396-0.022-0.615-0.068
        s-0.429-0.098-0.629-0.157c-0.201-0.06-0.385-0.123-0.554-0.191c-0.169-0.068-0.299-0.135-0.39-0.198l-0.697,1.107
        c0.182,0.137,0.405,0.26,0.67,0.369c0.264,0.109,0.54,0.207,0.827,0.294s0.565,0.15,0.834,0.191
        c0.269,0.041,0.503,0.062,0.704,0.062c0.401,0,0.791-0.039,1.169-0.116c0.378-0.077,0.713-0.214,1.005-0.41
        c0.292-0.196,0.524-0.456,0.697-0.779c0.173-0.323,0.26-0.723,0.26-1.196v-7.848h-1.668V51.43z"/>
      <path d="M28.767,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
        c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
        c0-0.483-0.103-0.918-0.308-1.306S29.099,45.018,28.767,44.744z M28.145,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
        s-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
        c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
        C28.295,47.451,28.245,47.795,28.145,48.073z"/>
      <path d="M35.76,49.926h1.709v2.488c-0.073,0.101-0.187,0.178-0.342,0.232c-0.155,0.055-0.317,0.098-0.485,0.13
        c-0.169,0.032-0.337,0.055-0.506,0.068c-0.169,0.014-0.303,0.021-0.403,0.021c-0.328,0-0.641-0.077-0.937-0.232
        c-0.296-0.155-0.561-0.392-0.793-0.711s-0.415-0.729-0.547-1.23c-0.132-0.501-0.203-1.094-0.212-1.777
        c0.009-0.702,0.082-1.294,0.219-1.777s0.326-0.877,0.567-1.183c0.241-0.306,0.515-0.521,0.82-0.649
        c0.305-0.128,0.626-0.191,0.964-0.191c0.301,0,0.592,0.06,0.875,0.178c0.282,0.118,0.533,0.31,0.752,0.574l1.135-1.012
        c-0.374-0.364-0.798-0.638-1.271-0.82c-0.474-0.183-0.984-0.273-1.531-0.273c-0.593,0-1.144,0.111-1.654,0.335
        c-0.511,0.224-0.955,0.549-1.333,0.978c-0.378,0.429-0.675,0.964-0.889,1.606c-0.214,0.643-0.321,1.388-0.321,2.235
        s0.107,1.595,0.321,2.242c0.214,0.647,0.51,1.185,0.889,1.613c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328
        c0.301,0,0.604-0.022,0.909-0.068c0.305-0.046,0.602-0.123,0.889-0.232s0.561-0.248,0.82-0.417s0.494-0.385,0.704-0.649v-3.896
        H35.76V49.926z"/>
      <path d="M19.069,23.638c3.071,0,5.569-2.498,5.569-5.569S22.14,12.5,19.069,12.5S13.5,14.998,13.5,18.069
        S15.998,23.638,19.069,23.638z M19.069,14.5c1.968,0,3.569,1.601,3.569,3.569s-1.601,3.569-3.569,3.569S15.5,20.037,15.5,18.069
        S17.101,14.5,19.069,14.5z"/>
    </g>
    </SvgIcon>
  );
}

function JSONIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
        c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
        c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
        z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
        c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.391V13.78c-0.532-0.48-1.229-0.78-2-0.78c-0.553,0-1,0.448-1,1s0.447,1,1,1c0.552,0,1,0.449,1,1v4
        c0,1.2,0.542,2.266,1.382,3c-0.84,0.734-1.382,1.8-1.382,3v4c0,0.551-0.448,1-1,1c-0.553,0-1,0.448-1,1s0.447,1,1,1
        c1.654,0,3-1.346,3-3v-4c0-1.103,0.897-2,2-2c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.103,0-2-0.897-2-2v-4
        c0-0.771-0.301-1.468-0.78-2h11.389c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M16.354,51.43c-0.019,0.446-0.171,0.764-0.458,0.95s-0.672,0.28-1.155,0.28c-0.191,0-0.396-0.022-0.615-0.068
        s-0.429-0.098-0.629-0.157s-0.385-0.123-0.554-0.191s-0.299-0.135-0.39-0.198l-0.697,1.107c0.183,0.137,0.405,0.26,0.67,0.369
        s0.54,0.207,0.827,0.294s0.565,0.15,0.834,0.191s0.504,0.062,0.704,0.062c0.401,0,0.791-0.039,1.169-0.116
        c0.378-0.077,0.713-0.214,1.005-0.41s0.524-0.456,0.697-0.779s0.26-0.723,0.26-1.196v-7.848h-1.668V51.43z"/>
      <path d="M25.083,49.064c-0.314-0.228-0.654-0.422-1.019-0.581s-0.702-0.323-1.012-0.492s-0.569-0.364-0.779-0.588
        s-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458s0.173-0.303,0.301-0.431s0.273-0.234,0.438-0.321
        s0.337-0.139,0.52-0.157c0.328-0.027,0.597-0.032,0.807-0.014s0.378,0.05,0.506,0.096s0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137s0.101-0.185,0.164-0.308s0.132-0.255,0.205-0.396
        s0.137-0.271,0.191-0.39c-0.265-0.173-0.61-0.299-1.039-0.376s-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191
        s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882S20.4,46.13,20.4,46.576c0,0.492,0.104,0.902,0.314,1.23
        s0.474,0.613,0.793,0.854s0.661,0.451,1.025,0.629s0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595s0.314,0.483,0.314,0.793
        c0,0.511-0.148,0.896-0.444,1.155s-0.723,0.39-1.278,0.39c-0.183,0-0.378-0.019-0.588-0.055s-0.419-0.084-0.629-0.144
        s-0.412-0.123-0.608-0.191s-0.357-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.34,0.253,0.554,0.349s0.439,0.171,0.677,0.226
        c0.237,0.055,0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.511,0,0.966-0.077,1.367-0.232s0.738-0.362,1.012-0.622
        s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.104-0.978-0.314-1.319S25.397,49.292,25.083,49.064z"/>
      <path d="M34.872,45.072c-0.378-0.429-0.82-0.754-1.326-0.978s-1.06-0.335-1.661-0.335s-1.155,0.111-1.661,0.335
        s-0.948,0.549-1.326,0.978s-0.675,0.964-0.889,1.606s-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242s0.511,1.185,0.889,1.613
        s0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328s1.155-0.109,1.661-0.328s0.948-0.542,1.326-0.971s0.675-0.966,0.889-1.613
        s0.321-1.395,0.321-2.242s-0.107-1.593-0.321-2.235S35.25,45.501,34.872,45.072z M34.195,50.698
        c-0.137,0.487-0.326,0.882-0.567,1.183s-0.515,0.518-0.82,0.649s-0.627,0.198-0.964,0.198c-0.328,0-0.641-0.07-0.937-0.212
        s-0.561-0.364-0.793-0.67s-0.415-0.699-0.547-1.183s-0.203-1.066-0.212-1.75c0.009-0.702,0.082-1.294,0.219-1.777
        c0.137-0.483,0.326-0.877,0.567-1.183s0.515-0.521,0.82-0.649s0.627-0.191,0.964-0.191c0.328,0,0.641,0.068,0.937,0.205
        s0.561,0.36,0.793,0.67s0.415,0.704,0.547,1.183s0.203,1.06,0.212,1.743C34.405,49.616,34.332,50.211,34.195,50.698z"/>
      <polygon points="44.012,50.869 40.061,43.924 38.393,43.924 38.393,54 40.061,54 40.061,47.055 44.012,54 45.68,54 45.68,43.924 
        44.012,43.924"/>
      <path d="M20.5,20v-4c0-0.551,0.448-1,1-1c0.553,0,1-0.448,1-1s-0.447-1-1-1c-1.654,0-3,1.346-3,3v4c0,1.103-0.897,2-2,2
        c-0.553,0-1,0.448-1,1s0.447,1,1,1c1.103,0,2,0.897,2,2v4c0,1.654,1.346,3,3,3c0.553,0,1-0.448,1-1s-0.447-1-1-1
        c-0.552,0-1-0.449-1-1v-4c0-1.2-0.542-2.266-1.382-3C19.958,22.266,20.5,21.2,20.5,20z"/>
      <circle cx="28.5" cy="19.5" r="1.5"/>
      <path d="M28.5,25c-0.553,0-1,0.448-1,1v3c0,0.552,0.447,1,1,1s1-0.448,1-1v-3C29.5,25.448,29.053,25,28.5,25z"/>
    </g>
    </SvgIcon>
  );
}

function MP4Icon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.77-0.77l0,0L40.084,1.321c0,0-0.001-0.001-0.002-0.001l-0.768-0.768C38.965,0.201,38.48,0,37.985,0H8.963
        C7.777,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.838,0.843,1.654,1.839,1.91c0.05,0.013,0.097,0.032,0.148,0.042
        C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048c0.051-0.01,0.098-0.029,0.148-0.042
        C50.657,57.654,51.5,56.838,51.5,56v-0.463V39V13.978C51.5,13.213,51.408,12.646,50.95,12.187z M42.5,21c0,7.168-5.832,13-13,13
        s-13-5.832-13-13s5.832-13,13-13c2.898,0,5.721,0.977,8,2.76V13v1h1h1.948C41.792,16.093,42.5,18.502,42.5,21z M40.981,12H39.5
        v-1.717V3.565L47.935,12H40.981z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.609,55.876,8.5,55.72,8.5,55.537V41h41v14.537
        c0,0.183-0.109,0.339-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.392v5.942C35.115,6.826,32.342,6,29.5,6c-8.271,0-15,6.729-15,15s6.729,15,15,15s15-6.729,15-15
        c0-2.465-0.607-4.849-1.749-7h6.357c0.135,0,0.264-0.025,0.39-0.058c0,0.014,0.001,0.02,0.001,0.036V39H8.5z"/>
      <polygon points="20.42,50.814 17.426,43.924 15.758,43.924 15.758,54 17.426,54 17.426,47.068 19.695,52.674 21.145,52.674 
        23.4,47.068 23.4,54 25.068,54 25.068,43.924 23.4,43.924"/>
      <path d="M32.868,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205H27.57V54h1.641v-3.637h1.217
        c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
        c0-0.483-0.103-0.918-0.308-1.306S33.201,45.018,32.868,44.744z M32.246,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
        s-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
        c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
        C32.396,47.451,32.346,47.795,32.246,48.073z"/>
      <path d="M41.146,43.924h-1.668l-4.361,6.426v1.299h4.361V54h1.668v-2.352h1.053V50.35h-1.053V43.924z M39.479,50.35H36.58
        l2.898-4.512V50.35z"/>
      <path d="M37.037,20.156l-11-7c-0.308-0.195-0.698-0.208-1.019-0.033C24.699,13.299,24.5,13.635,24.5,14v14
        c0,0.365,0.199,0.701,0.519,0.877C25.169,28.959,25.334,29,25.5,29c0.187,0,0.374-0.053,0.537-0.156l11-7
        C37.325,21.66,37.5,21.342,37.5,21S37.325,20.34,37.037,20.156z M26.5,26.179V15.821L34.637,21L26.5,26.179z"/>
    </g>
    </SvgIcon>
  );
}

function PDFIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
      <g> 
      <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
        v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
        c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
        C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
        C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
        M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
        c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <path d="M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
        c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
        c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
        c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
        c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
        C21.57,47.451,21.52,47.795,21.42,48.073z"/>
      <path d="M31.954,45.4c-0.424-0.446-0.957-0.805-1.6-1.073s-1.388-0.403-2.235-0.403h-3.035V54h3.814
        c0.127,0,0.323-0.016,0.588-0.048c0.264-0.032,0.556-0.104,0.875-0.219c0.319-0.114,0.649-0.285,0.991-0.513
        s0.649-0.54,0.923-0.937s0.499-0.889,0.677-1.477s0.267-1.297,0.267-2.126c0-0.602-0.105-1.188-0.314-1.757
        C32.694,46.355,32.378,45.847,31.954,45.4z M30.758,51.73c-0.492,0.711-1.294,1.066-2.406,1.066h-1.627v-7.629h0.957
        c0.784,0,1.422,0.103,1.914,0.308s0.882,0.474,1.169,0.807s0.48,0.704,0.581,1.114c0.1,0.41,0.15,0.825,0.15,1.244
        C31.496,49.989,31.25,51.02,30.758,51.73z"/>
      <polygon points="35.598,54 37.266,54 37.266,49.461 41.477,49.461 41.477,48.34 37.266,48.34 37.266,45.168 41.9,45.168 
        41.9,43.924 35.598,43.924"/>
      <path d="M38.428,22.961c-0.919,0-2.047,0.12-3.358,0.358c-1.83-1.942-3.74-4.778-5.088-7.562c1.337-5.629,0.668-6.426,0.373-6.802
        c-0.314-0.4-0.757-1.049-1.261-1.049c-0.211,0-0.787,0.096-1.016,0.172c-0.576,0.192-0.886,0.636-1.134,1.215
        c-0.707,1.653,0.263,4.471,1.261,6.643c-0.853,3.393-2.284,7.454-3.788,10.75c-3.79,1.736-5.803,3.441-5.985,5.068
        c-0.066,0.592,0.074,1.461,1.115,2.242c0.285,0.213,0.619,0.326,0.967,0.326h0c0.875,0,1.759-0.67,2.782-2.107
        c0.746-1.048,1.547-2.477,2.383-4.251c2.678-1.171,5.991-2.229,8.828-2.822c1.58,1.517,2.995,2.285,4.211,2.285
        c0.896,0,1.664-0.412,2.22-1.191c0.579-0.811,0.711-1.537,0.39-2.16C40.943,23.327,39.994,22.961,38.428,22.961z M20.536,32.634
        c-0.468-0.359-0.441-0.601-0.431-0.692c0.062-0.556,0.933-1.543,3.07-2.744C21.555,32.19,20.685,32.587,20.536,32.634z
        M28.736,9.712c0.043-0.014,1.045,1.101,0.096,3.216C27.406,11.469,28.638,9.745,28.736,9.712z M26.669,25.738
        c1.015-2.419,1.959-5.09,2.674-7.564c1.123,2.018,2.472,3.976,3.822,5.544C31.031,24.219,28.759,24.926,26.669,25.738z
        M39.57,25.259C39.262,25.69,38.594,25.7,38.36,25.7c-0.533,0-0.732-0.317-1.547-0.944c0.672-0.086,1.306-0.108,1.811-0.108
        c0.889,0,1.052,0.131,1.175,0.197C39.777,24.916,39.719,25.05,39.57,25.259z"/>
      </g>
    </SvgIcon>
  );
}

function PNGIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
        v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
        c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
        C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M11.5,2c0,0.552,0.448,1,1,1s1-0.448,1-1h2
        c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1
        s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h0.058C37.525,2.126,37.5,2.256,37.5,2.391V6v4
        v4h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1
        c0-0.02-0.01-0.037-0.011-0.057c0.003-0.001,0.007-0.001,0.01-0.002c0,0.015,0.001,0.021,0.001,0.036V14v4v4v4v0.67l-0.163-0.155
        C49.431,26.362,49.5,26.193,49.5,26c0-0.552-0.448-1-1-1c-0.211,0-0.395,0.08-0.557,0.191l-0.648-0.615
        C47.415,24.411,47.5,24.219,47.5,24c0-0.552-0.448-1-1-1c-0.238,0-0.447,0.095-0.618,0.233l-0.627-0.596
        C45.401,22.463,45.5,22.246,45.5,22c0-0.552-0.448-1-1-1c-0.264,0-0.501,0.107-0.68,0.275l-0.607-0.577
        C43.39,20.518,43.5,20.272,43.5,20c0-0.552-0.448-1-1-1c-0.291,0-0.547,0.129-0.73,0.327l-0.581-0.552
        c-0.013-0.012-0.029-0.016-0.042-0.027C41.359,18.565,41.5,18.302,41.5,18c0-0.552-0.448-1-1-1s-1,0.448-1,1
        c0,0.306,0.145,0.57,0.361,0.753c-0.031,0.027-0.071,0.042-0.099,0.073l-0.5,0.548C39.078,19.151,38.811,19,38.5,19
        c-0.552,0-1,0.448-1,1c0,0.344,0.184,0.632,0.448,0.812l-0.613,0.671C37.159,21.199,36.859,21,36.5,21c-0.552,0-1,0.448-1,1
        c0,0.39,0.23,0.72,0.556,0.884l-0.647,0.708C35.253,23.245,34.906,23,34.5,23c-0.552,0-1,0.448-1,1c0,0.44,0.288,0.802,0.683,0.936
        l-0.735,0.805C33.332,25.318,32.96,25,32.5,25c-0.552,0-1,0.448-1,1c0,0.492,0.362,0.882,0.83,0.966l-0.85,0.931
        C31.425,27.396,31.016,27,30.5,27c-0.552,0-1,0.448-1,1c0,0.544,0.436,0.982,0.976,0.995l-0.509,0.558l-4.743-4.743
        c-0.369-0.369-0.958-0.392-1.355-0.055l-6.412,5.457C17.473,30.142,17.5,30.075,17.5,30c0-0.552-0.448-1-1-1s-1,0.448-1,1
        c0,0.552,0.448,1,1,1c0.015,0,0.027-0.008,0.042-0.008l-1.063,0.904C15.425,31.395,15.015,31,14.5,31c-0.552,0-1,0.448-1,1
        c0,0.461,0.318,0.832,0.743,0.948l-0.815,0.694C13.283,33.268,12.925,33,12.5,33c-0.552,0-1,0.448-1,1
        c0,0.369,0.209,0.678,0.507,0.851l-0.697,0.593C11.129,35.183,10.842,35,10.5,35c-0.552,0-1,0.448-1,1
        c0,0.284,0.121,0.538,0.311,0.72L8.5,37.836V35c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1
        c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2
        c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1
        V7c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1V3c0.552,0,1-0.448,1-1H11.5z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
        C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
        M10.218,39l14.134-12.03C24.403,26.978,24.448,27,24.5,27c0.025,0,0.047-0.013,0.071-0.014L34.776,37.19
        c0.391,0.391,1.023,0.391,1.414,0s0.391-1.023,0-1.414l-4.807-4.807l9.168-10.041L49.5,29.43V39H10.218z"/>
      <path d="M22.042,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
        c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
        c0-0.483-0.103-0.918-0.308-1.306S22.375,45.018,22.042,44.744z M21.42,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
        c-0.164,0.155-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
        c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
        C21.57,47.451,21.52,47.795,21.42,48.073z"/>
      <polygon points="30.648,50.869 26.697,43.924 25.029,43.924 25.029,54 26.697,54 26.697,47.055 30.648,54 32.316,54 32.316,43.924 
        30.648,43.924 	"/>
      <path d="M38.824,49.926h1.709v2.488c-0.073,0.101-0.187,0.178-0.342,0.232c-0.155,0.055-0.317,0.098-0.485,0.13
        c-0.169,0.032-0.337,0.055-0.506,0.068c-0.169,0.014-0.303,0.021-0.403,0.021c-0.328,0-0.641-0.077-0.937-0.232
        c-0.296-0.155-0.561-0.392-0.793-0.711s-0.415-0.729-0.547-1.23c-0.132-0.501-0.203-1.094-0.212-1.777
        c0.009-0.702,0.082-1.294,0.219-1.777s0.326-0.877,0.567-1.183c0.241-0.306,0.515-0.521,0.82-0.649
        c0.305-0.128,0.626-0.191,0.964-0.191c0.301,0,0.592,0.06,0.875,0.178c0.282,0.118,0.533,0.31,0.752,0.574l1.135-1.012
        c-0.374-0.364-0.798-0.638-1.271-0.82c-0.474-0.183-0.984-0.273-1.531-0.273c-0.593,0-1.144,0.111-1.654,0.335
        c-0.511,0.224-0.955,0.549-1.333,0.978c-0.378,0.429-0.675,0.964-0.889,1.606c-0.214,0.643-0.321,1.388-0.321,2.235
        s0.107,1.595,0.321,2.242c0.214,0.647,0.51,1.185,0.889,1.613c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328
        c0.301,0,0.604-0.022,0.909-0.068c0.305-0.046,0.602-0.123,0.889-0.232s0.561-0.248,0.82-0.417s0.494-0.385,0.704-0.649v-3.896
        h-3.336V49.926z"/>
      <circle cx="10.5" cy="4" r="1"/>
      <circle cx="14.5" cy="4" r="1"/>
      <circle cx="18.5" cy="4" r="1"/>
      <circle cx="22.5" cy="4" r="1"/>
      <circle cx="26.5" cy="4" r="1"/>
      <circle cx="30.5" cy="4" r="1"/>
      <circle cx="34.5" cy="4" r="1"/>
      <circle cx="36.5" cy="6" r="1"/>
      <circle cx="32.5" cy="6" r="1"/>
      <circle cx="28.5" cy="6" r="1"/>
      <circle cx="24.5" cy="6" r="1"/>
      <circle cx="20.5" cy="6" r="1"/>
      <circle cx="16.5" cy="6" r="1"/>
      <circle cx="12.5" cy="6" r="1"/>
      <circle cx="10.5" cy="8" r="1"/>
      <circle cx="14.5" cy="8" r="1"/>
      <circle cx="18.5" cy="8" r="1"/>
      <circle cx="22.5" cy="8" r="1"/>
      <circle cx="26.5" cy="8" r="1"/>
      <circle cx="30.5" cy="8" r="1"/>
      <circle cx="34.5" cy="8" r="1"/>
      <circle cx="36.5" cy="10" r="1"/>
      <circle cx="32.5" cy="10" r="1"/>
      <circle cx="28.5" cy="10" r="1"/>
      <circle cx="24.5" cy="10" r="1"/>
      <circle cx="20.5" cy="10" r="1"/>
      <circle cx="16.5" cy="10" r="1"/>
      <circle cx="12.5" cy="10" r="1"/>
      <circle cx="10.5" cy="12" r="1"/>
      <circle cx="22.5" cy="12" r="1"/>
      <circle cx="26.5" cy="12" r="1"/>
      <circle cx="30.5" cy="12" r="1"/>
      <circle cx="34.5" cy="12" r="1"/>
      <circle cx="36.5" cy="14" r="1"/>
      <circle cx="32.5" cy="14" r="1"/>
      <circle cx="28.5" cy="14" r="1"/>
      <circle cx="24.5" cy="14" r="1"/>
      <circle cx="26.5" cy="16" r="1"/>
      <circle cx="30.5" cy="16" r="1"/>
      <circle cx="34.5" cy="16" r="1"/>
      <circle cx="38.5" cy="16" r="1"/>
      <circle cx="42.5" cy="16" r="1"/>
      <circle cx="44.5" cy="18" r="1"/>
      <circle cx="48.5" cy="22" r="1"/>
      <circle cx="46.5" cy="20" r="1"/>
      <circle cx="48.5" cy="18" r="1"/>
      <circle cx="46.5" cy="16" r="1"/>
      <circle cx="36.5" cy="18" r="1"/>
      <circle cx="32.5" cy="18" r="1"/>
      <circle cx="28.5" cy="18" r="1"/>
      <circle cx="24.5" cy="18" r="1"/>
      <path d="M10.5,21c0.426,0,0.784-0.269,0.928-0.644c0.136,0.301,0.296,0.589,0.481,0.858C11.667,21.397,11.5,21.673,11.5,22
        c0,0.552,0.448,1,1,1c0.322,0,0.595-0.162,0.778-0.398c0.258,0.184,0.533,0.345,0.822,0.484C13.747,23.241,13.5,23.591,13.5,24
        c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.178-0.059-0.336-0.14-0.481c0.368,0.077,0.749,0.118,1.14,0.118s0.772-0.041,1.14-0.118
        C17.559,23.664,17.5,23.822,17.5,24c0,0.552,0.448,1,1,1s1-0.448,1-1c0-0.409-0.247-0.759-0.599-0.914
        c0.288-0.139,0.563-0.299,0.822-0.484C19.905,22.838,20.178,23,20.5,23c0.552,0,1-0.448,1-1c0-0.327-0.167-0.603-0.409-0.785
        c0.185-0.27,0.345-0.557,0.481-0.858C21.716,20.731,22.074,21,22.5,21c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1
        c-0.205,0-0.385,0.077-0.543,0.182c0.073-0.36,0.112-0.732,0.112-1.114c0-0.441-0.057-0.868-0.154-1.28
        C22.081,16.913,22.277,17,22.5,17c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1c-0.46,0-0.831,0.318-0.948,0.741
        c-0.148-0.321-0.326-0.625-0.53-0.909C21.303,14.654,21.5,14.356,21.5,14c0-0.552-0.448-1-1-1c-0.361,0-0.664,0.202-0.839,0.489
        c-0.298-0.207-0.617-0.385-0.954-0.531C19.156,12.86,19.5,12.479,19.5,12c0-0.552-0.448-1-1-1s-1,0.448-1,1
        c0,0.25,0.101,0.472,0.253,0.647C17.349,12.554,16.931,12.5,16.5,12.5s-0.849,0.054-1.253,0.147C15.399,12.472,15.5,12.25,15.5,12
        c0-0.552-0.448-1-1-1s-1,0.448-1,1c0,0.479,0.344,0.86,0.793,0.958c-0.337,0.146-0.655,0.324-0.954,0.531
        C13.164,13.202,12.861,13,12.5,13c-0.552,0-1,0.448-1,1c0,0.356,0.197,0.654,0.478,0.832c-0.204,0.284-0.381,0.589-0.53,0.909
        C11.331,15.318,10.96,15,10.5,15c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1c0.223,0,0.419-0.087,0.585-0.211
        c-0.097,0.412-0.154,0.839-0.154,1.28c0,0.381,0.039,0.754,0.112,1.114C10.885,19.077,10.705,19,10.5,19c-0.552,0-1,0.448-1,1
        C9.5,20.552,9.948,21,10.5,21z M16.5,14.5c1.968,0,3.569,1.601,3.569,3.569s-1.601,3.569-3.569,3.569s-3.569-1.601-3.569-3.569
        S14.532,14.5,16.5,14.5z"/>
      <circle cx="26.5" cy="20" r="1"/>
      <circle cx="30.5" cy="20" r="1"/>
      <circle cx="34.5" cy="20" r="1"/>
      <circle cx="32.5" cy="22" r="1"/>
      <circle cx="28.5" cy="22" r="1"/>
      <circle cx="24.5" cy="22" r="1"/>
      <circle cx="10.5" cy="24" r="1"/>
      <circle cx="22.5" cy="24" r="1"/>
      <circle cx="26.5" cy="24" r="1"/>
      <circle cx="30.5" cy="24" r="1"/>
      <circle cx="28.5" cy="26" r="1"/>
      <circle cx="20.5" cy="26" r="1"/>
      <circle cx="16.5" cy="26" r="1"/>
      <circle cx="12.5" cy="26" r="1"/>
      <circle cx="10.5" cy="28" r="1"/>
      <circle cx="14.5" cy="28" r="1"/>
      <circle cx="18.5" cy="28" r="1"/>
      <circle cx="12.5" cy="30" r="1"/>
      <circle cx="10.5" cy="32" r="1"/>
    </g>
    </SvgIcon>
  );
}

function PPTIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.77-0.77l0,0L40.084,1.321c0,0-0.001-0.001-0.002-0.001l-0.768-0.768C38.965,0.201,38.48,0,37.985,0H8.963
        C7.605,0,6.5,1.104,6.5,2.463V39v16.537C6.5,56.896,7.605,58,8.963,58h40.074c1.358,0,2.463-1.104,2.463-2.463V39V13.515
        C51.5,13.014,51.305,12.543,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M49.5,55.537c0,0.255-0.208,0.463-0.463,0.463H8.963
        C8.708,56,8.5,55.792,8.5,55.537V41h41V55.537z M8.5,39V2.463C8.5,2.208,8.708,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.392V14h11.609c0.135,0,0.265-0.025,0.391-0.058V39H8.5z"/>
      <path d="M18.581,50.363h1.217c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046
        c0.173-0.415,0.26-0.877,0.26-1.388c0-0.483-0.103-0.918-0.308-1.306s-0.474-0.718-0.807-0.991
        c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205H16.94V54h1.641V50.363z M18.581,45.168h1.23
        c0.419,0,0.756,0.066,1.012,0.198c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615
        c0.045,0.214,0.068,0.403,0.068,0.567c0,0.41-0.05,0.754-0.15,1.032c-0.101,0.278-0.232,0.494-0.396,0.649s-0.344,0.267-0.54,0.335
        c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504V45.168z"/>
      <path d="M26.853,50.363h1.217c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046
        c0.173-0.415,0.26-0.877,0.26-1.388c0-0.483-0.103-0.918-0.308-1.306s-0.474-0.718-0.807-0.991
        c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641V50.363z M26.853,45.168h1.23
        c0.419,0,0.756,0.066,1.012,0.198c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615
        c0.045,0.214,0.068,0.403,0.068,0.567c0,0.41-0.05,0.754-0.15,1.032c-0.101,0.278-0.232,0.494-0.396,0.649s-0.344,0.267-0.54,0.335
        c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504V45.168z"/>
      <polygon points="35.944,54 37.599,54 37.599,45.045 40.606,45.045 40.606,43.924 32.937,43.924 32.937,45.045 35.944,45.045 	"/>
      <circle cx="20.5" cy="19" r="1"/>
      <circle cx="20.5" cy="27" r="1"/>
      <circle cx="20.5" cy="23" r="1"/>
      <circle cx="22.5" cy="21" r="1"/>
      <circle cx="24.5" cy="19" r="1"/>
      <circle cx="22.5" cy="25" r="1"/>
      <circle cx="24.5" cy="27" r="1"/>
      <circle cx="24.5" cy="23" r="1"/>
      <circle cx="26.5" cy="21" r="1"/>
      <circle cx="28.5" cy="19" r="1"/>
      <circle cx="26.5" cy="25" r="1"/>
      <circle cx="28.5" cy="27" r="1"/>
      <circle cx="28.5" cy="23" r="1"/>
      <circle cx="30.5" cy="21" r="1"/>
      <circle cx="32.5" cy="19" r="1"/>
      <circle cx="30.5" cy="25" r="1"/>
      <circle cx="32.5" cy="27" r="1"/>
      <circle cx="32.5" cy="23" r="1"/>
      <circle cx="34.5" cy="21" r="1"/>
      <circle cx="36.5" cy="19" r="1"/>
      <circle cx="34.5" cy="25" r="1"/>
      <circle cx="36.5" cy="27" r="1"/>
      <circle cx="36.5" cy="23" r="1"/>
      <path d="M29.5,13c0-0.553-0.448-1-1-1s-1,0.447-1,1v2h-11v16h6.234l-2.091,3.485c-0.284,0.474-0.131,1.088,0.343,1.372
        C21.146,35.954,21.324,36,21.499,36c0.34,0,0.671-0.173,0.858-0.485L25.066,31h6.868l2.709,4.515C34.83,35.827,35.161,36,35.501,36
        c0.175,0,0.353-0.046,0.514-0.143c0.474-0.284,0.627-0.898,0.343-1.372L34.266,31H40.5V15h-11V13z M33.5,17c0,0.552,0.448,1,1,1
        s1-0.448,1-1h2c0,0.552,0.448,1,1,1v2c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1v2c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1v2
        c-0.552,0-1,0.448-1,1h-2c0-0.552-0.448-1-1-1s-1,0.448-1,1h-2c0-0.552-0.448-1-1-1s-1,0.448-1,1h-2c0-0.552-0.448-1-1-1
        s-1,0.448-1,1h-2c0-0.552-0.448-1-1-1s-1,0.448-1,1h-2c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2
        c0.552,0,1-0.448,1-1c0-0.552-0.448-1-1-1v-2c0.552,0,1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1h2c0,0.552,0.448,1,1,1
        s1-0.448,1-1h2c0,0.552,0.448,1,1,1s1-0.448,1-1H33.5z"/>
    </g>
    </SvgIcon>
  );
}

function SVGIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.604,0,6.5,1.105,6.5,2.463V39v16.537C6.5,56.895,7.604,58,8.963,58h40.074
        c1.358,0,2.463-1.105,2.463-2.463V39V13.515C51.5,13.02,51.299,12.535,50.949,12.187z M47.935,12H39.5V3.565L47.935,12z
        M49.5,55.537c0,0.255-0.208,0.463-0.463,0.463H8.963C8.708,56,8.5,55.792,8.5,55.537V41h41V55.537z M8.5,39V2.463
        C8.5,2.208,8.708,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.608c0.135,0,0.265-0.025,0.392-0.058V39H8.5z"/>
      <path d="M19.205,52.756c-0.183,0-0.378-0.019-0.588-0.055s-0.419-0.084-0.629-0.144s-0.412-0.123-0.608-0.191
        s-0.357-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.34,0.253,0.554,0.349s0.439,0.171,0.677,0.226s0.472,0.094,0.704,0.116
        s0.458,0.034,0.677,0.034c0.511,0,0.966-0.077,1.367-0.232s0.738-0.362,1.012-0.622s0.485-0.561,0.636-0.902
        s0.226-0.695,0.226-1.06c0-0.538-0.104-0.978-0.314-1.319s-0.472-0.627-0.786-0.854s-0.654-0.422-1.019-0.581
        s-0.702-0.323-1.012-0.492s-0.569-0.364-0.779-0.588s-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458
        s0.173-0.303,0.301-0.431s0.273-0.234,0.438-0.321s0.337-0.139,0.52-0.157c0.328-0.027,0.597-0.032,0.807-0.014
        s0.378,0.05,0.506,0.096s0.226,0.091,0.294,0.137s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137
        s0.101-0.185,0.164-0.308s0.132-0.255,0.205-0.396s0.137-0.271,0.191-0.39c-0.265-0.173-0.61-0.299-1.039-0.376
        s-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191s-0.692,0.313-0.971,0.554s-0.499,0.535-0.663,0.882
        s-0.246,0.743-0.246,1.189c0,0.492,0.104,0.902,0.314,1.23s0.474,0.613,0.793,0.854s0.661,0.451,1.025,0.629
        s0.704,0.355,1.019,0.533s0.576,0.376,0.786,0.595s0.314,0.483,0.314,0.793c0,0.511-0.148,0.896-0.444,1.155
        C20.187,52.626,19.761,52.756,19.205,52.756z"/>
      <polygon points="28.994,54.055 32.275,43.924 30.416,43.924 27.941,52.619 25.604,43.924 23.73,43.924 26.861,54.055 	"/>
      <path d="M35.358,52.77c0.378,0.429,0.82,0.752,1.326,0.971s1.06,0.328,1.661,0.328c0.301,0,0.604-0.022,0.909-0.068
        s0.602-0.123,0.889-0.232s0.561-0.248,0.82-0.417s0.494-0.385,0.704-0.649v-3.896h-3.336v1.121h1.709v2.488
        c-0.073,0.101-0.187,0.178-0.342,0.232s-0.316,0.098-0.485,0.13s-0.337,0.055-0.506,0.068s-0.303,0.021-0.403,0.021
        c-0.328,0-0.641-0.077-0.937-0.232s-0.561-0.392-0.793-0.711s-0.415-0.729-0.547-1.23s-0.203-1.094-0.212-1.777
        c0.009-0.702,0.082-1.294,0.219-1.777s0.326-0.877,0.567-1.183s0.515-0.521,0.82-0.649s0.627-0.191,0.964-0.191
        c0.301,0,0.593,0.06,0.875,0.178s0.533,0.31,0.752,0.574l1.135-1.012c-0.374-0.364-0.798-0.638-1.271-0.82
        s-0.984-0.273-1.531-0.273c-0.593,0-1.144,0.111-1.654,0.335s-0.955,0.549-1.333,0.978s-0.675,0.964-0.889,1.606
        s-0.321,1.388-0.321,2.235s0.107,1.595,0.321,2.242S34.98,52.341,35.358,52.77z"/>
      <path d="M40.5,19h-6v-4h-10v4h-6v-2h-6v6h6v-2h3.548c-4.566,2.636-7.548,7.588-7.548,13c0,0.552,0.447,1,1,1s1-0.448,1-1
        c0-5.246,3.229-9.999,8-11.995V25h10v-2.995c4.771,1.997,8,6.75,8,11.995c0,0.552,0.447,1,1,1s1-0.448,1-1
        c0-5.412-2.982-10.364-7.548-13H40.5v2h6v-6h-6V19z M16.5,21h-2v-2h2V21z M32.5,23h-6v-3.754V17h6v2.246V23z M42.5,19h2v2h-2V19z"
        />
    </g>
    </SvgIcon>
  );
}

function TXTIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
      <g>
        <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771
          C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
          c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
          c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
          z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
          c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
          C37.525,2.126,37.5,2.256,37.5,2.391V14h11.608c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
        <polygon points="15.197,45.045 18.205,45.045 18.205,54 19.859,54 19.859,45.045 22.867,45.045 22.867,43.924 15.197,43.924 	"/>
        <polygon points="30.291,43.924 28.363,48.025 28.227,48.025 26.449,43.924 24.576,43.924 27.297,49.105 24.74,54 26.641,54 
          28.363,50.199 28.5,50.199 30.1,54 32,54 29.443,49.105 32.164,43.924 	"/>
        <polygon points="33.859,45.045 36.867,45.045 36.867,54 38.521,54 38.521,45.045 41.529,45.045 41.529,43.924 33.859,43.924 	"/>
        <path d="M13.5,14h6c0.553,0,1-0.448,1-1s-0.447-1-1-1h-6c-0.553,0-1,0.448-1,1S12.947,14,13.5,14z"/>
        <path d="M13.5,19h9c0.553,0,1-0.448,1-1s-0.447-1-1-1h-9c-0.553,0-1,0.448-1,1S12.947,19,13.5,19z"/>
        <path d="M26.5,19c0.26,0,0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.26-0.11-0.52-0.29-0.71
          c-0.37-0.37-1.05-0.37-1.41,0c-0.19,0.19-0.3,0.44-0.3,0.71s0.109,0.52,0.29,0.71C25.979,18.89,26.24,19,26.5,19z"/>
        <path d="M30.5,19h8c0.553,0,1-0.448,1-1s-0.447-1-1-1h-8c-0.553,0-1,0.448-1,1S29.947,19,30.5,19z"/>
        <path d="M12.79,32.29c-0.181,0.19-0.29,0.45-0.29,0.71c0,0.26,0.109,0.52,0.29,0.71C12.979,33.89,13.24,34,13.5,34
          s0.52-0.11,0.71-0.29c0.18-0.19,0.29-0.45,0.29-0.71c0-0.26-0.11-0.52-0.29-0.7C13.84,31.92,13.16,31.92,12.79,32.29z"/>
        <path d="M25.5,32h-8c-0.553,0-1,0.448-1,1s0.447,1,1,1h8c0.553,0,1-0.448,1-1S26.053,32,25.5,32z"/>
        <path d="M44.5,17h-2c-0.553,0-1,0.448-1,1s0.447,1,1,1h2c0.553,0,1-0.448,1-1S45.053,17,44.5,17z"/>
        <path d="M13.5,24h22c0.553,0,1-0.448,1-1s-0.447-1-1-1h-22c-0.553,0-1,0.448-1,1S12.947,24,13.5,24z"/>
        <path d="M44.5,22h-6c-0.553,0-1,0.448-1,1s0.447,1,1,1h6c0.553,0,1-0.448,1-1S45.053,22,44.5,22z"/>
        <path d="M13.5,29h4c0.553,0,1-0.448,1-1s-0.447-1-1-1h-4c-0.553,0-1,0.448-1,1S12.947,29,13.5,29z"/>
        <path d="M31.5,27h-10c-0.553,0-1,0.448-1,1s0.447,1,1,1h10c0.553,0,1-0.448,1-1S32.053,27,31.5,27z"/>
        <path d="M44.5,27h-9c-0.553,0-1,0.448-1,1s0.447,1,1,1h9c0.553,0,1-0.448,1-1S45.053,27,44.5,27z"/>
      </g>
    </SvgIcon>
  );
}

function XLSIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.771-0.771L40.084,1.321L39.313,0.55C38.964,0.201,38.48,0,37.985,0H8.963C7.777,0,6.5,0.916,6.5,2.926V39
        v16.537V56c0,0.837,0.842,1.653,1.838,1.91c0.05,0.013,0.098,0.032,0.15,0.042C8.644,57.983,8.803,58,8.963,58h40.074
        c0.16,0,0.319-0.017,0.475-0.048c0.052-0.01,0.1-0.029,0.15-0.042C50.658,57.653,51.5,56.837,51.5,56v-0.463V39V13.978
        C51.5,13.211,51.408,12.645,50.95,12.187z M47.935,12H39.5V3.565L47.935,12z M8.963,56c-0.071,0-0.135-0.026-0.198-0.049
        C8.609,55.877,8.5,55.721,8.5,55.537V41h41v14.537c0,0.184-0.109,0.339-0.265,0.414C49.172,55.974,49.108,56,49.037,56H8.963z
        M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.391V14h11.609c0.135,0,0.264-0.025,0.39-0.058
        c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <polygon points="22.227,43.924 20.299,48.025 20.162,48.025 18.385,43.924 16.512,43.924 19.232,49.105 16.676,54 18.576,54 
        20.299,50.199 20.436,50.199 22.035,54 23.936,54 21.379,49.105 24.1,43.924 	"/>
      <polygon points="28.037,43.924 26.369,43.924 26.369,54 32.672,54 32.672,52.756 28.037,52.756 	"/>
      <path d="M38.94,49.064c-0.314-0.228-0.654-0.422-1.019-0.581c-0.365-0.159-0.702-0.323-1.012-0.492
        c-0.31-0.169-0.57-0.364-0.779-0.588c-0.21-0.224-0.314-0.518-0.314-0.882c0-0.146,0.036-0.299,0.109-0.458
        c0.073-0.159,0.173-0.303,0.301-0.431c0.127-0.128,0.273-0.234,0.438-0.321s0.337-0.139,0.52-0.157
        c0.328-0.027,0.597-0.032,0.807-0.014c0.209,0.019,0.378,0.05,0.506,0.096c0.127,0.046,0.226,0.091,0.294,0.137
        s0.13,0.082,0.185,0.109c0.009-0.009,0.036-0.055,0.082-0.137c0.045-0.082,0.1-0.185,0.164-0.308
        c0.063-0.123,0.132-0.255,0.205-0.396c0.073-0.142,0.137-0.271,0.191-0.39c-0.265-0.173-0.611-0.299-1.039-0.376
        c-0.429-0.077-0.853-0.116-1.271-0.116c-0.41,0-0.8,0.063-1.169,0.191s-0.693,0.313-0.971,0.554
        c-0.278,0.241-0.499,0.535-0.663,0.882s-0.246,0.743-0.246,1.189c0,0.492,0.104,0.902,0.314,1.23
        c0.209,0.328,0.474,0.613,0.793,0.854c0.319,0.241,0.661,0.451,1.025,0.629c0.364,0.178,0.704,0.355,1.019,0.533
        s0.576,0.376,0.786,0.595c0.209,0.219,0.314,0.483,0.314,0.793c0,0.511-0.148,0.896-0.444,1.155c-0.296,0.26-0.723,0.39-1.278,0.39
        c-0.183,0-0.378-0.019-0.588-0.055c-0.21-0.036-0.419-0.084-0.629-0.144c-0.21-0.06-0.413-0.123-0.608-0.191
        c-0.196-0.068-0.358-0.139-0.485-0.212l-0.287,1.176c0.155,0.137,0.339,0.253,0.554,0.349c0.214,0.096,0.439,0.171,0.677,0.226
        c0.237,0.055,0.472,0.094,0.704,0.116s0.458,0.034,0.677,0.034c0.51,0,0.966-0.077,1.367-0.232
        c0.401-0.155,0.738-0.362,1.012-0.622s0.485-0.561,0.636-0.902s0.226-0.695,0.226-1.06c0-0.538-0.105-0.978-0.314-1.319
        C39.517,49.577,39.255,49.292,38.94,49.064z"/>
      <path d="M24.5,13h-12v4v2v2v2v2v2v2v2v4h10h2h21v-4v-2v-2v-2v-2v-2v-4h-21V13z M14.5,19h8v2h-8V19z M14.5,23h8v2h-8V23z M14.5,27h8
        v2h-8V27z M22.5,33h-8v-2h8V33z M43.5,33h-19v-2h19V33z M43.5,29h-19v-2h19V29z M43.5,25h-19v-2h19V25z M43.5,19v2h-19v-2H43.5z
        M14.5,17v-2h8v2H14.5z"/>
    </g>
    </SvgIcon>
  );
}

function XMLIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.949,12.187l-1.361-1.361l-9.504-9.505c-0.001-0.001-0.001-0.001-0.002-0.001l-0.77-0.771
        C38.957,0.195,38.486,0,37.985,0H8.963C7.776,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.837,0.841,1.652,1.836,1.909
        c0.051,0.014,0.1,0.033,0.152,0.043C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048
        c0.052-0.01,0.101-0.029,0.152-0.043C50.659,57.652,51.5,56.837,51.5,56v-0.463V39V13.978C51.5,13.211,51.407,12.644,50.949,12.187
        z M39.5,3.565L47.935,12H39.5V3.565z M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.61,55.877,8.5,55.721,8.5,55.537V41h41v14.537
        c0,0.184-0.11,0.34-0.265,0.414C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595
        C37.525,2.126,37.5,2.256,37.5,2.391V14h11.608c0.135,0,0.265-0.025,0.391-0.058c0,0.015,0.001,0.021,0.001,0.036V39H8.5z"/>
      <polygon points="21.227,43.924 19.299,48.025 19.162,48.025 17.385,43.924 15.512,43.924 18.232,49.105 15.676,54 17.576,54 
        19.299,50.199 19.436,50.199 21.035,54 22.936,54 20.379,49.105 23.1,43.924 	"/>
      <polygon points="30.018,50.814 27.023,43.924 25.355,43.924 25.355,54 27.023,54 27.023,47.068 29.293,52.674 30.742,52.674 
        32.998,47.068 32.998,54 34.666,54 34.666,43.924 32.998,43.924 	"/>
      <polygon points="38.863,43.924 37.195,43.924 37.195,54 43.498,54 43.498,52.756 38.863,52.756 	"/>
      <path d="M23.207,17.293c-0.391-0.391-1.023-0.391-1.414,0l-6,6c-0.391,0.391-0.391,1.023,0,1.414l6,6
        C21.988,30.902,22.244,31,22.5,31s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L17.914,24l5.293-5.293
        C23.598,18.316,23.598,17.684,23.207,17.293z"/>
      <path d="M35.207,17.293c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414L39.086,24l-5.293,5.293
        c-0.391,0.391-0.391,1.023,0,1.414C33.988,30.902,34.244,31,34.5,31s0.512-0.098,0.707-0.293l6-6c0.391-0.391,0.391-1.023,0-1.414
        L35.207,17.293z"/>
      <path d="M31.833,14.057c-0.523-0.185-1.092,0.089-1.276,0.61l-6,17c-0.184,0.521,0.09,1.092,0.61,1.276
        C25.277,32.982,25.39,33,25.5,33c0.412,0,0.798-0.257,0.943-0.667l6-17C32.627,14.812,32.354,14.241,31.833,14.057z"/>
    </g>
    </SvgIcon>
  );
}

function ZIPIcon(props:any) {
  return (
    <SvgIcon viewBox='0 0 58 58' {...props}>
    <g>
      <path d="M50.95,12.187l-0.77-0.77l0,0L40.084,1.321c0,0-0.001-0.001-0.002-0.001l-0.768-0.768C38.965,0.201,38.48,0,37.985,0H8.963
        C7.777,0,6.5,0.916,6.5,2.926V39v16.537V56c0,0.838,0.843,1.654,1.839,1.91c0.05,0.013,0.097,0.032,0.148,0.042
        C8.644,57.983,8.803,58,8.963,58h40.074c0.16,0,0.319-0.017,0.475-0.048c0.051-0.01,0.098-0.029,0.148-0.042
        C50.657,57.654,51.5,56.838,51.5,56v-0.463V39V13.978C51.5,13.213,51.408,12.646,50.95,12.187z M39.5,3.565L47.935,12H39.5V3.565z
        M8.963,56c-0.071,0-0.135-0.025-0.198-0.049C8.609,55.876,8.5,55.72,8.5,55.537V41h41v14.537c0,0.183-0.109,0.339-0.265,0.414
        C49.172,55.975,49.108,56,49.037,56H8.963z M8.5,39V2.926C8.5,2.709,8.533,2,8.963,2h28.595C37.525,2.126,37.5,2.256,37.5,2.392V14
        h11.609c0.135,0,0.264-0.025,0.39-0.058c0,0.014,0.001,0.02,0.001,0.036V39H8.5z"/>
      <polygon points="19.566,45.25 24.652,45.25 24.365,45.469 19.566,52.674 19.566,54 26.266,54 26.266,52.674 21.193,52.674 
        21.467,52.455 26.266,45.25 26.266,43.924 19.566,43.924 	"/>
      <rect x="28.604" y="43.924" width="1.668" height="10.076"/>
      <path d="M38.071,44.744c-0.333-0.273-0.709-0.479-1.128-0.615c-0.419-0.137-0.843-0.205-1.271-0.205h-2.898V54h1.641v-3.637h1.217
        c0.528,0,1.012-0.077,1.449-0.232s0.811-0.374,1.121-0.656c0.31-0.282,0.551-0.631,0.725-1.046c0.173-0.415,0.26-0.877,0.26-1.388
        c0-0.483-0.103-0.918-0.308-1.306S38.404,45.018,38.071,44.744z M37.449,48.073c-0.101,0.278-0.232,0.494-0.396,0.649
        s-0.344,0.267-0.54,0.335c-0.196,0.068-0.395,0.103-0.595,0.103h-1.504v-3.992h1.23c0.419,0,0.756,0.066,1.012,0.198
        c0.255,0.132,0.453,0.296,0.595,0.492c0.141,0.196,0.234,0.401,0.28,0.615c0.045,0.214,0.068,0.403,0.068,0.567
        C37.6,47.451,37.549,47.795,37.449,48.073z"/>
      <path d="M29.5,23h2v-2h-2v-2h2v-2h-2v-2h2v-2h-2v-2h2V9h-2V7h-2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-2v2h2v2h-4v5c0,2.757,2.243,5,5,5
        s5-2.243,5-5v-5h-4V23z M31.5,30c0,1.654-1.346,3-3,3s-3-1.346-3-3v-3h6V30z"/>
      <path d="M27.5,31h2c0.552,0,1-0.447,1-1s-0.448-1-1-1h-2c-0.552,0-1,0.447-1,1S26.948,31,27.5,31z"/>
    </g>
    </SvgIcon>
  );
}

function HomeIcon(props:any) {
  return (
    <SvgIcon {...props}>
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </SvgIcon>
  );
}

export {CSSIcon, CSVIcon, DOCIcon, HTMLIcon, JSIcon, JPGIcon, JSONIcon, MP4Icon, 
        PDFIcon, PNGIcon, PPTIcon, SVGIcon, TXTIcon, XLSIcon, XMLIcon, ZIPIcon };

export default HomeIcon;