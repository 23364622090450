import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import FileUploadIcon from '@material-ui/icons/CloudUpload';

const styles = (theme:Theme):any => ({
  container: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems: 'center'
  },
  preview: {
    width:200,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  }
});

class FImageField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      source: undefined
    };
  }

  onChange = (event:any) => {
    const {onChange} = this.props;
    event.persist();
    let file:any, fr:any;
    const name = event.target.name;
    let files:any = event.target.files || event.dataTransfer.files;
    if(files) {
      file = files[0];
      fr = new FileReader();
      fr.onload = () => {
        this.setState({source:fr.result});
        if(typeof onChange === 'function'){
          if(typeof onChange(name) === 'function'){
            onChange(name)(event,file);
            //console.log('source set, onChange called');
          }
        }
      };
      fr.readAsDataURL(file);
    }
  }

  render() {
    const {id,name,label,divider,options,classes} = this.props;
    const {preview,previewStyle} = options;
    const {source} = this.state;
    var accept = "image/*";
    return (
      <div>
      <div className={classes.container}>
        <input
          accept={accept}
          className={classes.input}
          id={id}
          name={name}
          type="file"
          onChange={this.onChange}
        />
        <label htmlFor={id}>
          <Button variant="contained" component="span" className={classes.button}>
            <FileUploadIcon color={'inherit'}/>
            {label}
          </Button>
        </label>
        {preview&&<img className={source?classes.preview:classes.hidden} style={previewStyle||{}} src={source} alt={""}/>}
      </div>
      {divider && <Divider />}
      </div>
    );
  }
}

FImageField.propTypes = {
  classes: PropTypes.object.isRequired,
  //children: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FImageField);
