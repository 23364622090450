import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import {pink,green,grey} from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {ClosableBar} from '../../components/dialogs';
import {downloadFile} from '../../ajax';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon} from '../../components/icons';
import { clone } from '../../core/utils';
import { formatDate } from '../../components/renderers';
import { create, isValid } from '../../components/form';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    width: '100%',
  },
  titlebar: {
    padding:0,
    backgroundColor: grey['700']
  },
  summary: {
    alignItems: 'space-between'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  topheading: {
    fontWeight:'bold',
  },
  close: {
    flexBasis: '33.33%',
    align:'right'
  },
  details: {
    alignItems: 'center',
    padding: theme.spacing(1),

  },
  column: {
    flexBasis: '33.33%',
  },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink["500"]
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
});

const styles = extendedstyles(extrastyles);

class ReportForm extends Component<any,any> {
  constructor(props:any) {
    super(props);
    this.state = {
      views:[],
      count:0,
      open: true,
      params:{s:props.service,a:'report'},
      datasets: null,
      dataset: null
    };
  }

  formconfig = () => {
    const cbip = {InputLabelProps:{ shrink:true,}};
    const config = {
      service: {
        name: 'dataform',
        title: 'Data Form',
        eform: [
          { id:'rpt',label:'Report Type',type:'fa',req:1,ops:{placeholder:'Select Type',strval:true,...cbip},cp:{t:'lcs',s:'reporttype'}},
          { id:'sdt',label:'Start Date',type:'df',req:1,ops:{ placeholder:'Select Start Date'} },
          { id:'edt',label:'Stop Date',type:'df',req:1,ops:{ placeholder:'Select Stop Date'} },
          { id:'ayi',label:'Agency',type:'fa',req:0,ops:{placeholder:'Select Agency',...cbip},cp:{t:'bcs',s:'agency'}},
          { id:'agi',label:'Agent',type:'fa',req:0,ops:{placeholder:'Select Agent',...cbip},cp:{t:'bcs',s:'agent'}},
          { id:'chi',label:'Channel',type:'fa',req:0,ops:{placeholder:'Select Channel',...cbip},cp:{t:'bcs',s:'channel'}},
          { id:'bri',label:'Bank(RCB)',type:'fa',req:0,ops:{placeholder:'Select RCB',...cbip},cp:{t:'bcs',s:'branch'}},
          //{ id:'fmt',label:'Report Format',type:'cb',req:0,ops:{placeholder:'Select Format',t:'lcs',s:'format',...cbip}}
        ],
      },
      create: true,
      actions: { add: 'Add' },
      panels: { add: 'simple' },
    };
    return config;
  }

  setValid = (data:any) => {
    const config = this.formconfig();
    const valid = isValid(config,data);
    this.props.setValid(valid);
  }

  onChange = (name:any) => (value:any) => {
    const {data} = this.props;
    const newdata = {...data.data,[name]:value};
    this.setValid(newdata);
    this.props.setItem('data','data',newdata);
  }

  // onChange = (name:any) => (value:any) => {
  //   console.log('form.onChange:',name, value);
  //   this.setState((state:any) => ({params: {...state.params,[name]:value}}));
  // }

  makeform = () => {
    const config = this.formconfig();
  
    const { record, classes, extras } = this.props;
    const sconfig = clone(config);
    const sdata = sconfig.service;
    const mode = 'add';
    const formatters = { dob: formatDate };
    const onChange = this.onChange.bind(this);
    const form = create(mode,sdata,record,formatters,onChange,classes,extras||{});
    //const form = this.columnlayout(formitems,classes);
    return form;
  }

  onClick = (mode:any) => async(event:any) => {
    try{
      //var btn = event.target;
      console.log('report mode:',mode);
      const {service,action,data,record} = this.props;
      const {params:sparams} = this.state;
      console.log('print action:',action);
      const name = service||'agent';
      const params = {...sparams,s:'settlement',a:'report'};
      await downloadFile(mode,params,name,data,record);
    }
    catch(error){
      console.log('report.handle.failed',error);
      const errormessage = `Error: ${error}`;
      this.props.showNotification(errormessage);
    }
  } 

  onExpChange = (event:any,expanded:boolean) => {
    if (!expanded) this.setState({open:false})
  }

  onClose = (event:any) => {
    this.setState({open:false})
  }

  render() {
    const {title,classes } = this.props;
    const form = this.makeform();
    const {open} = this.state;
    return (
      <Dialog open={open}>
        <DialogTitle id="window-dialog-title" className={classes.titlebar} disableTypography={true}>
          <ClosableBar onClose={this.onClose}>{title}</ClosableBar>
        </DialogTitle>
        <DialogContent className={classes.dialogcontent}>

      <div className={classes.root}>
        
        <Accordion defaultExpanded onChange={this.onExpChange}>

          <AccordionDetails className={classes.details}>
            <div className={classes.root}>
              {/* <div> */}
                {form}
              {/* </div> */}
              
            </div>
          </AccordionDetails>
          <Divider />
        <AccordionActions>
        {/* <div className={classes.iconsroot}> */}
          <Button variant="contained" className={classes.customicon} onClick={this.onClick('pdf')}>
            <PDFIcon titleAccess="PDF Download"/>
          </Button>
          <Button variant="contained" className={classes.customicon} onClick={this.onClick('csv')}>
            <CSVIcon titleAccess="CSV Download"/>
          </Button>
          <Button variant="contained" className={classes.customicon} onClick={this.onClick('json')}>
            <JSONIcon titleAccess="JSON Download" />
          </Button>
          <Button variant="contained" className={classes.customicon} onClick={this.onClick('zip')}>
            <ZIPIcon titleAccess="ZIP Download" />
          </Button>
      {/* </div> */}
        </AccordionActions>
        </Accordion>
      </div>
      </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReportForm);
