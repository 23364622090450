import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
//import { GridList, GridListTile, GridListTileBar } from '@material-ui/core/GridList';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
//import StarBorderIcon from '@material-ui/icons/StarBorder';
import DownloadIcon from '@material-ui/icons/CloudDownload';
//import tileData from './tileData';
import {saveAs} from 'file-saver';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    background: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  videobox: {
    objectFit: "contain",
    width: 240,
    height: 200,
  },
  video:{
    position: 'absolute', 
    right: 0, 
    bottom: 0,
    minWidth: '100%', 
    minHeight: '100%',
    width: 'auto', 
    height: 'auto', 
    zIndex: "-100",
    backgroundSize: 'cover',
    overflow: 'hidden'
  }
});

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     type: image,
 *     img: image,
 *     vid: video,
 *     title: 'Image',
 *     author: 'author',
 *     featured: true,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */

 const styles = extendedstyles(extrastyles);


class ResourceView extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      count:0
    };
  }

  // componentWillReceiveProps(nextProps) {
  //   if (this.props.page !== nextProps.page) {
  //       this.setState({page: nextProps.page});
  //   }
  // }

  handleClick = (name:any) => (event:any) => {
    //var btn = event.target;
    //var {result,tileData} = this.props;
    const value = event.target.value;
    var {result} = this.props;
    console.log('result',result,value,name);
    var blob = new Blob(value);
    saveAs(blob);
    // var d = new Download();
    // var s = this.state.dataset;
    // if(name=='pdf'){
    //   d.downloadPDF(data,record,user);
    //   return;
    // }
    // var fns = {txt:d.txtDemo,json:d.jsonData, pdf:d.pdfData};
    // if(s == null) {
    //   d.normalize(data,record,user,(ss)=>{
    //     this.setState({dataset:ss});
    //     fns[name]('selified_verification',ss,data,record,user);
    //   });
    // }
    // else{
    //   fns[name]('selified_verification',s,data,record,user);
    // }
  } 

  render() {
    //const { name, tileData, classes, theme } = this.props;
    const { tileData, classes} = this.props;

    return (
      <div className={classes.root}>
        <GridList cellHeight={200} spacing={1} className={classes.gridList}>
          {tileData.map((tile:any) => (
            <GridListTile key={tile.resid} cols={tile.featured ? 2 : 1} rows={tile.featured ? 2 : 1}>
              {/* {tile.type=='image'?<img src={tile.img} alt={tile.title} />
                                 :<video src={tile.vid} alt={tile.title} muted playsInline controls></video>} */}
              {tile.type==='image'?<img id={tile.resid} src={tile.src} alt=""/>
                                 :<div className={classes.videobox}>
                                    <video id={tile.resid} src={tile.src} className={classes.videobox} muted playsInline controls></video>
                                  </div>}
              <GridListTileBar
                title={tile.title}
                titlePosition="top"
                actionIcon={
                  // <IconButton className={classes.icon} onClick={this.handleClick(tile.resid,tile)}>
                  <IconButton className={classes.icon} onClick={this.handleClick(tile.resid)}>
                    <DownloadIcon />
                  </IconButton>
                }
                actionPosition="right"
                className={classes.titleBar}
              />
            </GridListTile>
          ))}
        </GridList>
      </div>
    );
  }
}

ResourceView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(ResourceView);