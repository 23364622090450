import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Carousel from "react-material-ui-carousel"
import LoginBanner from './LoginBanner';
import { bizpost } from '../../ajax';

const styles = (theme:Theme) => {
  const cfg:any = {
  master: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'vertical',
    paddingBottom: 50,
  },
  bannerbox:{
    // height: '100%',
    width: 400,
    backgroundImage: 'url(lib/img/bg02.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
  },
  
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   //width: 200,
  // },
  // hide:{
  //   display:'none',
  // },
};
return cfg;
}

// const items = [
//   {
//     name: "slide01",
//     title: "Account Services",
//     subtitle: "Customer Account Management",
//     message: "This service enables a customer to have access to various account management services",
//     color: "#64ACC8",
//     photo: 'lib/img/slide01.jpg'
//   },
//   {
//     name: "slide02",
//     title: "Momo Push",
//     subtitle: "Wallet to Bank Transactions",
//     message: "This service ennables a customer to push money from mobile wallet to bank account",
//     color: "#7D85B1",
//     photo: 'lib/img/slide02.jpg'
//   },
//   {
//     name: "slide03",
//     title: "Momo Pull",
//     subtitle: "Bank to Wallet Transactions",
//     message: "This service ennables a customer to pull money from bank account to mobile wallet",
//     color: "#CE7E78",
//     photo: 'lib/img/slide03.jpg'
//   },
//   {
//     name: "slide04",
//     title: "Automatic Settlements",
//     subtitle: "Automatic Settlement for all RCBs and MMCs",
//     message: "This service enables RCBs to review daily transnactions and process settlement at EOD",
//     color: "#C9A27E",
//     photo: 'lib/img/slide04.jpg'
//   }
// ];

class LoginCarousel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      value: this.props.value,
      mode: 'banner',
      items: [],
      autoPlay:true,
      animation: 'slide',
      indicators: true,
      timeout: 10000
    };
  }

  componentDidMount(){
    this.refresh();
  }

  refresh = async() => {
    try{
      const params = {s:'uicontent',a:'doc',dcc:'BANNER',dvc:'ALL',lgc:'ALL'};
      this.setState({loading:true});
      const response = await bizpost(params);
      console.log('uicontent response ',response);
      if (response.success){
        const {sd:items} = response;
        this.setState({mode:'slider',items});
      }
      else if(response.failure){
        this.setState({mode:'banner'});
      }      
    }
    catch(err){
      this.setState({mode:'banner'});
    }
    finally{
      this.setState({loading:false});
    }
  }

  // handleChange = () => {
  //   // let value = event.target.value;
  //   // this.props.onChange(value);
  //   // this.setState({value});
  // };

  render() {
    const {classes } = this.props;
    const {mode,items} = this.state;
    const {autoPlay,animation,indicators,timeout,navButtonsAlwaysVisible,navButtonsAlwaysInvisible} = this.state;

    return (
      <div className={classes.master}>
        {(mode==='slider')?
        <Carousel
            className={classes.carousel}
            autoPlay={autoPlay}
            animation={animation}
            indicators={indicators}
            interval={timeout}
            navButtonsAlwaysVisible={navButtonsAlwaysVisible}
            navButtonsAlwaysInvisible={navButtonsAlwaysInvisible}>
          {
            items.map((item:any, index:number) => {
                return <LoginBanner item={item} key={index} />
            })
          }
        </Carousel>
        :
        <div className={classes.bannerbox}>
            {/* <!-- Left Panel --> */}

        </div>
      }
      </div>
    );
  }
}

LoginCarousel.propTypes = {
  classes: PropTypes.object.isRequired,
  //id: PropTypes.string.isRequired,
  //label: PropTypes.string.isRequired,
};

export default withStyles(styles)(LoginCarousel);
