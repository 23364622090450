import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {pink,green} from '@material-ui/core/colors';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import { PDFIcon, CSVIcon, JSONIcon, ZIPIcon} from '../../components/icons';
import {Download} from '../../ajax';

const styles = (theme:Theme):any => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  topheading: {
    fontWeight:'bold',
  },
  details: {
    alignItems: 'center',
  },
  iconsroot: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  customicon: {
    margin: theme.spacing(2),
    color: pink["500"]
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: green[200],
    },
  },
  
});

class TransactionReport extends Component<any,any> {
  constructor(props:any) {
    super(props);
    this.state = {
      views:[],
      count:0,
      datasets: null,
      dataset: null
    };
  }

  handleClick = (name:any) => () => {
    //var btn = event.target;
    var {data,record} = this.props;
    var d = new Download();
    d.downloadFile(name,data,record);
  } 

  render() {
    const {classes } = this.props;
    return (
      <div className={classes.root}>
        
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classnames(classes.heading,classes.topheading)}>
              Downloads
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <div className={classes.iconsroot}>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('pdf')}>
                    <PDFIcon titleAccess="PDF Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('csv')}>
                    <CSVIcon titleAccess="CSV Download"/>
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('json')}>
                    <JSONIcon titleAccess="JSON Download" />
                  </Button>
                  <Button variant="contained" className={classes.customicon} onClick={this.handleClick('zip')}>
                    <ZIPIcon titleAccess="ZIP Download" />
                  </Button>
              </div>
            </div>
            <div></div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

export default withStyles(styles)(TransactionReport);
