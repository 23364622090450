// react
import React from 'react';

import FAutoComplete from './FAutoComplete';
import FCheckGroup from './FCheckGroup';
import FChoice from './FChoice';
import FComboBox from './FComboBox';
import FDateField from './FDateField';
import FDisplayField from './FDisplayField';
import FFileField from './FFileField';
import FFileUpload from './FileUpload';
import FGeoLocation from './FGeoLocation';
import FImageField from './FImageField';
import FJsonField from './FJsonField';
import FMaskField from './FMaskField';
import FMaskListField from './FMaskListField';
import FNumberField from './FNumberField';
import FPasswordField from './FPasswordField';
import FPhotoField from './FPhotoField';
import FRadioGroup from './FRadioGroup';
import FSlider from './FSlider';
import FTextArea from './FTextArea';
import FTextField from './FTextField';

/**
 * -------------------------------------------------------------------
 * Local Form Fields used in action forms
 * -------------------------------------------------------------------
 */
const ff:any = {
  cb:<FComboBox/>,
  cg:<FCheckGroup/>,
  ch:<FChoice/>,
  df:<FDateField/>,
  fa:<FAutoComplete/>,
  ff:<FFileField/>,
  fu:<FFileUpload/>,
  gl:<FGeoLocation/>,
  if:<FImageField/>,
  jf:<FJsonField/>,
  mf:<FMaskField/>,
  ml:<FMaskListField/>,
  nf:<FNumberField/>,
  pf:<FPasswordField/>,
  ph:<FPhotoField />,
  rf:<FDisplayField/>,
  rg:<FRadioGroup/>,
  sl:<FSlider/>,
  ta:<FTextArea/>,
  tf:<FTextField/>
};
//export {cg:ff.cg,ch:ff.ch,cb:ff.cb,df:ff.df,rf:ff.rf,fu:ff.fu,if:ff.if,nf:ff.nf,rg:ff.rg,sl:ff.sl,tf:ff.tf,ta:ff.ta};
export default ff;