//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
// import DialogContentText from '@material-ui/core/DialogContentText';
//import ProgressDialog from '../dialogs/ProgressDialog';
import {LoadingDialog} from '../dialogs';
//local helpers
import { apipost } from '../../ajax';
//import ResultView from '../view/ResultView';
import views from '../../app/views';
//import styles from './servicestyles';
import styles from '../../app/styles'

/**
 * -------------------------------------------------------------------
 * Service form
 * -------------------------------------------------------------------
 */
class ResultForm extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading:false,
      record:null,
      vdata:null,
      result:null,
      rvopen:false,
    };
  }

  componentDidMount(){
    this.refresh();
  }

  refresh = async() => {
    try{
      const {code,record,server} = this.props;
      const refid = record.rfi;
      const trackid = record.tri;
      const url = server || '/api/result/dash';
      this.startLoading();
      let baseparams = {refid:refid,trackid:trackid}
      const response = await apipost(url,code,baseparams);
      // console.log('login response ',response);
      let rvopen = true;
      this.setState({record:record,vdata:response.sd,result:response.result,rvopen:rvopen});
    }
    catch(error){
      console.log(error);
      //this.showNotification(error.message||"request failed",error.type||'warn',error.title||'Loading Error');
    }
    finally{
      this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  customError = () => {
    //
  }

  render = () => {
    const {page,showNotification,code,classes} = this.props;
    const {loading,record,vdata,result,rvopen} = this.state;
    
    
    return (
      <div className={classes.container}>
        { loading &&
          <LoadingDialog status={loading} start={this.startLoading} stop={this.stopLoading} />
        }
        {/* { rvopen &&
        <ResultView record={record} data={vdata} result={result} user={record.urf}/>
        } */}
        {React.cloneElement(
          (page in views) ? views[page] : this.customError(), //TODO: default to a simple local message component
          {record,result,data:vdata,user:record.urf,showNotification,code}
        )}
      </div>
    );
  }
}

ResultForm.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add','edit','delete','view','close','manage','wizard']), //PropTypes.string.isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object
};

//ResultForm = withStyles(styles, { withTheme: true })(ResultForm);

export default withStyles(styles, { withTheme: true })(ResultForm);
