import React, {Component} from 'react';
// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Radio';
import GridHeader from './GridHeader';
//import GridToolbar from './GridToolbar';
//import GridPagingActions from './GridPagingActions';
import {gridSort} from './GridSorter';
import { Switch } from '@material-ui/core';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: { 
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const styles = extendedstyles(extrastyles);

class GridMaskView extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      order: this.props.order || 'asc',
      orderBy: this.props.orderBy, //'calories', 
      selected: [],
      colids: props.cols.map((row:any)=>row.id),
      cols: props.cols,//this.props.cols,
      data: props.data,//this.props.data,
      page: props.page || 0,//this.props.page || 0,
      rowsPerPage: props.rowsPerPage||10,//this.props.rowsPerPage||5,
      selectedRecord: null,
      selectedIndex: null,
      actions: [],
      recs:props.recs||{},
      list:[],
    };
  }

  onColumnSort = (event:any, property:any) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  onPageChange = (event:any, page:any) => {
    this.setState({ page });
  };

  onRowsPerPageChange = (event:any) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const { mask,cols,recordset,formatters,filterColumns,onSwitch,classes } = this.props;
    const {recField,keyField} = this.props;
    const { selected, order, orderBy, rowsPerPage, page,colids } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, recordset.length - page * rowsPerPage);
    const fm = formatters||{};
    //console.log("data",recordset,"props",this.props, 'recs:',recs);
    const fcols = filterColumns(cols,colids);
    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} stickyHeader aria-labelledby="tableTitle">
            <GridHeader
              cols={fcols}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(ev:any)=>null}
              onRequestSort={(ev:any)=>null}
              rowCount={recordset.length}
              actionCols={false}
            />
            <TableBody>
              {//recordset //
              gridSort(recordset, order, orderBy)
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n:any) => {
                  const bit = +n[keyField]; 
                  const isChecked = ((mask & (1 << bit)) !== 0); 
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      // aria-checked={isSelected}
                      tabIndex={-1}
                      key={n[recField]}
                    >
                      <TableCell key="rsm" padding="checkbox">
                        <Checkbox checked={false} disabled={true}/>
                      </TableCell>
                      {fcols.map((col:any) =>
                      <TableCell key={col.id} padding="none">
                        {fm[col.id]?fm[col.id](n[col.id],n):n[col.id]}
                       </TableCell>
                      )}
                      {/* <TableCell key="rsw" padding="checkbox">
                        <Switch 
                          checked={isChecked}
                          onChange={onSwitch(n)}
                          name={n[keyField]}
                        />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={fcols.length+2} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={fcols.length+2}
                  count={recordset.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={this.onPageChange}
                  onRowsPerPageChange={this.onRowsPerPageChange}
                  rowsPerPageOptions={[5,10]}
                  //ActionsComponent={GridPagingActions}
                  //ActionsComponent={'span'}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

GridMaskView.propTypes = {
  filterColumns:PropTypes.func,
  classes: PropTypes.object.isRequired,
  rowsPerPage: PropTypes.number,
  recordset: PropTypes.array.isRequired,
  cols: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
};

export default withStyles(styles)(GridMaskView);
