import React, {Component, Fragment} from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';

//import Dialog, {DialogTitle,DialogContent, DialogContentText, DialogActions} from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Typography } from '@material-ui/core';

const styles = (theme:Theme):any => ({
  button: {
    margin: theme.spacing(1)
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  progress: {
    margin: theme.spacing(4),
    backgroundColor: 'transparent'
  },
  dialogcontent: {
    backgroundColor: 'white'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  alertdiv: {
    width: 400,
    height: 400,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  }
});

class Message extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  render() {
    const {open,title,message,type,onClose,classes} = this.props;
    const dialogClasses = {
      paper: classes.dialogcontent
    }
    return (
      <Fragment>
        <Backdrop open={open} className={classes.backdrop} onClick={onClose}>
        <Dialog open={open}  className={classes.dialogcontent} classes={dialogClasses}>
          <DialogContent className={classes.dialogcontent}>
          
          <Alert severity={type}>
            <AlertTitle>{title}</AlertTitle>
              <Typography variant={'body1'}>{message}</Typography>
          </Alert>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="contained" color="secondary" onClick={onClose}><CloseIcon/> </Button>
          </DialogActions> */}
        </Dialog>
        </Backdrop>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Message);
