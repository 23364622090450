//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import ReportBase from './ReportBase';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({
});
const styles = extendedstyles(extrastyles);


class ReportService extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  configuration = () => {
    const config = {
      service:{
        name:'request',
        title:'Verification Requests',
        num: false,
        grid: [['dcd','Date'],['urf','User'],['svc','Service'],['tri','Tracking ID'],['rfi','Reference'],['src','Source'],['stn','Status'],['stp','Stamp']],
        menu: {mnu:[{t:'Name',v:'nam'},{t:'Code',v:'shc'}],def:'nam'},
        eform:[{id:'dcd',label:'Request Date',type:'rf',req:0,ops:{}},
              {id:'urf',label:'User Contact',type:'rf',req:0,ops:{}},
              {id:'svc',label:'Service',type:'rf',req:0,ops:{}},
              {id:'tri',label:'Tracking ID',type:'rf',req:0,ops:{}},
              {id:'rfi',label:'Reference',type:'rf',req:0,ops:{}},
              {id:'src',label:'Source Reference',type:'rf',req:0,ops:{}},
              {id:'stn',label:'Status',type:'rf',req:0,ops:{}},
              // {id:'log',label:'Logo',type:'if',req:0,ops:{}},
            ],
      },
      create:true
    };
    return config;
  }

  render() {
    const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    // console.log('review.classes',classes);
    const title = "Activity Report";
    return (
      <ReportBase
        title={title}
        service={service}
        code={code}
        configuration={this.configuration}
        setPage={setPage}
        setHome={setHome}
        showNotification={showNotification}
        classes={classes}
        theme={theme}
      />
    );
  }
}

ReportService.propTypes = {
  service: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  setPage: PropTypes.func,
  setHome: PropTypes.func,
  showNotification: PropTypes.func
};
 
export default withStyles(styles, { withTheme: true })(ReportService);
