import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import LocationOn from '@material-ui/icons/LocationOn';
import LocationOff from '@material-ui/icons/LocationOff';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
//import Button from '@material-ui/core/Button';
//import Input from '@material-ui/core/Input';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import FileUploadIcon from '@material-ui/icons/CloudUpload';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Button, InputAdornment } from '@material-ui/core';
import 'leaflet/dist/leaflet.css';
//import { Autocomplete } from '@material-ui/lab';
//import MapPreview from '../map/MapPreview';

//absolute left:27
const styles = (theme:Theme):any => ({
  container: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems: 'center'
  },
  MuiCardContentRoot:{
    margin: theme.spacing(1),
    height:'85%'
  },
  preview: {
    width:300,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  cardroot:{
    width: '100%',
    height: '100%',
    margin: 0
  },
  popper:{
    marginTop: theme.spacing(12),
    marginLeft: theme.spacing(20),
    width: '80%',
    height: '80%',
    zIndex: 1300
  },

  mapbox:{
    width: '100%',
    height: '100%',
    // margin: theme.spacing(1)
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  }
});

class FGeoLocation extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any = {position:[7.9465,-1.0232],zoom:6,info:'Drag to prefferred location'};
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      id: undefined,
      anchor: undefined,
      source: undefined,
      blank: false,
      value: this.props.value||'',
      valid: true
    };
  }

  onMouseDown = (event:any) => {
    event.preventDefault();
  };

  handleChange = (event:any) => {
    const {onChange} = this.props;
    let value = event.target.value;
    if(value===undefined) {
      const {lat,lng} = event.target._latlng;
      value = `(${lat}, ${lng})`;
      this.setState({valid:false});
    }
    if(typeof onChange === 'function') onChange(value);
    this.setState({value});
  };

  handlePopper = (event:any) => {
    // console.log('target.parent',event.currentTarget.parent);
    this.setState({id:'map-popper',open:!this.state.open,anchor:event.currentTarget})
  }

  render() {
    const {name,label,required,divider,classes} = this.props;
    const {position,zoom,info} = this.props;
    const {id,anchor,open,geocoord,valid} = this.state;
    const blank = !(typeof geocoord === 'object'  && geocoord.x && geocoord.y);
    const height = 250; 
    // console.log('mapprops:',position,zoom,info);
    // console.log('popperprops:',open,anchor,geocoord);
    return (
      <div className={classes.root}>
        <TextField
          label={label}
          name={name}
          fullWidth={true}
          margin='normal'
          helperText={'Enter your location here eg: (x,y)'}
          onChange={this.handleChange}
          value={this.state.value}
          required={required}
          className={classes.textField}          
          InputProps={{
            endAdornment: 
              <InputAdornment position={"end"}>
                <IconButton
                  onClick={this.handlePopper}
                  onMouseDown={this.onMouseDown}
                >
                  {blank ? <LocationOff /> : <LocationOn />}
                </IconButton>
              </InputAdornment>
          }}
        />
        <Popper id={id} open={open} anchorEl={null} transition className={classes.popper}
          placement="top"
          disablePortal={true}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: false,
            },
          }}
        >
          {/* {({ TransitionProps }) => (
            <Fade {...TransitionProps}  timeout={350}> */}
              <Card className={classes.cardroot} variant="outlined">
                <CardContent className={classes.MuiCardContentRoot}>
                  <MapContainer center={position} zoom={zoom||13} scrollWheelZoom={false} className={classes.mapbox} >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
                    />
                    <Marker position={position} draggable={true} eventHandlers={{
                      dragend: this.handleChange
                    }}>
                      <Popup>{info}</Popup>
                    </Marker>
                  </MapContainer>  
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={this.handlePopper}>Close</Button>
                  <Button size="small" disabled={valid}  onClick={this.handlePopper}>Select</Button>
                </CardActions>
              </Card>
              
            {/* </Fade>
          )} */}
        </Popper>

        {divider && <Divider />}
      </div>
    );
  }
}

FGeoLocation.propTypes = {
  classes: PropTypes.object.isRequired,
  //children: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FGeoLocation);
