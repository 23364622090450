import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
// import Button from '@material-ui/core/Button';
// import FileUploadIcon from '@material-ui/icons/CloudUpload';

const styles = (theme:Theme):any => ({
  container: {
    display: 'flex',
    justifyContent:'flex-start',
    alignItems: 'center'
  },
  preview: {
    width:200,
    height: 40,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1)
  },
  input: {
    display: 'none',
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'flex'
  }
});

class FPhotoField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      source: undefined
    };
  }

  render() {
    const { base, label, value, previewStyle, divider, classes } = this.props;
    //const {id,name,label,preview,previewStyle,divider,classes} = this.props;
    //const {source} = this.state;
    //var accept = "image/*";
    const basePath = base || '/api/photo/';
    const srcPath = basePath+value;
    return (
      <div>
      <div className={classes.container}>
        {/* <input
          accept={accept}
          className={classes.input}
          id={id}
          name={name}
          type="file"
          onChange={this.onChange}
        /> */}
        <span>
            {label}
        </span>
        <img 
          className={classes.preview} 
          style={previewStyle||{}} 
          src={srcPath} 
          alt={""}
        />
      </div>
      {divider && <Divider />}
      </div>
    );
  }
}

FPhotoField.propTypes = {
  classes: PropTypes.object.isRequired,
  //children: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default withStyles(styles)(FPhotoField);