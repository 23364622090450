import React,{Component} from "react";
import PropTypes from "prop-types";
import { Theme, withStyles } from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Divider from "@material-ui/core/Divider";

const styles = {
  root: {
    color: grey[600],
    "&$checked": {
      color: grey[500]
    }
  },
  checked: {}
};

const oarray = (obj:any) => Object.entries(obj).map(rec=>({key:rec[0],val:rec[1]}));
const isvalid = (obj:any,v:any) => Object.values(obj).includes(v);
const first = (arr:any[]) => arr[0].val;
// usage:
// const steps = [a:"1hr", "3hrs", "1day", "3days"];
// <FRadioGroup id="exp" label="Expire" values={steps} value="1hr" onChange={this.handleChange}/>,

class FRadioGroup extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    let data = oarray(props.values);
    let cur = isvalid(props.values,props.value)?props.value:first(data); //Math.max(props.values.indexOf(props.value),0)
    this.state = {
      data: data,
      cur: cur, //"1hr"
    };
  }

  // state = {
  //   cur: oarray(this.props.value)[0].val || this.props.values[0]
  // };

  // handleChange = (event:any) => {
  //   // const { id, onChange } = this.props;
  //   this.setState({ cur: event.target.va lue });
  //   // if (onChange) onChange(id)(event.target.value);
  // };

  handleChange = (name:any) => (event:any) => {
    this.setState({
      cur: event.target.value,
    });
  };

  render() {
    const { id, label, value, values, onChange, divider, classes } = this.props;
    const { cur } = this.state;
    if(value) console.log('');

    return (
      <div>
      <FormControl variant="outlined" fullWidth={true}> {/** id={id} **/}
        <FormLabel>
          {label}
        </FormLabel>
        <RadioGroup
          aria-label={label}
          id={id}
          name={id}
          value={cur}
          onChange={onChange || this.handleChange}
          row
        >
          {values.map((item:any, index:number) => (
            <FormControlLabel
              key={index}
              value={item.key}
              control={
                <Radio classes={{root:classes.root}}/>
              }
              label={item.val}
              labelPlacement="bottom"
            />
          ))}
        </RadioGroup>
      </FormControl>
      {divider && <Divider />}
      </div>
    );
  }
}

FRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FRadioGroup);