import React,{ Component } from "react";
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//material-ui core components
import { Typography } from "@material-ui/core";
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme:Theme):any => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

class DialogClosableTitle extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  render(){
    const { children, classes, onClose } = this.props;
    return(
      <DialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
    )
  }
}

DialogClosableTitle.propTypes = {
  onClose: PropTypes.func,
}

//DialogClosableTitle = withStyles(styles, { withTheme: true })(DialogClosableTitle);
export default withStyles(styles, { withTheme: true })(DialogClosableTitle);