import React, {Component, Fragment} from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
//import Dialog, {DialogTitle,DialogContent, DialogContentText, DialogActions} from '@material-ui/core/Dialog';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import GridList from '@material-ui/core/GridList';
// import GridListTile from '@material-ui/core/GridListTile';
// import GridListTileBar from '@material-ui/core/GridListTileBar';
// import ZoomIcon from '@material-ui/icons/ZoomIn';
// import CloseIcon from '@material-ui/icons/Close';
import { DialogTitle } from '@material-ui/core';
import ClosableBar from './ClosableBar';

const styles = (theme:Theme):any => ({
  button: {
    margin: theme.spacing(1)
  },
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'block'
  },
  dialogcontent: {
    backgroundColor: 'white'
  },
  root: {
    //display: 'flex',
    //flexWrap: 'wrap',
    //justifyContent: 'space-around',
    // overflow: 'hidden',
    flex: '1 1 auto',
    background: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    //height: 350,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    //overflow: 'scroll'
  },
  gridListTile: {
    // flex: '1 1 auto',
  },
  gridTileImage: {
    //flex: '0 1 auto',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
});

class PreviewDialog extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      loading: false,
      open: false,
    };
  }

  render() {
    const {classes,open,photo,source,onClose} = this.props;
    const title = 'Preview image';
    const dialogClasses = {
      paper: classes.dialogcontent
    }
    return (
      <Fragment>
        <Dialog open={open} classes={dialogClasses}>
          <DialogTitle><ClosableBar onClose={onClose}>{title}</ClosableBar></DialogTitle>
          <DialogContent className={classes.dialogcontent}>
              <div className={classes.root}>
                {/* <GridList cellHeight={350} spacing={1} className={classes.gridList}> */}
                  {/* <GridListTile cols={1} rows={1} className={classes.gridListTile}> */}
                    <img id={photo.resid} src={photo.src} alt="" className={classes.gridTileImage} />
                    {/* <GridListTileBar
                      title={photo.title}
                      titlePosition="top"
                      actionIcon={
                        <IconButton className={classes.icon} onclick={onClose}>
                          <CloseIcon />
                        </IconButton>
                      }
                      actionPosition="right"
                      className={classes.titleBar}
                    />
                  </GridListTile> */}
                {/* </GridList> */}
              </div>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="contained" color="secondary" onClick={this.stopLoading}><CloseIcon/>Close </Button>
          </DialogActions> */}
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(PreviewDialog);
