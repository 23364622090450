import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import RotateIcon from '@material-ui/icons/Rotate90DegreesCcw';
import Cropper from 'cropperjs';
import Rotation from '../../lib/ai/rotation';

let ro:any = false;

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '100%',
    height: 200,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  gridListTile: {
    width: '100%',
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  icon: {
    color: 'white',
  },
  imagewrapper: {
    objectFit: "contain",
    width: '100%',
    height: 240,
  },
  previewImage: {
    transformStyle: 'preserve-3D',
    width: '100%',
    height: 250,
  },
  
});

class CardPreview extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      fwopen: false
    };
  }

  componentDidMount() {
    this.initCropper();
    //this.initRotation();
  }

  initCropper = () => {
    const image:any = document.getElementById(this.props.id);
    const cropper = new Cropper(image, {
      aspectRatio: 16 / 9,
      crop(event) {
        console.log(event.detail.x);
        console.log(event.detail.y);
        console.log(event.detail.width);
        console.log(event.detail.height);
        console.log(event.detail.rotate);
        console.log(event.detail.scaleX);
        console.log(event.detail.scaleY);
      },
    });
  }

  initRotation = () => {
    let pp = document.getElementById(this.props.id);
    ro = new Rotation(pp,this.props.rotatefn);
  }

  handleRotate = (ev:any) => {
    if(!ro) this.initRotation();
    ro.rotate();
  }

  //function CountryList(props) 
  render(){
    const { id, tile, classes, theme } = this.props;

    return (
      <div className={classes.root}>
        <GridList cellHeight={200} spacing={1} cols={1} className={classes.gridList}>
          <GridListTile className={classes.gridListTile}>
            <div className={classes.imagewrapper}>
              <img className={classes.previewImage} id={id} src={tile.img} alt={tile.title} />
            </div>
            <GridListTileBar
              title={tile.title}
              titlePosition="top"
              actionIcon={
                <IconButton className={classes.icon} onClick={this.handleRotate}>
                  <RotateIcon />
                </IconButton>
              }
              actionPosition="right"
              className={classes.titleBar}
            />
          </GridListTile>
        </GridList>
      </div>
    );
  }
}

CardPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(CardPreview);
