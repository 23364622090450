import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme:Theme):any => ({
  grid: {
    minHeight: '60vh',
  },
});
    
class CenterGrid extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  render() {
    const {xs=12,sm=12,children,classes} = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.grid}
      >
        <Grid item xs={xs} sm={sm}>
          {children}
        </Grid>   
      </Grid> 
    );
  }
}

CenterGrid.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default withStyles(styles, { withTheme: true })(CenterGrid);
