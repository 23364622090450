import React,{ Component } from "react";
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//material-ui core components
import { Typography } from "@material-ui/core";
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  gridCellText: {
    padding: 4,
  },
});

const styles = extendedstyles(extrastyles);

class GridCellText extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  render(){
    const {text,classes} = this.props;
    return(
      <Typography className={classes.gridCellText}>{text}</Typography>
    )
  }
}

GridCellText.propTypes = {
  style: PropTypes.object.isRequired,
  text: PropTypes.node
}

//GridCellText = withStyles(styles, { withTheme: true })(GridCellText);
export default withStyles(styles, { withTheme: true })(GridCellText);