import React, {Component} from 'react';

import { Theme, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar'; 
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Help from '@material-ui/icons/Help';
import {CenterGrid} from '../../components/layout';
import { extendedstyles } from '../styles';

const extrastyles = (theme:Theme) => ({
  loginbox: {
    //width: 450
    margin:'50px auto',
    display: 'inline-block'
  },
  loginform: {
    padding: theme.spacing(1),
  },
  titlebar: {
    color: 'white',
    textAlign: 'center',
    flex:1
  },
  formControl: {
    margin: theme.spacing(1),
  },
  input: {
    margin: theme.spacing(1),
  },
  actionbox: {
    display: 'flex',
    justifyContent: 'center'
  },
  action: {
    margin: 'auto 5px',
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  menu: {
    //width: 200,
  },
  hide: {
    display:'node'
  },
  show: {
    display:'inline-block'
  },
  logo: {
    cursor: 'pointer',
    fontSize: 22,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    backgroundImage: 'url(lib/img/logo.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: '0 50%',
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
    height: 20,
    width:25,
    [theme.breakpoints.down('sm')]:{
      display:'none',
    },
  }
  
});

const styles = extendedstyles(extrastyles);

class About extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);

    this.state = {
      loading: false,
      count: 0,
      open: false,
      sbopen: false,
      sbmesg: '',
      username: '',
      password:'',
      showPassword: false,
      context: props.context,
      serviceid: 1,
      remember: false
    };
  }

  onHelp = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'help';
    this.props.handleSetContent(null,page).then( () => {
      //this.props.closeDrawer();
    });
  };

  onSupport = (event:any) => {
    this.setState({ settingsanchor: null, settingsopen:false });
    const page = 'support';
    this.props.handleSetContent(null,page).then( () => {
      //this.props.closeDrawer();
    });
  };

  render() {
    const {service,code,showNotification,setPage,setHome,classes, theme} = this.props;
    //const vertical = 'top', horizontal = 'center';
    const logosrc = '/api/logo/icon.png';

    return (
      <CenterGrid>
        <Paper elevation={3} square={false}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.logo} style={{backgroundImage: `url(${logosrc})`}}/>
            <Typography variant="h3" className={classes.titlebar}>
              About...
            </Typography>
          </Toolbar>
        </AppBar>
          <div>
          <form id="aboutform" className={classes.loginform}>
            
            <div>
              <Typography>
                ARc Mobile AMS 1.0
              </Typography>
            </div>
            <div>
              <Typography>
                ARC Mobile Audit Management System
              </Typography>  
            </div>
            <div>
              <Typography>
                Audit Analytics, Monitoring, Reporting, Corebanking Integration
              </Typography> 
            </div>
            <br/>
            <div>
              <Typography>
                &copy; Commercial Bank of Ethiopia
              </Typography> 
            </div>
            <br/>
            <div className={classes.actionbox}>
              support@is.cbe.com.et
            </div>
          </form>
          </div>
        </Paper>
        <div>
          <Button className={classes.button} onClick={this.onSupport}><PersonAdd className={classes.leftIcon}/> Contact us</Button>
          <Button className={classes.button} onClick={this.onHelp}><Help className={classes.leftIcon}/>More info?</Button>
        </div>
        <div>
          
        </div>
      </CenterGrid>
    );
  }

  
}

export default withStyles(styles, { withTheme: true })(About);
