//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//local helpers
import {getWizard} from '../../app/wizards';
import Wizard from '../wizard';
import styles from '../../app/styles';

/**
 * -------------------------------------------------------------------
 * Service form
 * -------------------------------------------------------------------
 */
class WizardPanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      wizards:[],
    };
  }

  addExtraInfo = (record:any) => {
    if(typeof record !== 'object') return record;
    if(typeof record.exi !== 'object') return record;
    if(!record.exi && typeof record.dtl === 'string'){
      try{
        record.exi = JSON.parse(record.dtl);
      }
      catch(e){
        console.log(e);
      }
      return record;
    }
    else return record; 
  }

  render = () => {
    const {code,service,mode,record,handleClose, selected, reset, cvalue, cuparams,...others} = this.props;
    const extras = {selected, reset, cvalue, cuparams };
    const {cards,list,meta} = getWizard(service,mode);
    
    return (
      <Wizard 
        service={service} 
        name={service}
        mode={mode} 
        record={this.addExtraInfo(record)}
        code={code} 
        cards={cards} 
        list={list} 
        meta={meta}
        wizClose={handleClose}
        extras={extras}
        {...others}
      />
    );
  }
}

WizardPanel.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add','process','wizard']), //PropTypes.string.isRequired, 
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object
};

//WizardPanel = withStyles(styles, { withTheme: true })(WizardPanel);

export default withStyles(styles, { withTheme: true })(WizardPanel);
