import React, { Component } from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import {cyan, grey, pink, purple, orange, yellow, blue} from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
//import Assessment from '@material-ui/icons/Assessment';
//import Face from '@material-ui/icons/Face';
//import ThumbUp from '@material-ui/icons/ThumbUp';
//import ShoppingCart from '@material-ui/icons/ShoppingCart';
import {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
} from '@material-ui/icons'; 

import PageBase from '../site/PageBase';
import Filter from './Filter';
import InfoBox from './Info';
import Daily from './Daily';
import Recent from './Recent';
import Monthly from './Monthly';
import Feature from './Feature';
import Distribution from './Distribution';
import {bizpost} from '../../ajax';
import { ProgressDialog } from '../dialogs';

const styles = (theme:Theme):any => ({
  root: {
    flexGrow: 1,
  },
  navigation: {
    fontSize: 15,
    fontWeight: 300,
    color: grey['600'],
    paddingBottom: 15,
    display: 'block'
  },
  title: {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 20,
    color:'white'
  },
  paper: {
    padding: 30
  },
  clear: {
    clear: 'both'
  }
});

const icons:any = {
  Assessment,Face,ThumbUp,
  ShoppingCart,AddShoppingCart,RemoveShoppingCart,
  Wifi,WifiOff,Work,Today,DateRange,AccessTime,PieChart,
};

class Dashboard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      dd: this.props.userData.dd,
      ping: null,
      timer: 60000, //300000,
      count:0,
      loading: false,
      isFiltered: false 
    };
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }
  componentDidMount(){
    const {timer} = this.state;
      var ping = setInterval(async()=>{
        try{
          const baseparams = {s:'security',a:'dashdata'};
          const response = await bizpost(baseparams);
          //console.log('dd resp',response);
          this.setState({dd:response.dd});
        }
        catch(error){
          console.log('dd error',error);
          this.sessionError(error);
        } 
      },timer);
      this.setState({ping:ping});
  }

  componentWillUnmount(){
    if(this.state.ping) clearInterval(this.state.ping);
  }

  sessionError(error:any){
    const {type,name,title,data} = error||{};
    if(type==='error' && name==='fetcherror' && title === 'Unauthorised'){
      const {failure,code} = data || {};
      if(failure === true && code === 401){
        //session expired. reload page.
        //this.props.doRestart('Session Expired. reloading...');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }
    }
  }

  handleFilter = async (data:any) => {
    this.startLoading();
    const {start,end} = data;
    const baseparams = {s:'security',a:'dashfilter', sta:start, sto:end};
    const response = await bizpost(baseparams);
    this.stopLoading();
    this.setState({dd:response.dd});
    
  }

  getChannel(data:any){
    const {meta,channel:chandata} = data;
    const chans = meta.filter((val:any,idx:number,arr:any[])=> val.group==='channel');
    const rtn = chans.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = chandata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getMode(data:any){
    const {meta,mode:modedata} = data;
    const modes = meta.filter((val:any,idx:number,arr:any[])=> val.group==='mode');
    const rtn = modes.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = modedata[rec.code];
      acc.push(rec);
      return acc;
    },[]);
    return rtn;
  }

  getInfo(data:any){
    const {meta,source} = data;
    const infos = meta.filter((val:any,idx:number,arr:any[])=> val.group==='info');
    const info = infos.reduce((acc:any[],rec:any,idx:number,arr:any) => {
      rec.value = source[rec.name];
      acc.push(rec);
      return acc;
    },[]);
    return info;
  }

  getDaily(data:any){

    return Daily;
  }

  getMonthly(data:any){

    return  Monthly;
  }

  getRecent(data:any){

    return Recent;
  }

  getFeature(data:any){

    return null;
  }

  render() {
    const {userData,theme,classes} = this.props;
    const {bd} = userData;
    const {dd, loading} = this.state;
    //const dashdata = dd || {monthly:[],source:[{}]};
    const dashdata = dd || {monthly:[],source:{}};
    //console.log('dashdata: ',dashdata);
    // const {total,mtnpush,mtnpull,vodapush,vodapull,other} = dashdata.source[0];
    // const infos = [
    //   {name:'total',title:'TOTAL',value:total,color:'#D81B60',palette:'pink',icon:ShoppingCart},
    //   {name:'mtnpull',title:'MTNPULL',value:mtnpull,color:'#00ACC1',palette:'cyan',icon:ShoppingCart},
    //   {name:'mtnpush',title:'MTNPUSH',value:mtnpush,color:'#8E24AA',palette:'purple',icon:ShoppingCart},
    //   {name:'vodapull',title:'VODAPULL',value:vodapull,color:'#FB8C00',palette:'orange',icon:ShoppingCart},
    //   {name:'vodapush',title:'VODAPUSH',value:vodapush,color:'#1E88E5',palette:'blue',icon:ShoppingCart},
    //   {name:'other',title:'OTHERS',value:other,color:'#757575',palette:'grey',icon:ShoppingCart},
    // ];
    //console.log('dd ',dd);
    const infos = this.getInfo(dashdata);
    const modes = this.getMode(dashdata);
    const chans = this.getChannel(dashdata);
    //console.log('infos: ',infos);
    const {meta,daily,monthly,recent,mode:feature} = dashdata;

    //console.log('render.daily: ',daily);
    //console.log('render.monthly: ',monthly);

    return (
      <PageBase navigation="AMS / Summary Dashboard" subid={(bd||{}).eni}>
      <div className={classes.root}>
        {/* <h3 className={classes.navigation}>{this.props.userData.bd.enm}</h3> */}

        <Grid container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12}>
            <Filter data={[]} handleFilter={this.handleFilter} icons/>
          </Grid>
          {infos.map((info:any,idx:number) => (
            (info.title === 'OTHERS' && !info.value)?'':
          <Grid key={`infobox_${idx}`} item xs={12} sm={6} md={3} lg={2} xl={1}>
            <InfoBox Icon={icons[info.icon]}
                    color={info.color}
                    title={info.title}
                    value={info.value||0}/>
          </Grid>
          ))
          }
        </Grid>

        <Grid  container spacing={theme.breakpoints.up('sm')?3:1}>
          <Grid item xs={12} sm={6}>
            <Recent data={recent||[]} title='Recent Events' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Daily data={daily||[]} title='Daily Events' handleFilter={this.handleFilter} icons/>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Monthly data={monthly||[]} title='Monthly Events' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={modes||[]} title='Events By Mode' color='orange' icons/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Distribution data={chans||[]} title='Events By Channel' color='blue' icons/>
          </Grid>
        </Grid>

        <ProgressDialog  
          open={loading} 
          loading={loading}
          startLoading={this.startLoading}
          stopLoading={this.stopLoading}
        />
      </div>
      </PageBase>
    );
  }
};

export default withStyles(styles, { withTheme: true })(Dashboard);
