import React,{ Component } from "react";
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';
//material-ui core components
import { Typography } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
// import DragIcon from '@material-ui/icons/DragHandle';
// import DragOffIcon from '@material-ui/icons/ExploreOff';
// import DragOnIcon from '@material-ui/icons/Explore';

const styles = (theme:Theme):any => ({
  flex: {
    flex: 1,
  },
  // closable: {
  //   //color: 'inherit'
  // },
  // hide: {
  //   display:'none',
  // },
  // show: {
  //   display:'block',
  // }
});

class ClosableBar extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;

  render(){
    const { children, classes, onClose } = this.props;
    //const { children, classes, onClose, drag, onDrag } = this.props;
    //const dragmode = (typeof onDrag === 'function');
    return(
      <Toolbar>
        <Typography variant="h6" color="inherit" className={classes.flex}>
          {children}
        </Typography>
        {/* {dragmode&&
        <IconButton color="inherit" onClick={onDrag} aria-label="Drag">
          {(drag?<DragOnIcon />:<DragOffIcon />)}
        </IconButton>
        } */}
        <IconButton color="inherit" onClick={onClose} aria-label="Close">  {/*className={classes.closable} className={(dragmode&&drag)?classes.hide:classes.show}*/}
          <CloseIcon />
        </IconButton>
      </Toolbar>
    )
  }
}

ClosableBar.propTypes = {
  onClose: PropTypes.func,
}

//ClosableBar = withStyles(styles, { withTheme: true })(ClosableBar);
export default withStyles(styles, { withTheme: true })(ClosableBar);



