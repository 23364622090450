//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';

import {LoadingDialog} from '../dialogs';
import styles from '../../app/styles';
import {createItemList} from '../form';

/**
 * -------------------------------------------------------------------
 * Service Panel
 * -------------------------------------------------------------------
 */
class DoublePanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode: this.props.mode,
      init: false,
      loading: false,
      fopen: false,
    };
  }

  handleChange = (name:any) => (event:any) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }

  render = () => {
    const {mode,sdata,record,formatters,onChange,classes, selected, reset, cvalue, cuparams,...others} = this.props;
    const {loading} = this.state;
    const extras = {selected, reset, cvalue, cuparams }
    //const formitems = generateItemList(mode,sdata,record,formatters,onChange);
    const formitems = createItemList(mode, sdata, record, formatters, onChange,extras);
    return (
      <div className={classes.container}>
        <form id="requestform" className={classes.reqform}>
          { loading &&
            <LoadingDialog status={loading} start={this.startLoading} stop={this.stopLoading} />
          }
          {formitems.map((item:any) => (
            <div key={item.id}>{item.dom}</div>
          ))}
        </form>
      </div>
    );
  }
};

DoublePanel.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add','edit','delete','view','close','manage','wizard']).isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object,
  layout: PropTypes.object,
};

//DoublePanel = withStyles(styles, { withTheme: true })(DoublePanel);

export default withStyles(styles, { withTheme: true })(DoublePanel);
