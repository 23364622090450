import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Button from '@material-ui/core/Button';
// import Done from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
// import sender from '../core/sender';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../styles';

const extrastyles = (theme:Theme) => ({
  card: {
    maxWidth: 345,
    minHeight: 600,
    margin: 'auto',
    paddingTop: 20,
  },
  media: {
    height: 28,
    marginLeft: '20px auto'
  },
  video: {
    width: '90%',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    color: '#fffff',
  },
  done: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    color: '#fffff',
    width: '36px',
    height: '36px',
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
  success: {
    color: '#28dab6'
  }
});
const styles = extendedstyles(extrastyles);

class FinalCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  ui:any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("Final");
    // this.ui = this.props.getUI(this.props.uic,'NONE','DONE','ALL');
    this.state = {
      next: false,
      fwopen: false
    };
  }
  
  // componentDidMount(){
  //   var userdata = this.props;
  //   let that = this;
  //   setTimeout( () => {
  //     sender.logout(userdata,this.handleProgress,(err,rtn) => {
  //       if(err){
  //         this.props.deb.log(err);
  //       }
  //       else{
  //         //TODO: A reset function here to kill everything;
  //         //that.props.setReset();
  //         this.handleDone();
  //       }
  //     });
  //   }, 10000);
  // }

  handleProgress = (ev:any,perc:any) => {
    //DO NOTHING
  }

  handleNext = (ev:any)  => {
    this.props.setNext(this.props.name);
  }

  handleCancel = (ev:any)  => {
    //this.props.switchFn('intro');
  }

  handleDone = (ev:any)  => {
    //TODO: get from server or <code> props.code
    //var doneurls = {'0LopMXlSLLA0gug1DsABTc':'https://eSignatureGuarantee.com','default':'https://www.selified.com'};
    //window.location.href = doneurls[this.props.code]||doneurls.default; //'';
    window.location.href = this.ui.URL;
  }

  render = () => {
    const message = "Process completed successfull. Thank you. Bye!";
    const { classes, theme, ...extraProps  } = this.props;  //fmtUI, logo, title, switchFn,
    // const {logo, title, switchFn, message, classes, theme  } = this.props;
    // const { TITLE, INSTRUCTION,DONE,CANCEL} = this.ui;
    return (
      <div>
        <BaseCard
          cardid={'FINAL'}
          cardname={'Final'}
          title={'Finalize'}
          instruction={'Complete wizard'}
          step={4}
          buttons={['DONE','GUIDE']}
          {...extraProps}
        >
          <Typography>
            {message}
          </Typography>
        </BaseCard>
      </div>
    );
  }
}

FinalCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FinalCard); 
