import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = (theme:Theme):any => ({
  grid: {
    minHeight: '100vh',
  },
});
    
class InfoGrid extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  render() {
    const {children,classes} = this.props;
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={classes.grid}
      >
        <Grid item xs={12} sm={6}>
          {children}
        </Grid>   
      </Grid> 
    );
  }
}

InfoGrid.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default withStyles(styles, { withTheme: true })(InfoGrid);
