import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles, withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import {bizpost} from '../../ajax';
//import combos from '../../core/combo';
import {combos} from '../../core';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  main: {
    width: '100%'
  },
  menu: {
    width: 200,
  },
  textField: {
    //marginLeft: theme.spacing(1),
    //marginRight: theme.spacing(1),
    width: '100%',
  },
  group: {
    margin: theme.spacing(1, 0),
    display: 'flex',
  },
});

class FComboBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      cbs: null,
      next: true,
      sOptions:this.props.sOptions || [],
      count:0,
      value:this.props.value||''
    };
  }

  componentDidMount(){
    const {config} = this.props;
    console.log('cb.config',config);
    const cbs = combos(config.t,config.s)||{};
    this.setState({cbs:cbs});
    if(config.t==='lcs') this.locrefresh(cbs);
    else this.remrefresh(cbs);
  }

  locrefresh = (cbs:any) => {
    let err = !cbs.sd;
    if(err){
      //TODO: show message here
      console.log("lcs data not available");
      this.setState({sOptions:[],count:0});
      //this.setState({count:0});
    }
    else{
      const sd = cbs.sd.reduce( (acc:any[],rd:any) => { acc.push({rid:rd[0],nam:rd[1]});return acc;}, []);
      const rc = sd.length;
      //console.log(sd);
      // const value = rc>0?this.format(sd[0].rid,sd[0]):this.state.value;
      // this.setState({sOptions:sd,count:rc,value});
      this.setState({sOptions:sd,count:rc});
      //this.setState({count:rc});
    }
      
  }

  remrefresh = async(cbs:any) =>{
    if(!cbs.sv) return;
    try{
      const {service,config,filters} = this.props;
      let s = cbs.sv||config.s||service,
          a = config.a||'combo',
          params = {s:s,a:a,...filters};
      console.log('combo.params:',params);
      const response = await bizpost(params);
      console.log('combo.response:',response);
      const {sd,rc} = response;
      const sc = (sd||[]).length;
      if(sc>0){
        this.setState({sOptions:sd,count:+rc});
      }
    }
    catch(error){
      console.log('combo.error:',error);
      this.setState({sOptions:[],count:0});
      if(this.props.onError) this.props.onError(error);
    }
    finally{
      //this.stopLoading();
    }
  }

  format = (itemid:any,item:any) => {
    const {vFormat} = this.props;
    let vftype = typeof vFormat;
    if(vftype === 'function') return vFormat(itemid,item);
    else if(vftype === 'string') {
      if(vFormat === 'status') return ['disabled','active'][itemid];
      if(vFormat === 'gender') return ['F','M'][itemid];
      if(vFormat === 'truthy') return ['N','Y'][itemid];
      if(vFormat === 'label') return item.nam.replace(/\s/g,'_');
    }
    return itemid;
  }

  handleChange = (event:any) => { 
    const { onChange } = this.props;
    let value = event.target.value;
    this.setState({value});
    if (typeof onChange === 'function') onChange(value);
  };

  render() {
    //const {service,action,id,label,required,autoFocus,options,onChange,value,divider,classes } = this.props;
    const {id,label,required,strval,options,divider,classes } = this.props;
    const { value, sOptions } = this.state;
    return (
      <div className={classes.root}>
        <TextField
            label={label}
            id={id}
            select
            //autoWidth={true}
            required={required}
            margin='dense'
            fullWidth={true}
            className={classes.textField}
            value={value}
            onChange={this.handleChange}
            placeholder="Please select a record"
            InputLabelProps={{shrink: true }}
            SelectProps={{
              //native: true,
              MenuProps: {
                className: classes.menu,
              },
            }}
            // InputProps = {{id:id,name:id}}
            variant={'outlined'}
            {...options}
          >
          {sOptions.map((option:any) => 
            <option key={option.rid} value={this.format(strval?option.rid:+option.rid,option)}> {option.nam} </option>
          )}
        </TextField>
        {divider && <Divider />}
      </div>
    );
  }
}

FComboBox.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme:true})(FComboBox);
