import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  hide:{
    display:'none',
  },
});

class FTextField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      show: false,
      next: true,
      value: props.value||''
    };
  }

  // handleChange = (event:any) => {
  //   let value = event.target.value;
  //   this.props.onChange(value);
  //   this.setState({value});
  // };

  handleChange = (event:any) => {
    const {onChange} = this.props;
    let value = event.target.value;
    this.setState({value});
    //if(typeof onChange === 'function') console.log('final onChange is a function',value);
    //if(typeof onChange === 'function')
    if(typeof onChange === 'function') onChange(value);
  };

  handleMouseDown = (event:any) => {
    event.preventDefault();
  };

  toggleShow = () => {
    this.setState({ show: !this.state.show });
  };

  render() {
    const {id,label,required,options,divider,onKeyPress,classes } = this.props;
    const {InputProps:inprops={},...ooptions} = options||{};
    const {show,value} = this.state;
    //console.log('passwordfield.ooptions is ',ooptions);
    //console.log('passwordfield.inprops is ',inprops);

    return (
      // <div className={classes.root}>
      <React.Fragment>
        <TextField
            label={label}
            id={id}
            name={id}
            type={show ? 'text' : 'password'}
            required={required}
            margin='dense'
            fullWidth={true}
            className={classes.textField}
            onChange={this.handleChange}
            onKeyPress={onKeyPress}
            value={value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.toggleShow}
                    onMouseDown={this.handleMouseDown}
                  >
                    {show ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              ...(inprops)
            }}
            {...ooptions}
          />
        {divider && <Divider />}
      </React.Fragment>
      // </div>
    );
  }
}

FTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FTextField);