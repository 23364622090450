import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import VeriPane from './VeriPane';
import VeriForm from './VeriForm';
import {BaseCard} from '../../../components/wizard';
import { extendedstyles } from '../../styles';

const extrastyles = (theme:Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    // overflow: 'scroll',
    justifyContent: 'space-between'
  },
  pane: {
    flex: '1 1 auto'
  },
  form: {
    // width: '30.0%',
    flex: '0 0 400px',
    height: 320,
    overflow: 'scroll'
  },
  // card: {
  //   maxWidth: 345,
  //   minHeight: 600,
  //   margin: 'auto',
  //   paddingTop: 20,
  // },
  // media: {
  //   height: 28,
  //   marginLeft: '20px auto'
  // },
  // video: {
  //   width: '90%',
  // },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#28dab6',
    border: '1px solid #28dab6',
    borderRadius: '1',
    color: '#fffff',
    paddingLeft: 20,
    paddingRight: 20,
  },
  second: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    border: '1px solid #cccccc',
    borderRadius: '1',
    color: '#cccccc',
    paddingLeft: 20,
    paddingRight: 20,
  },
  cancel: {
    margin: theme.spacing(1),
    backgroundColor: '#ffffff',
    borderRadius: '1',
    color: '#cccccc',
  },
  divider: {
    margin: '5px 20px 5px 20px',
  },
});
const styles = extendedstyles(extrastyles);

class VeriCard extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.props.deb.log("VeriCard");
    // this.ui = this.props.getUI(this.props.uic,'ID','TYPE','ALL');
    this.state = {
      next: true,
      idtype: null,
      idfrom: null,
      fwopen: false
    };
  }

  handleCCChange = (event:any,value:any) => {
    this.setState({idfrom: value });
    this.props.setParam('ifrom',value);
  };

  handleChange = (event:any,value:any) => {
    // this.setState({idfrom: value });
    // this.props.setParam('ifrom',value);
    console.log('change',value);
  };

  handleNext = (ev:any)  => {
    if(this.state.idtype == null) return false;
    this.props.setNext(this.props.name);
  }

  handlePrev = (ev:any)  => {
    this.props.setPrev(this.props.name);
  }

  render = () => {
    const { classes, theme, ...extraProps  } = this.props;  //fmtUI, logo, title, switchFn,
    const {record} = this.props;
    // const { uic, logo, title, switchFn, classes, theme  } = this.props;
    // const { TITLE, INSTRUCTION,NEXT,CANCEL} = this.ui;
    // const profile = uic.ALL.ALL.PROFILE.ALL;
    //const basepath = 'https://devio.questcollect.com/qclab';
    const basepath = 'api/store/qclab';
    //const userpath = `${basepath}/`;
    const photo = {resid:'photo',src:`${basepath}/1111-111111-1111-1111-photo.jpg`,title:'Photo'};
    const idcard = {resid:'card',src:`${basepath}/1111-111111-1111-1111-card.jpg`,title:'ID Card'};
    return (
      <BaseCard
        cardid={'VERI'}
        cardname={'Veri'}
        title={'Verification'}
        instruction={'Confirm identity match and populate data'}
        step={1}
        buttons={['START','CANCEL','PREV','NEXT','GUIDE']}
        {...extraProps}
      >
        <div className={classes.root}>
          <VeriPane record={record} photo={photo} idcard={idcard} className={classes.pane}/>
          <VeriForm record={record} className={classes.form} />
        </div>
      </BaseCard>
    );
  }
}

VeriCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VeriCard);