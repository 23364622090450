import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/icons/List';
import Done from '@material-ui/icons/CheckCircleOutline';
import Cancel from '@material-ui/icons/HighlightOff';

import { formatDate,formatStatus } from '../renderers';
import { GridMaskView, GridViewer } from '../grid';
import {bizpost} from '../../ajax';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //width: 200,
  },
  button: {
    marginBottom: 30,
    marginRight: -30,
  },
  title: {
    backgroundColor: theme.palette.grey['300'],
  },
  valueChip: {
    position: 'absolute',
    right: theme.spacing(12),
  },
  cancelButton: {
    position: 'absolute',
    right: theme.spacing(6),
    top: theme.spacing(1),
    //color: theme.palette.grey[500],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    //color: theme.palette.grey[500],
  },
  hide:{
    display:'none',
  },
});

const styles = extendedstyles(extrastyles);

class FMaskField extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      next: true,
      data: props.data||[],
      open: false,
      svalue: null,
      value: props.value,
    };
  }

  componentDidMount(){
    const {options,record={}} = this.props;
    const {params,conf} = options;
    const {keyField='rid'} = conf||{};
    const recid = +(record[keyField]);
    if(recid>=0){
      params[keyField] = recid;
      this.refresh(params);
    }
  }

  refresh = async(params:any) => {
    try{
      this.startLoading();
      const response = await bizpost(params);
      const data = response.sd||[];
      this.setState({data,recordCount:+response.rc});
    }
    catch(error){
      this.setState({data:[],recordCount:0});
      const err:any = typeof error === 'object'?error : {};
      if(this.props.showNotification)
        this.props.showNotification(err.message||"request failed",err.type||'warn',err.title||'Loading Error'); 
    }
    finally{
      if (this.state.loading) this.stopLoading();
    }
  }

  startLoading = () => {
    this.setState({loading:true});
  }

  stopLoading = () => {
    this.setState({loading:false});
  }
  
  setValid = (data:any) => {
    const valid = (!!data.ati);
    //console.log('valid:',valid);
    this.props.setValid(valid);
  }

  

  onOpen = (event?:any) => {
    const  {data} = this.state;
    if(!data){
      const {showNotification} = this.props;
      if(typeof showNotification === 'function') return showNotification('Data not found','error','Display Error');
    }
    this.setState({open:true});
  }

  onClose = (event?:any) => {
    this.setState({open:false});
  }

  onCancel = (event?:any) => {
    this.onClose();
  }

  onUpdate = (event?:any) => {
    this.onClose();
  }

  
  onMouseDown = (event:any) => {
    event.preventDefault();
  };

  defaultGridCfg = () => {
    const {recordCount} = this.state;
    const pages = (recordCount>5)?10:5;
    const [page,rowsPerPage,order,orderBy] = [0,pages,'asc','rid'];
    const [recField,keyField,accessField] = ['rid','aky','acl'];
    const grid = [['nam','Name'],['dsc','Description']];
    const rownum = true; //numbered list
    var init = rownum?[{id:'id',numeric:false,disablePadding:true,label:'No'}]:[];
    const cols = grid.reduce((ac,va)=>{
      ac.push({id:va[0],numeric:false,disablePadding:true,label:va[1]});
      return ac;
    },init);
    const formatters = {};  //{sts:formatStatus,dcd:formatDate,stp:formatDate};
    const filter = (cols:any) => cols;

    return {recField,keyField,accessField,cols,rowsPerPage,page,order,orderBy,formatters,filter};
  }

  render() {
    const {id,label,divider,classes,...others } = this.props;
    const {value,mask,data,open} = this.state;

    const {recField,keyField,accessField} = this.state;
    const {options} = this.props;console.log('fmf.options',JSON.stringify(options));
    const {gridcfg} = options;

    const defconf = this.defaultGridCfg();
    const optconf = (typeof gridcfg === 'object')?gridcfg:{recField,keyField,accessField};
    const gridconf = {...defconf,...optconf}
    const {cols,rowsPerPage,page,order,orderBy,formatters,filter} = gridconf;

    return (
      <div className={classes.root}>
        <Dialog onClose={this.onClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title" className={classes.title}>
            {label}
            <Chip variant="outlined" color="primary" size="small" className={classes.valueChip}/>
            <IconButton color={"secondary"} aria-label="Cancel" className={classes.cancelButton} onClick={this.onCancel}><Cancel/></IconButton>
            <IconButton color={"primary"} aria-label="Close" className={classes.closeButton} onClick={this.onUpdate}><Done/></IconButton>
          </DialogTitle>
          <GridMaskView //TODO: Add service and code for remote activity?
            recField={recField}
            keyField={keyField}
            mask={mask}
            cols={cols}
            recordset={data}
            rowsPerPage={rowsPerPage}
            page={page}
            order={order}
            orderBy={orderBy}
            formatters={formatters}
            filterColumns={filter}
            {...others}
          />
        </Dialog>
        <TextField
            label={label}
            id={id}
            name={id}
            type={'text'}
            margin='dense'
            fullWidth={true}
            className={classes.textField}
            value={value}
            inputProps={
              { readOnly: true, }
            }
            // onChange={this.onFieldChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="open access control dialog"
                    onClick={this.onOpen}
                    onMouseDown={this.onMouseDown}
                    edge="end"
                    className={classes.button}
                  >
                    <List color="secondary"/>
                  </IconButton>
              </InputAdornment>)
            }}
          />
        {divider && <Divider />}
      </div>
    );
  }
}

FMaskField.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles)(FMaskField);
