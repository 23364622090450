import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import {grey} from '@material-ui/core/colors';


const styles = (theme:Theme):any => ({
  content: {
    padding: '5px 10px',
    marginLeft: 60, //90
    height: 55, //80
  },
  number: {
    display: 'block',
    marginTop: 10,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    color: grey['800'],
    textAlign: 'center',
  },
  text: {
    display: 'block',
    fontSize: 20,
    fontWeight: theme.typography.fontWeightLight,
    color: grey['800'],
    textAlign: 'center',
  },
  iconSpan: {
    float: 'left',
    height: 60, //90
    width: 60, //90
    textAlign: 'center',
    //backgroundColor: theme.color
  },
  icon: {
    height: 48,
    width: 48,
    marginTop: 5,  //20
    fill: 'white',
    maxWidth: '100%'
  }
});

class InfoBox extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      test: null
    };
  }


  render() {
    const {color, title, value, Icon, classes} = this.props;
    const extras = {
      span:{backgroundColor: color}
    }
    return (
      <Paper>
        {/* <span className={classNames(classes.iconSpan,extras.span)} style={extras.span}> */}
        <span className={classes.iconSpan} style={extras.span}>
          <Icon htmlColor={'white'}
                className={classes.icon}
          />
        </span>

        <div className={classes.content}>
          <span className={classes.text}>{title}</span>
          <span className={classes.number}>{value}</span>
        </div>
      </Paper>
      );
  }
}

InfoBox.propTypes = {
  Icon: PropTypes.any, // eslint-disable-line
  color: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any
};

// export default InfoBox;
export default withStyles(styles, { withTheme: true })(InfoBox);
