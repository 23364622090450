import React,{Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
//import ContentDelete from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ViewIcon from '@material-ui/icons/ViewCompact';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { extendedstyles } from '../../app/styles';

const extrastyles = (theme:Theme):any => ({
  root: {
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 100%',
  },
  actionlist: {
    display: 'flex'
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
});

const styles = extendedstyles(extrastyles);

//TODO: get title from props

class GridMSMToolbar extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode:0
    };
  }
  
  render() {
    const { title, numSelected, selectedRecord, handleSelect, handleFilter, classes } = this.props;
    
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {/* <div className={classes.title}>
          {numSelected > 0 ? (
            <Typography color="inherit" variant="subheading">
              {numSelected} selected
            </Typography>
          ) : (
            <Typography variant="title" id="tableTitle">
              {title}
            </Typography>
          )}
        </div> */}
        <div className={classes.title}>
          <Typography variant="h1" id="tableTitle">
            {title}
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.title}>
        {numSelected > 0 && (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        )}
        </div>
        {(numSelected > 0) ? (
          (numSelected > 1)? (
            <div className={classes.actions}>
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={handleSelect({item:selectedRecord,action:'delete'})}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ):(
          <span className={classes.actionlist}>
            <div className={classes.actions}>
              <Tooltip title="Edit">
                <IconButton aria-label="Edit" onClick={handleSelect({item:selectedRecord,action:'edit'})}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              </div>
              <div className={classes.actions}>
              <Tooltip title="View">
                <IconButton aria-label="View" onClick={handleSelect({item:selectedRecord,action:'view'})}>
                  <ViewIcon />
                </IconButton>
              </Tooltip>
              </div>
              <div className={classes.actions}>
              <Tooltip title="Delete">
                <IconButton aria-label="Delete" onClick={handleSelect({item:selectedRecord,action:'delete'})}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </span>
          ) 
          ): (
          <div className={classes.actions}>
            <Tooltip title="Filter list">
              <IconButton aria-label="Filter list" onClick={handleFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
          )}
        
      </Toolbar>
    );
  }
}

GridMSMToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

export default withStyles(styles)(GridMSMToolbar);
