//react
import React, { Component } from 'react';
import PropTypes from 'prop-types';
//material-ui design helpers
import { Theme, withStyles } from '@material-ui/core/styles';

import { LoadingDialog } from '../dialogs';
import styles from '../../app/styles';
import { createItemList } from '../form';

/**
 * -------------------------------------------------------------------
 * Service Panel
 * -------------------------------------------------------------------
 */
class SimplePanel extends Component<any,any> {
  static propTypes: any;
  static defaultProps: any;
  constructor(props:any) {
    super(props);
    this.state = {
      mode: this.props.mode,
      init: false,
      loading: false,
      fopen: false,
      childs: {}
    };
  }

  startLoading = () => {
    this.setState({ loading: true });
  }

  stopLoading = () => {
    this.setState({ loading: false });
  }

  // setChildren = (items) => {
  //   const childs = {};
  //   items.map(item=>{
  //     childs[item.id]=item.dom;
  //     return true;
  //   });
  //   this.setState({childs});
  // }

  // resetChildren = (childs) => {
  //   //
  // }

  render = () => {
    //console.log('in simple.render');
    const { mode, sdata, record, formatters, onChange, classes, selected, reset, cvalue, cuparams,...others } = this.props;
    const { loading } = this.state;
    //const extra = {selected, reset, cvalue, resetChildren:this.resetChildren, ...others}; 

    //const options = {};
    const extras = {selected, reset, cvalue, cuparams, others };
    //console.log('simple.others',others);
    //const extra = 
    const formitems = createItemList(mode, sdata, record, formatters, onChange, extras);
    return (
      <div className={classes.container}>
        <form id="requestform" className={classes.reqform}>
          {//loading &&
            <LoadingDialog status={loading} start={this.startLoading} stop={this.stopLoading} />
          }
          {formitems.map((item:any) => (
            <div key={item.id}>{item.dom}</div>
          ))}
        </form>
      </div>
    );
  }
};

SimplePanel.propTypes = {
  service: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['add', 'edit', 'delete', 'view', 'close', 'manage', 'wizard']).isRequired,
  sdata: PropTypes.object,
  record: PropTypes.object,
  onChange: PropTypes.func,
  formatters: PropTypes.object,
};

//SimplePanel = withStyles(styles, { withTheme: true })(SimplePanel);

export default withStyles(styles, { withTheme: true })(SimplePanel);
