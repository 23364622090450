//colors
import { Theme } from '@material-ui/core';
import {pink} from '@material-ui/core/colors';
import {common} from '@material-ui/core/colors';
//import { Color } from '@material-ui/core';
//app defaults
import servicestyle from '../../styles/servicestyle';
import statusstyles from '../../styles/statusstyles';

const styles = (theme:Theme):any => {
  let white  = common.white;
  let rtn = servicestyle(theme,pink,white);
  let status = statusstyles(theme);
  return {...rtn,status};
}

const extendedstyles = (extrafn:any) => (theme:Theme):any => {
  let rtn = styles(theme);
  const extra = extrafn(theme);
  return {...rtn,...extra};
}

export {extendedstyles};

export default styles;